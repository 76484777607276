import { useToast, Show } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { z } from "@/i18n";
import { POST } from "@/api";
import { useCallback, useEffect, useRef } from "react";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoginDesktop } from "./desktop";
import { LoginMobile } from "./mobile";
import { useTranslation } from "react-i18next";
import { i18n } from "@/i18n";
import { TurnstileInstance } from "@marsidev/react-turnstile";

export const LoginSchema = z.object({
  email: z.string().min(1, i18n.t("errors.email-is-required")), //.email(i18n.t("errors.email-is-required"),
  password: z
    .string()
    .min(1, i18n.t("errors.password-is-required"))
    .refine((password) => password.trim() === password, {
      message: i18n.t("errors.password-should-not-start-or-end-with-space"),
    }),
  captchaToken: z.string(),
});

export type LoginSchema = z.infer<typeof LoginSchema>;

export const Login = () => {
  const [setToken] = useAuthStore((a) => [a.setToken, a.token]);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const token = searchParams.get("token");
  const turnstileRef = useRef<TurnstileInstance | undefined>();

  const toast = useToast();
  const navigate = useNavigate();

  const methods = useForm<LoginSchema>({
    resolver: zodResolver(LoginSchema),
  });

  const login: SubmitHandler<LoginSchema> = useCallback(
    async ({ email, password, captchaToken }) => {
      const { data, error } = await POST("/auth/login/", {
        body: { email, password, captchaToken },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (error) {
        if (turnstileRef.current) {
          turnstileRef.current.reset();
        }
        if (error.issues) {
          error.issues.forEach((issue) => {
            if (!issue.path) return;
            methods.setError(
              issue.path as keyof (typeof LoginSchema)["_type"],
              {
                message: issue.message,
              }
            );
          });
          return;
        }
        toast({
          status: "error",
          description: error.detail ?? t("errors.an-error-occurred"),
        });

        return;
      }

      if (!data) {
        toast({
          title: t("errors.unexpected_error"),
          status: "error",
          isClosable: true,
        });

        return;
      }
      await setToken(data.token);

      navigate("/");
    },
    [navigate, setToken, toast, t, methods]
  );

  useEffect(() => {
    if (token) {
      const backendLogin = async () => {
        await setToken(token);
        navigate("/");
      };

      backendLogin();
    }
  }, [navigate, setToken, token]);

  return (
    <FormProvider {...methods}>
      <Show above="md">
        <LoginDesktop
          onSubmit={methods.handleSubmit(login)}
          disabled={methods.formState.isSubmitting}
          turnstileRef={turnstileRef}
        />
      </Show>
      <Show below="md">
        <LoginMobile
          onSubmit={methods.handleSubmit(login)}
          disabled={methods.formState.isSubmitting}
          turnstileRef={turnstileRef}
        />
      </Show>
    </FormProvider>
  );
};
