import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  IconButton,
  Text,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PasswordCheckIcon } from "@/assets/icons/password-check-icon";

export interface Step4Props {
  isOpen: boolean;
  onClose: () => void;
}

export const Step4: React.FC<Step4Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="704px"
        px="16px"
        py="20px"
        border="1px solid"
        borderColor="gray.athens"
      >
        <ModalCloseButton
          color="blue.shiny"
          bg="gray.athens"
          rounded="full"
          w="24px"
          h="24px"
        />

        <ModalBody pb="24px" display="flex" flexDir="row">
          <IconButton
            aria-label="Password Check"
            cursor="default"
            icon={<PasswordCheckIcon />}
            bg="gray.athens"
            w="56px"
            h="56px"
            mt="4px"
          />
          <Box ml="24px">
            <Text fontSize="20px" fontWeight="600">
              {t("page.settings.profile.applied")}
            </Text>
            <Text fontSize="14px" fontWeight="400" color="gray.smoke">
              {t("page.settings.profile.password-changed")}
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter pt="0">
          <Button
            color="white.normal"
            onClick={onClose}
            bg="blue.shiny"
            px="20px"
            py="14px"
            fontSize="14px"
            w="full"
          >
            {t("confirm")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
