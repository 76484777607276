export const SearchBoxIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.772217" y="0.5" width="31.0336" height="31.0336" rx="2.5" />
      <path
        d="M18.977 9.84105C16.5335 7.39755 12.5565 7.39755 10.113 9.84105C7.67002 12.2851 7.67002 16.2616 10.113 18.7056C12.289 20.8811 15.678 21.1141 18.1199 19.4154C18.1713 19.6585 18.2889 19.8905 18.478 20.0796L22.0366 23.6382C22.5551 24.1557 23.3931 24.1557 23.909 23.6382C24.4271 23.1201 24.4271 22.2821 23.909 21.7657L20.3505 18.2061C20.1625 18.0186 19.9299 17.9004 19.6868 17.8491C21.3866 15.4066 21.1535 12.0181 18.977 9.84105ZM17.8535 17.5821C16.0292 19.4064 13.0602 19.4064 11.2365 17.5821C9.41326 15.7578 9.41326 12.7894 11.2365 10.9651C13.0602 9.14132 16.0292 9.14132 17.8535 10.9651C19.6778 12.7894 19.6778 15.7578 17.8535 17.5821Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
