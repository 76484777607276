import { FC } from "react";

export const PlusStyledIcon: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_12126_21158)">
        <rect width="128" height="128" fill="#FFF" />
        <path
          d="M59 86.4951V68.9951H41.5C38.7383 68.9951 36.5 66.7568 36.5 63.9951C36.5 61.2344 38.7383 58.9951 41.5 58.9951H59V41.4951C59 38.7344 61.2383 36.4951 64 36.4951C66.7617 36.4951 69 38.7344 69 41.4951V58.9951H86.5C89.2617 58.9951 91.5 61.2344 91.5 63.9951C91.5 66.7568 89.2617 68.9951 86.5 68.9951H69V86.4951C69 89.2568 66.7617 91.4951 64 91.4951C61.2383 91.4951 59 89.2568 59 86.4951ZM109.255 18.7451C97.167 6.65723 81.0947 0 64 0C46.9053 0 30.833 6.65723 18.7451 18.7451C6.65723 30.833 0 46.9053 0 64C0 81.0947 6.65723 97.167 18.7451 109.255C30.833 121.343 46.9053 128 64 128C75.7031 128 87.1543 124.811 97.1152 118.776C99.4775 117.346 100.232 114.271 98.8018 111.91C97.3711 109.548 94.2959 108.793 91.9346 110.224C83.5361 115.311 73.876 118 64 118C34.2246 118 10 93.7754 10 64C10 34.2246 34.2246 10 64 10C93.7754 10 118 34.2246 118 64C118 74.6475 114.834 85.0107 108.844 93.9717C107.309 96.2666 107.926 99.3721 110.222 100.907C112.517 102.441 115.622 101.824 117.157 99.5293C124.25 88.9189 128 76.6328 128 64C128 46.9053 121.343 30.833 109.255 18.7451Z"
          fill="#50CD89"
        />
      </g>
      <defs>
        <clipPath id="clip0_12126_21158">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
