export const ConnectPlusIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.64298 16.7397V0.739746H10.273V16.7397H6.64298ZM0.458008 10.5548V6.92472H16.458V10.5548H0.458008Z" />
    </svg>
  );
};
