import React, { useState } from "react";
import { Flex, Box, Text, IconButton, Radio } from "@chakra-ui/react";
import { MasterCardIcon } from "@/assets/icons/mastercard-logo";
import { VisaLogoIcon } from "@/assets/icons/visa-logo-icon";
import CustomAlertDialog from "@/components/alert-modal";
import { useTranslation } from "react-i18next";
import { CancelSquareIcon } from "@/assets/icons/cancel-square-icon";

interface CardInfo {
  cardHolderName: string | null;
  maskedCardNumber: string;
  cardToken: string;
  finishPaymentRequired: boolean;
  cardNamebyUser: string;
  bankCode: string | null;
  isDebitCard: boolean | null;
  cardProgramName: string | null;
  isBusinessCard: boolean | null;
  cardType: string;
  bin: string | null;
}

interface CardItemProps {
  card: CardInfo;
  image?: string;
  onEdit?: () => void;
  onDelete: () => void;
  onSelect: (card: CardInfo) => void;
  index: number;
}

export const CardItem: React.FC<CardItemProps> = ({
  card,
  onDelete,
  onSelect,
  index,
}) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <Flex
      p="3px"
      pl="20px"
      py="5px"
      rounded="sm"
      cursor="pointer"
      bg="gray.whiteSmoke"
      mb="13px"
      id="card-item"
      justifyItems="space-between"
    >
      <Radio
        key={index}
        value={index.toString()}
        onChange={() => onSelect(card)}
        w="full"
      >
        <Flex alignItems="center" w="full" justifyContent="space-between">
          {card.cardType === "Mastercard" ? (
            <Box
              bg="black.dark.default"
              rounded="base"
              width="27px"
              height="20px"
              p="2px"
            >
              <MasterCardIcon />
            </Box>
          ) : card.cardType === "Visa" ? (
            <Box width="27px" height="29px">
              <VisaLogoIcon />
            </Box>
          ) : null}

          <Text
            w="full"
            ml="5"
            color="black.active"
            fontSize="16px"
            fontWeight="500"
            lineHeight="20px"
          >
            {card.cardHolderName}
            <Text
              as="span"
              display="block"
              fontWeight="500"
              fontSize="16px"
              opacity="0.33"
              mt="2"
            >
              {card.maskedCardNumber}
            </Text>
          </Text>
        </Flex>
      </Radio>
      <Flex>
        <Box w="full">
          <IconButton
            mr="2"
            aria-label="remove-channel"
            icon={<CancelSquareIcon />}
            variant="unstyled"
            onClick={() => setAlertOpen(true)}
          />

          <CustomAlertDialog
            title={t("alert.card-list-alert.title")}
            description={t("alert.card-list-alert.description")}
            isOpen={alertOpen}
            onClose={() => setAlertOpen(false)}
            onCancel={() => setAlertOpen(false)}
            onAction={onDelete}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
