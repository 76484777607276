import { FC } from "react";
import { BarGraph } from "./charts/bar-chart";
import { DotsGraph } from "./charts/dots-chart";
import { LineGraph } from "./charts/line-chart";
import { CandleStickGraph } from "./charts/candlestick-chart";

interface AnalyticsChartProps {
  data: {
    name: string;
    data: number;
    lastData?: number;
  }[];
  chartType: string;
}

export const AnalyticsChart: FC<AnalyticsChartProps> = ({
  data,
  chartType,
}) => {
  return (
    <>
      {chartType === "Bar Chart" && <BarGraph data={data} />}
      {chartType === "Line Chart" && <LineGraph data={data} />}
      {chartType === "Dots" && <DotsGraph data={data} />}
      {chartType === "Candlestick" && <CandleStickGraph data={data} />}
    </>
  );
};
