import { PACKAGE_VERSION_N } from "@/constant";
import { differenceInMinutes, parseISO } from "date-fns";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useAuthStore } from "../auth-store/auth-store";

interface SessionState {
  last_active: Date;
  lifespan: number; // number of minutes
  warnUserBefore: number; // number of minutes
  running: boolean;
  userClosed: boolean;
  madeAction: () => void;
  startSession: () => void;
  stopSession: () => void;
  closeModal: () => void;
  modalOpen: boolean;
  clickedByUser: () => void;
}

export const useSessionStore = create<SessionState>()(
  persist<SessionState>(
    (set, get) => ({
      last_active: new Date(),
      lifespan: 45,
      warnUserBefore: 15,
      running: false,
      modalOpen: false,
      userClosed: false,
      madeAction() {
        set({ last_active: new Date() });
      },

      startSession() {
        set({ running: true });

        const timer = setInterval(() => {
          const state = get();
          const diffInM = differenceInMinutes(
            Date.now(),
            typeof state.last_active === "string"
              ? parseISO(state.last_active)
              : state.last_active
          );

          if (diffInM >= state.lifespan) {
            set({ running: false });
            get().stopSession();
            clearInterval(timer);

            if (useAuthStore.getState().user) {
              useAuthStore.getState().logout();
            }

            return;
          }

          if (diffInM >= state.lifespan - state.warnUserBefore) {
            if (!state.userClosed) {
              set({ modalOpen: true });
            }
          }
        }, 1000);
      },

      closeModal() {
        set({ modalOpen: false });
      },
      clickedByUser() {
        set({ userClosed: true, modalOpen: false });
      },
      stopSession() {
        set({ last_active: new Date(0) });
      },
    }),
    {
      name: "session-storage",
      version: PACKAGE_VERSION_N,
    }
  )
);
