export interface MusicSignIcon extends React.SVGAttributes<SVGElement> {
  fill?: string;
}

export const MusicSignIcon = (props: MusicSignIcon) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.72551 2.62222V11.2477C4.2236 10.8 3.56221 10.5274 2.83825 10.5274C1.27324 10.5274 0 11.8006 0 13.3657C0 14.9307 1.27324 16.204 2.83825 16.204C4.40133 16.204 5.67325 14.9339 5.67648 13.3716H5.67654V6.43964L14.119 4.29955V8.95803C13.617 8.5103 12.9557 8.23771 12.2317 8.23771C10.6667 8.23771 9.39344 9.51095 9.39344 11.076C9.39344 12.641 10.6667 13.9143 12.2317 13.9143C13.7967 13.9143 15.07 12.641 15.07 11.076V0L4.72551 2.62222ZM2.83829 15.2529C1.79769 15.2529 0.951064 14.4063 0.951064 13.3657C0.951064 12.325 1.79766 11.4784 2.83829 11.4784C3.87891 11.4784 4.72554 12.325 4.72554 13.3657C4.72551 14.4063 3.87891 15.2529 2.83829 15.2529ZM5.67654 5.45858V3.36225L14.119 1.2222V3.31849L5.67654 5.45858ZM12.2317 12.9632C11.1911 12.9632 10.3445 12.1166 10.3445 11.076C10.3445 10.0354 11.1911 9.18874 12.2317 9.18874C13.2723 9.18874 14.119 10.0353 14.119 11.076C14.119 12.1166 13.2724 12.9632 12.2317 12.9632Z"
        fill={props.fill || "#000"}
      />
    </svg>
  );
};
