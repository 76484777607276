import { Box, Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import React from "react";
import { TooltipProps } from "recharts";

export interface CustomTooltipProps extends TooltipProps<number, string> {
  active?: boolean;
  lastData?: number;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  const { t } = useTranslation();

  const activeAndHasPayload = active && payload && payload.length;

  if (!activeAndHasPayload) {
    return null;
  }

  return (
    <Box p="10px" bg="white" border="1px" borderColor="gray.300" boxShadow="md">
      <Flex>
        <Text fontSize="14px" color="blue.shiny" fontWeight="600">
          {t("date")}:
        </Text>
        <Text
          ml="1"
          fontSize="14px"
          fontWeight="450"
          color="black.active"
        >{`${label}`}</Text>
      </Flex>

      <Flex>
        <Text fontSize="14px" color="blue.shiny" fontWeight="600">
          {t("activity")}:
        </Text>
        <Text
          ml="1"
          fontSize="14px"
          fontWeight="450"
          color="black.active"
        >{`${payload[0].value}`}</Text>
      </Flex>
      {payload[1] && (
        <Flex>
          <Text fontSize="14px" color="blue.shiny" fontWeight="600">
            Data
          </Text>
          <Text ml="1" fontSize="14px" fontWeight="450" color="black.active">
            {`${payload[1].value}`}
          </Text>
        </Flex>
      )}
    </Box>
  );
};
