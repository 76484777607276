import { Box, Text, Flex, Icon, Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useMemo, useCallback } from "react";
import { BlackCrossIcon } from "@/assets/icons/black-cross-icon";
import { ConnectChannelItem } from "./connect-channel-item";
import { APISchemas, DELETE } from "@/api";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { useTranslation } from "react-i18next";
import {
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  FacebookIcon,
  TelegramIcon,
  TiktokCircleIcon,
} from "@/assets/brands";
import { useChannel } from "@/hooks/use-channels";
import { LogoBlue } from "@/assets/logo/logo-blue";
import { brandTitle } from "@/constant";

export const ConnectChannel = () => {
  const activeOrganization = useAuthStore((s) => s.activeOrganization);
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: Channels, refetch } = useChannel();

  const handleDelete = useCallback(
    async (channel: APISchemas["Channel"]) => {
      if (!activeOrganization?.id) {
        return;
      }
      const { data, error } = await DELETE("/org/{org_pk}/channels/{id}/", {
        params: {
          path: {
            org_pk: activeOrganization.id.toString(),
            id: channel.id,
          },
        },
      });
      if (error) {
        toast({
          description: error.detail || t("errors.unexpected_error"),
          status: "error",
        });
        return;
      }
      refetch();
      return data;
    },
    [activeOrganization, refetch, toast, t]
  );
  const { instagram, twitter, linkedin, facebook, telegram, tiktok } =
    useMemo(() => {
      const instagram = Channels?.filter((item) =>
        item.channel_type.startsWith("instagram")
      );
      const twitter = Channels?.filter((item) =>
        item.channel_type.startsWith("twitter")
      );
      const facebook = Channels?.filter((item) =>
        item.channel_type.startsWith("facebook")
      );
      const linkedin = Channels?.filter((item) =>
        item.channel_type.startsWith("linkedin")
      );

      const telegram = Channels?.filter((item) =>
        item.channel_type.startsWith("telegram")
      );
      const tiktok = Channels?.filter((item) =>
        item.channel_type.startsWith("tiktok")
      );

      // const youtube = Channels?.filter((item) =>
      //   item.channel_type.startsWith("youtube")
      // );

      return {
        instagram,
        twitter,
        facebook,
        linkedin,
        telegram,
        tiktok,
        // youtube,
      };
    }, [Channels]);

  const activeInstagramChannels = instagram?.filter(
    (channel) => channel.status === "active"
  );
  const activeFacebookChannels = facebook?.filter(
    (channel) => channel.status === "active"
  );
  const activeTwitterChannels = twitter?.filter(
    (channel) => channel.status === "active"
  );
  const activeLinkedinChannels = linkedin?.filter(
    (channel) => channel.status === "active"
  );

  const activeTelegramChannels = telegram?.filter(
    (channel) => channel.status === "active"
  );
  const activeTiktokChannels = tiktok?.filter(
    (channel) => channel.status === "active"
  );
  // const activeYoutubeChannels = youtube?.filter(
  //   (channel) => channel.status === "active"
  // );

  return (
    <Box h="full" alignSelf="stretch" bg="white">
      <Flex py="9" px="9" justifyContent="space-between">
        <Flex alignItems="center">
          <Icon w="10" h="10" mr="3">
            <LogoBlue />
          </Icon>
          <Text
            fontSize="18px"
            lineHeight="22.5px"
            fontWeight="700"
            color="blue.shiny"
          >
            {brandTitle}
          </Text>
        </Flex>

        <Flex>
          <Button
            onClick={() => {
              if (Channels?.length === 0) {
                navigate("/");
                return;
              }
              navigate(-1);
            }}
            variant="unstyled"
          >
            <BlackCrossIcon width="30px" height="30px" />
          </Button>
        </Flex>
      </Flex>

      <Box px="20" py="5" flex="1">
        <Box>
          <Text fontSize="24px" color="black.active" fontWeight="500">
            {t("connect_your_channels")}
          </Text>
          <Text fontSize="16px" color="black.active" mt="4" fontWeight="400">
            {t("page.connect-channels.subtitle")}
          </Text>
        </Box>
        <Flex mt="48px" wrap="wrap" w="full">
          <ConnectChannelItem
            titleBg="linear-gradient(94deg, #841DC3 1.73%, #FD3283 98.01%)"
            title="Instagram"
            children={<InstagramIcon />}
            accountList={activeInstagramChannels}
            onDelete={handleDelete}
          />
          <ConnectChannelItem
            title="Twitter"
            titleBg="linear-gradient(94deg, #343434 1.73%, #333 98.01%)"
            children={<TwitterIcon />}
            accountList={activeTwitterChannels}
            onDelete={handleDelete}
          />
          <ConnectChannelItem
            title="Facebook"
            titleBg="linear-gradient(94deg, #3A559F 1.73%, #587EE7 98.01%)"
            children={<FacebookIcon />}
            accountList={activeFacebookChannels}
            onDelete={handleDelete}
          />

          <ConnectChannelItem
            title="LinkedIn"
            titleBg="linear-gradient(94deg, #0077B5 1.73%, #02699F 98.01%)"
            children={<LinkedinIcon />}
            accountList={activeLinkedinChannels}
            onDelete={handleDelete}
          />

          <ConnectChannelItem
            title="Telegram"
            titleBg="linear-gradient(93.5deg, #039BE5 1.73%, #077DB7 98.01%)"
            children={<TelegramIcon />}
            accountList={activeTelegramChannels}
            onDelete={handleDelete}
          />

          <ConnectChannelItem
            title="Tiktok"
            titleBg="black.default"
            children={<TiktokCircleIcon reverse={true} />}
            accountList={activeTiktokChannels}
            onDelete={handleDelete}
          />

          {/* <ConnectChannelItem
            title="Youtube"
            titleBg="red.youtube"
            children={
              <YoutubeCircleIcon fill="white" width="full" height="full" />
            }
            accountList={activeYoutubeChannels}
            onDelete={handleDelete}
          /> */}
        </Flex>
      </Box>

      <Flex
        alignItems="flex-end"
        w="full"
        px="12"
        justifyContent="flex-end"
        mb="5"
      >
        <Button
          borderRadius="3px"
          h="42px"
          w="202px"
          color="white"
          bg={Channels?.length === 0 ? "gray.passive" : "blue.shiny"}
          fontSize="16px"
          lineHeight="16.5"
          fontWeight="600"
          isDisabled={!Channels || Channels?.length === 0}
          onClick={() => {
            navigate("/");
          }}
        >
          {t("continue")}
        </Button>
      </Flex>
    </Box>
  );
};
