import { CustomDropdown } from "@/components/dropdown";
import { Box, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { AnalyticsChart } from "./analytics-chart";

export const AnalyticsChartSection = () => {
  const [analyzeDropdown, setAnalyzeDropdown] = useState<string>(
    analyzeList[0]
  );
  const [periodDropdown, setPeriodDropdown] = useState<string>(periodList[0]);
  const [chartTypeDropdown, setChartTypeDropdown] = useState<string>(
    chartList[0]
  );

  return (
    <Box w="full" height="full" bgColor="white" p="16px" borderRadius="6px">
      <Flex w="full" alignItems="center">
        <Box w="full">
          <Text
            fontWeight="600"
            lineHeight="14px"
            fontSize="16px"
            color="black.active"
          >
            {t("page.analytics.analytics-chart")}{" "}
          </Text>
        </Box>
        <Flex gap="10px">
          <CustomDropdown
            info="Analyze"
            activeItem={analyzeDropdown}
            dropdownList={analyzeList}
            setActiveItem={(item) => setAnalyzeDropdown(item)}
          />
          <CustomDropdown
            info="Period"
            activeItem={periodDropdown}
            dropdownList={periodList}
            setActiveItem={(item) => setPeriodDropdown(item)}
          />
          <CustomDropdown
            info="Chart Type"
            activeItem={chartTypeDropdown}
            dropdownList={chartList}
            setActiveItem={(item) => setChartTypeDropdown(item)}
          />
        </Flex>
      </Flex>
      <Box w="full" height="full" py="6">
        <AnalyticsChart data={data} chartType={chartTypeDropdown} />
      </Box>
    </Box>
  );
};

const analyzeList = [
  "Interaction",
  "Activity",
  "Engagement",
  "Impressions",
  "Reach",
  "Audience",
];
const periodList = ["This Week", "This Month", "This Year"];
const chartList = ["Bar Chart", "Line Chart", "Dots", "Candlestick"];

const data = [
  { name: "19 Feb", data: 1000, lastData: 1600 },
  { name: "20 Feb", data: 1300, lastData: 1400 },
  { name: "21 Feb", data: 1500, lastData: 1800 },
  { name: "22 Feb", data: 1200, lastData: 1200 },
  { name: "23 Feb", data: 1800, lastData: 1900 },
  { name: "24 Feb", data: 1100, lastData: 1000 },
  { name: "25 Feb", data: 1200, lastData: 1600 },
  { name: "26 Feb", data: 1800, lastData: 1400 },
  { name: "27 Feb", data: 1300, lastData: 1300 },
  { name: "28 Feb", data: 1500, lastData: 1800 },
];
