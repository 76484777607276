import {
  Box,
  Button,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";
import { Guide } from "./posteffect-guide/guide";
import { useLocation } from "react-router-dom";
import { Asistan } from "./asistan";
import { Tickets } from "./my-tickets";
import { CreateTicketIcon } from "@/assets/icons/create-ticket-icon";
import { CreateTicket } from "./components/ticket/create-ticket";

import { useTranslation } from "react-i18next";
import { colors } from "@/theme/colors";

type FAQ = {
  title: string;
  question: string;
  answer: string;
};

// type Ticket = {
//   id: string;
//   title: string;
//   message: string;
//   feature: string;
//   platform: string;
//   channels: string[];
//   files: string[];
// };

type TicketAnswer = {
  id: string;
  title: string;
  message: string;
};

export const HelpCenterPage: FC = () => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(
    location.state && location.state.tab ? location.state.tab : 0
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ticketHover, setTicketHover] = useState<boolean>(false);
  const { t } = useTranslation();

  const ticketAnswers: TicketAnswer[] = [
    {
      id: "1",
      title: "title1",
      message: "message1",
    },
    {
      id: "2",
      title: "title2",
      message: "message2",
    },
    {
      id: "3",
      title: "title3",
      message: "message3",
    },
    {
      id: "4",
      title: "title4",
      message: "message4",
    },
    {
      id: "5",
      title: "title5",
      message: "message5",
    },
  ];

  const faqList: FAQ[] = useMemo(
    () => [
      {
        title: t("page.faqList.what-is-posteffect.title"),
        question: t("page.faqList.what-is-posteffect.question1"),
        answer: t("page.faqList.what-is-posteffect.answer1"),
      },
      {
        title: t("page.faqList.what-is-posteffect.title"),
        question: t("page.faqList.what-is-posteffect.question2"),
        answer: t("page.faqList.what-is-posteffect.answer2"),
      },
      {
        title: t("page.faqList.what-is-posteffect.title"),
        question: t("page.faqList.what-is-posteffect.question3"),
        answer: t("page.faqList.what-is-posteffect.answer3"),
      },
      {
        title: t("page.faqList.what-is-posteffect.title"),
        question: t("page.faqList.what-is-posteffect.question4"),
        answer: t("page.faqList.what-is-posteffect.answer4"),
      },
      {
        title: t("page.faqList.what-is-posteffect.title"),
        question: t("page.faqList.what-is-posteffect.question5"),
        answer: t("page.faqList.what-is-posteffect.answer5"),
      },
      {
        title: t("page.faqList.Features.title"),
        question: t("page.faqList.Features.question1"),
        answer: t("page.faqList.Features.answer1"),
      },
      {
        title: t("page.faqList.Features.title"),
        question: t("page.faqList.Features.question2"),
        answer: t("page.faqList.Features.answer2"),
      },
      {
        title: t("page.faqList.Features.title"),
        question: t("page.faqList.Features.question3"),
        answer: t("page.faqList.Features.answer3"),
      },
      {
        title: t("page.faqList.Planning.title"),
        question: t("page.faqList.Planning.question1"),
        answer: t("page.faqList.Planning.answer1"),
      },
      {
        title: t("page.faqList.Planning.title"),
        question: t("page.faqList.Planning.question2"),
        answer: t("page.faqList.Planning.answer2"),
      },
      {
        title: t("page.faqList.Sharing.title"),
        question: t("page.faqList.Sharing.question1"),
        answer: t("page.faqList.Sharing.answer1"),
      },
      {
        title: t("page.faqList.Collaboration.title"),
        question: t("page.faqList.Collaboration.question1"),
        answer: t("page.faqList.Collaboration.answer1"),
      },
      {
        title: t("page.faqList.Collaboration.title"),
        question: t("page.faqList.Collaboration.question2"),
        answer: t("page.faqList.Collaboration.answer2"),
      },
      {
        title: t("page.faqList.Collaboration.title"),
        question: t("page.faqList.Collaboration.question3"),
        answer: t("page.faqList.Collaboration.answer3"),
      },
      {
        title: t("page.faqList.Analytics.title"),
        question: t("page.faqList.Analytics.question1"),
        answer: t("page.faqList.Analytics.answer1"),
      },
      {
        title: t("page.faqList.Analytics.title"),
        question: t("page.faqList.Analytics.question2"),
        answer: t("page.faqList.Analytics.answer2"),
      },
      {
        title: t("page.faqList.Channels.title"),
        question: t("page.faqList.Channels.question1"),
        answer: t("page.faqList.Channels.answer1"),
      },
      {
        title: t("page.faqList.Channels.title"),
        question: t("page.faqList.Channels.question2"),
        answer: t("page.faqList.Channels.answer2"),
      },
      {
        title: t("page.faqList.URLShortening.title"),
        question: t("page.faqList.URLShortening.question1"),
        answer: t("page.faqList.URLShortening.answer1"),
      },
      {
        title: t("page.faqList.URLShortening.title"),
        question: t("page.faqList.URLShortening.question2"),
        answer: t("page.faqList.URLShortening.answer2"),
      },
      {
        title: t("page.faqList.URLShortening.title"),
        question: t("page.faqList.URLShortening.question3"),
        answer: t("page.faqList.URLShortening.answer3"),
      },
      {
        title: t("page.faqList.URLShortening.title"),
        question: t("page.faqList.URLShortening.question4"),
        answer: t("page.faqList.URLShortening.answer4"),
      },
      {
        title: t("page.faqList.URLShortening.title"),
        question: t("page.faqList.URLShortening.question5"),
        answer: t("page.faqList.URLShortening.answer5"),
      },
    ],
    [t]
  );

  // const groupByTitle = (faqList: FAQ[]) => {
  //   return faqList.reduce(
  //     (acc, faq) => {
  //       if (!acc[faq.title]) {
  //         acc[faq.title] = [];
  //       }
  //       acc[faq.title].push(faq);
  //       return acc;
  //     },
  //     {} as Record<string, FAQ[]>
  //   );
  // };

  // const groupedFaqList = groupByTitle(faqList);

  // const filteredFaqListTitle = ({
  //   faqList,
  //   selectedTitle,
  //   searchInput,
  // }: {
  //   faqList: FAQ[];
  //   selectedTitle: string;
  //   searchInput: string;
  // }) => {
  //   if (selectedTitle) {
  //     return groupedFaqList[selectedTitle] || [];
  //   } else {
  //     return faqList.filter((faq) =>
  //       faq.title.toLowerCase().includes(searchInput.toLowerCase())
  //     );
  //   }
  // };

  // const onSubmit: SubmitHandler<SupportSchema> = useCallback(async (data) => {
  //   console.log(data);
  //   const { error } = await POST("/support", {
  //     body: {
  //       title: data.title,
  //       message: data.message,
  //       channel: data.channel,
  //       feature: data.feature,
  //       platform: data.platform,
  //       documents: data.documents,
  //     },
  //   });
  //   onClose();
  // }, []);

  // const toast = useToast();

  // const { data } = useQuery("support", async () => {
  //   const { data } = await GET("/support");
  //   return data;
  // });

  return (
    <Tabs
      variant="unstyled"
      minH="70vh"
      index={tabIndex}
      onChange={setTabIndex}
      p="20px"
      bg="gray.bg"
    >
      <TabList
        gap="4"
        minH="full"
        whiteSpace="nowrap"
        bg="white"
        alignItems="center"
        w="full"
      >
        <Tab p="20px">
          <Flex
            fontSize="16px"
            lineHeight="14px"
            opacity={tabIndex === 0 ? "1" : "0.63"}
            color={colors.black.active}
            fontWeight={tabIndex === 0 ? "500" : "none"}
            pos="relative"
          >
            {t("page.help-center.Guide")}
            <Box
              bg="blue.shiny"
              w="full"
              h="4px"
              pos="absolute"
              borderTopRadius="3px"
              bottom="-20px"
              display={tabIndex === 0 ? "block" : "none"}
            />
          </Flex>
        </Tab>
        <Tab p="20px">
          <Flex
            fontSize="16px"
            lineHeight="14px"
            opacity={tabIndex === 1 ? "1" : "0.63"}
            color={colors.black.active}
            alignItems="end"
            fontWeight={tabIndex === 1 ? "500" : "none"}
            pos="relative"
          >
            Asistan
            <Box
              bg="blue.shiny"
              w="full"
              h="4px"
              pos="absolute"
              borderTopRadius="3px"
              bottom="-20px"
              display={tabIndex === 1 ? "block" : "none"}
            />
          </Flex>
        </Tab>

        <Tab p="20px">
          <Flex
            fontSize="16px"
            lineHeight="16px"
            opacity={tabIndex === 2 ? "1" : "0.63"}
            color={colors.black.active}
            alignItems="flex-end"
            fontWeight={tabIndex === 2 ? "500" : "none"}
            pos="relative"
          >
            {t("page.help-center.Tickets")}
            <Box
              bg="purple.normal"
              w="full"
              h="4px"
              pos="absolute"
              borderTopRadius="3px"
              bottom="-20px"
              display={tabIndex === 2 ? "block" : "none"}
            />
          </Flex>
        </Tab>
        {/* 
        <Tab p="20px">
          <Flex
            fontSize="16px"
            alignItems="flex-end"
            lineHeight="20px"
            opacity={tabIndex === 3 ? "1" : "0.63"}
            color={"black.active"}
          
            fontWeight={tabIndex === 3 ? "500" : "none"}
            pos="relative"
          >
            {t("page.help-center.Drafts")}
            <Box
              bg="#F5A10B"
              w="full"
              h="4px"
              pos="absolute"
              borderTopRadius="3px"
              bottom="-20px"
              display={tabIndex === 3 ? "block" : "none"}
            /
          </Flex>
        </Tab> */}

        <Box textAlign="right" w="full" h="full" pr="20px">
          <Button
            variant="unstyled"
            pr="12px"
            pl="5px"
            py="4.9px"
            color="white.normal"
            fontWeight="400"
            bg="blue.shiny"
            borderRadius="3px"
            border="1px solid"
            borderColor="blue.shiny"
            onMouseOver={() => setTicketHover(true)}
            onMouseLeave={() => setTicketHover(false)}
            _hover={{ bg: colors.white.normal, color: colors.blue.shiny }}
            onClick={onOpen}
          >
            <IconButton
              aria-label="create-ticket"
              as={CreateTicketIcon}
              variant="unstyled"
              w="20px"
              h="14px"
              mb="3px"
              fill={ticketHover ? colors.blue.shiny : colors.white.normal}
            />

            {t("page.help-center.create-ticket")}
          </Button>
          <CreateTicket
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={console.log}
          />
        </Box>
      </TabList>

      <TabPanels>
        <TabPanel h="full" px="0">
          <Guide faqList={faqList} />
        </TabPanel>
        <TabPanel h="full" px="0">
          <Asistan faqList={faqList} />
        </TabPanel>

        <TabPanel h="full" px="0">
          <Tickets ticketAnswers={ticketAnswers} />
        </TabPanel>

        {/* <TabPanel h="full" px="0">
          <Drafts
            filteredFaqListTitle={filteredFaqListTitle}
            groupedFaqList={groupedFaqList}
          />
        </TabPanel> */}
      </TabPanels>
    </Tabs>
  );
};
