import React from "react";
import { FC } from "react";

export const TIcon: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.585938 5.3373C0.909531 5.3373 1.17188 5.07496 1.17188 4.75137V2.04688H3.87637C4.19996 2.04688 4.4623 1.78453 4.4623 1.46094C4.4623 1.13734 4.19996 0.875 3.87637 0.875H0.585938C0.262344 0.875 0 1.13734 0 1.46094V4.75137C0 5.075 0.262344 5.3373 0.585938 5.3373Z"
        fill="#C5E0F5"
      />
      <path
        d="M19.4145 0.875H16.124C15.8004 0.875 15.5381 1.13734 15.5381 1.46094C15.5381 1.78453 15.8004 2.04688 16.124 2.04688H18.8285V4.75137C18.8285 5.07496 19.0909 5.3373 19.4145 5.3373C19.738 5.3373 20.0004 5.07496 20.0004 4.75137V1.46094C20.0004 1.13734 19.738 0.875 19.4145 0.875Z"
        fill="#C5E0F5"
      />
      <path
        d="M19.4145 16.4131C19.0909 16.4131 18.8285 16.6754 18.8285 16.999V19.7035H16.124C15.8004 19.7035 15.5381 19.9659 15.5381 20.2895C15.5381 20.613 15.8004 20.8754 16.124 20.8754H19.4145C19.738 20.8754 20.0004 20.613 20.0004 20.2895V16.999C20.0004 16.6754 19.738 16.4131 19.4145 16.4131Z"
        fill="#C5E0F5"
      />
      <path
        d="M3.87637 19.7035H1.17188V16.999C1.17188 16.6754 0.909531 16.4131 0.585938 16.4131C0.262344 16.4131 0 16.6754 0 16.999V20.2895C0 20.613 0.262344 20.8754 0.585938 20.8754H3.87637C4.19996 20.8754 4.4623 20.613 4.4623 20.2895C4.4623 19.9659 4.2 19.7035 3.87637 19.7035Z"
        fill="#C5E0F5"
      />
      <path
        d="M16.3934 8.35117V4.65078C16.3934 4.46719 16.2445 4.31836 16.0605 4.31836H10.0004H3.94023C3.75625 4.31836 3.60742 4.46719 3.60742 4.65078V8.35117C3.60742 8.53516 3.75625 8.68398 3.94023 8.68398H5.70742C5.89102 8.68398 6.03984 8.53516 6.03984 8.35117V7.08359C6.03984 6.89961 6.18906 6.75078 6.37266 6.75078H8.45117C8.63516 6.75078 8.78398 6.89961 8.78398 7.08359V16.2297C8.78398 16.4137 8.63516 16.5625 8.45117 16.5625H7.54219C7.3582 16.5625 7.20938 16.7113 7.20938 16.8953V18.6625C7.20938 18.8461 7.3582 18.9949 7.54219 18.9949H10.0004H12.4586C12.6426 18.9949 12.7914 18.8461 12.7914 18.6625V16.8953C12.7914 16.7113 12.6426 16.5625 12.4586 16.5625H11.5496C11.3656 16.5625 11.2168 16.4137 11.2168 16.2297V7.08359C11.2168 6.89961 11.3656 6.75078 11.5496 6.75078H13.6281C13.8117 6.75078 13.9609 6.89961 13.9609 7.08359V8.35117C13.9609 8.53516 14.1098 8.68398 14.2934 8.68398H16.0605C16.2445 8.68398 16.3934 8.53516 16.3934 8.35117Z"
        fill="#C5E0F5"
      />
    </svg>
  );
};
