import React, { FC } from "react";

export const ImageIcon: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      {...props}
    >
      <path
        d="M17.3824 0.314453H2.21656C0.994097 0.314453 0 1.30822 0 2.53213V16.4704C0 17.6929 0.994097 18.6856 2.21656 18.6856H17.3823C18.6048 18.6856 19.5978 17.6929 19.5978 16.4704V2.53213C19.5979 1.30822 18.6048 0.314453 17.3824 0.314453ZM12.673 3.61256C13.8552 3.61256 14.8139 4.57136 14.8139 5.75348C14.8139 6.9356 13.8551 7.8944 12.673 7.8944C11.4905 7.8944 10.5321 6.9356 10.5321 5.75348C10.5321 4.57136 11.4905 3.61256 12.673 3.61256ZM16.6872 16.6367H9.79874H3.21715C2.62586 16.6367 2.36253 16.2088 2.62908 15.6811L6.30329 8.40362C6.56951 7.8759 7.0769 7.8288 7.43621 8.29832L11.1308 13.1265C11.4901 13.5961 12.1181 13.6361 12.5338 13.2153L13.4376 12.3001C13.8529 11.8794 14.4649 11.9315 14.8039 12.4157L17.1443 15.7588C17.4826 16.2437 17.2785 16.6367 16.6872 16.6367Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
