import { Box, Heading, Text } from "@chakra-ui/react";

export const TermAndConditionEn = () => {
  return (
    <Box>
      <Text as="p">
        These Terms of Service ("Terms", "Service Terms", or "Agreement") govern
        your relationship with the websites located at Posteffect.ai, along with
        our related websites, networks, applications, software, and mobile
        applications (each a "Application" and collectively "Applications")
        provided by GDH Media and Technology Services Inc. ("POSTEFFECT", "we",
        or "us").
      </Text>

      <Text as="p">
        You should read these Terms along with our Privacy Policy and Cookie
        Policy, which, together with any software license agreement, form our
        contractual relationship with you (each a "User" and collectively
        "Users"). The POSTEFFECT Privacy Policy and Cookie Policy are included
        in these Terms by this reference and made a part thereof.
      </Text>

      <Text as="p">
        Please read these Terms carefully and ensure you understand them. If you
        do not understand these Terms or do not accept any part of them, you
        cannot use the Service.
      </Text>
      <Text as="p">
        Your access to and use of the Services is conditioned on your acceptance
        of and compliance with these Terms. These Terms apply to all visitors,
        users, and others who access or use the Service.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Memberships
        </Text>
      </Heading>

      <Text as="p">
        Some parts of the Service are billed on a Membership basis ("Membership"
        or "Plan"). You will be billed in advance on a recurring and periodic
        basis ("Billing Cycle"). Billing cycles are set either monthly or
        annually, depending on the type of Membership plan you select when
        purchasing a Plan.
      </Text>

      <Text as="p">
        At the end of each Billing Cycle, your Subscription will automatically
        renew under the exact same conditions unless you cancel it or POSTEFFECT
        cancels it. You may cancel your Membership renewal through the
        "Membership" page within the Application.
      </Text>
      <Text as="p">
        A valid payment method, including credit card, is required to process
        the payment for your Subscription. You must provide POSTEFFECT with
        accurate and complete billing information including full name, address,
        state, zip code, telephone number, and a valid payment method
        information. By submitting such payment information, you automatically
        authorize POSTEFFECT to charge all Subscription fees incurred through
        your account to any such payment instruments.
      </Text>
      <Text as="p">
        Should automatic billing fail to occur for any reason, POSTEFFECT will
        issue an electronic invoice indicating that you must proceed manually,
        within a certain deadline date, with the full payment corresponding to
        the billing period as indicated on the invoice.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Upgrades and Downgrades
        </Text>
      </Heading>

      <Text as="p">
        If you choose to upgrade your Plan or make any Add-ons to your Plan
        during your Membership period, you will be charged a prorated rate based
        on the number of days remaining in your Membership period for the
        upgrade or Add-ons at the then-current rate. Unless otherwise stated in
        your service order, any upgrades or Add-ons you add will expire at the
        same time as your current Plan and will automatically renew with your
        Plan at the end of your Membership period. If you choose to downgrade
        your Plan or remove any Add-ons from your Plan, no refunds or credits
        will be issued for unused and prepaid fees associated with downgrading
        or removal. Downgrading your Plan may cause the loss of content,
        features, or capacity of your account and we do not accept any liability
        for such loss.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Fee Changes
        </Text>
      </Heading>

      <Text as="p">
        POSTEFFECT, in its sole discretion and at any time, may modify the
        Subscription fees. Any Subscription fee change will become effective at
        the end of the then-current Billing Cycle.
      </Text>

      <Text as="p">
        POSTEFFECT will provide you with a reasonable prior notice of any change
        in Subscription fees to give you an opportunity to terminate your
        Subscription before such change becomes effective.
      </Text>
      <Text as="p">
        Your continued use of the Service after the Subscription fee change
        comes into effect constitutes your agreement to pay the modified
        Subscription fee am
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Taxes
        </Text>
      </Heading>

      <Text as="p">
        The recipient company is legally responsible for paying all taxes for
        all fees paid to us. Local taxes may vary depending on your payment
        method.
      </Text>
      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Cancellation
        </Text>
      </Heading>

      <Text as="p">
        We make it possible to cancel your Membership at any stage of your
        journey with us.
      </Text>
      <Text as="p">
        You can request a cancellation at any time, but the change will only
        take effect at the end of your billing cycle and you will not have the
        right to receive a refund for any prepaid and unused fees or any unpaid
        fees under your Plan for the relevant Membership period and all unpaid
        amounts will become immediately due.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Content
        </Text>
      </Heading>

      <Text as="p">
        Our Services allow you to post, link, store, share, and otherwise make
        available certain information, text, graphics, videos, or other material
        ("Content"). You are responsible for the Content that you submit on or
        through the Service, including its legality, reliability, and
        appropriateness.
      </Text>
      <Text as="p">
        By posting, uploading, or otherwise submitting Content to the Service,
        you grant us the right and license to use, modify, publicly perform,
        publicly display, reproduce, and distribute such Content on and through
        the Service. You retain any and all of your rights to any Content you
        submit, post or display on or through the Service and you are
        responsible for protecting those rights.
      </Text>

      <Text as="p">
        You represent and warrant that: (i) the Content is yours (you own it) or
        you have the right to use it and grant us the rights and license as
        provided in these Terms, and (ii) the posting of your Content on or
        through the Service does not violate the privacy rights, publicity
        rights, copyrights, trademark rights, contract rights, or any other
        rights of any person.
      </Text>
      <Text as="p">
        We reserve the right to block or remove communications or materials that
        we determine to be: (a) abusive, defamatory, or obscene; (b) fraudulent,
        deceptive, or misleading; (c) in violation of a copyright or trademark
        of another party; (d) offensive or otherwise unacceptable to us at our
        sole discretion.
      </Text>
      <Text as="p">
        By providing us with the opportunity to view and distribute user-created
        content on the Service, you acknowledge that we are acting only as a
        passive channel for such distribution and are not undertaking any
        obligation or liability relating to any contents or activities on the
        Service.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Accounts
        </Text>
      </Heading>

      <Text as="p">
        When you create an account with us, you must always provide us with
        accurate, complete, and current information. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of your account on our Service.
      </Text>
      <Text as="p">
        You are responsible for safeguarding the password that you use to access
        the Service and for any activities or actions under your password,
        whether your password is with our Service or a third-party service.
      </Text>

      <Text as="p">
        You agree not to disclose your password to any third party. You must
        notify us immediately upon becoming aware of any breach of security or
        unauthorized use of your account.
      </Text>
      <Text as="p">
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you without
        appropriate authorization, or a name that is otherwise offensive,
        vulgar, or obscene.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Mobile Application
        </Text>
      </Heading>

      <Text as="p">
        The use of Mobile Applications requires that your mobile device be
        compatible with the Mobile Applications. Mobile Applications may from
        time to time be upgraded automatically, depending on your user settings.
        You accept such automatic upgrades and any standard operator data fees
        that may apply to your use of the Mobile Applications.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Third-Party Products and Services
        </Text>
      </Heading>

      <Text as="p">
        When creating a POSTEFFECT account, you must provide us with accurate
        information. Your POSTEFFECT account may, from time to time and in our
        sole discretion, enable you to access the Service and features that we
        may establish and maintain. We may maintain different types of accounts
        for different types of users or organizations. If you connect to
        POSTEFFECT with a third-party service, you give us permission to access
        and use your information from that service as permitted by that service
        and to store your log-in credentials for that service or your API
        tokens. You may never use another user's account without permission.{" "}
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Privacy Statement
        </Text>
      </Heading>

      <Text as="p">
        We are committed to protecting your privacy. Authorized employees within
        POSTEFFECT use information collected from individual users on a
        need-to-know basis. We constantly review our systems and data to provide
        the best possible service to our users. There are specific offenses for
        unauthorized actions against computer systems and data. We will
        investigate any such actions with a view to prosecuting and/or taking
        civil proceedings to recover damages against those responsible.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Privacy
        </Text>
      </Heading>

      <Text as="p">
        All information concerning the user and their respective User Records
        may be passed to third parties. However, User Records are regarded as
        confidential and therefore will not be divulged to any third party,
        other than our employees and, if legally required, to appropriate
        authorities. Users have the right to request sight of, and copies of,
        all User Records we keep, on the proviso that we are given reasonable
        notice of such a request. Users are requested to retain copies of any
        literature issued in relation to the provision of our services. Where
        appropriate, we shall issue User with appropriate written information,
        handouts, or copies of records as part of an agreed contract, for the
        benefit of both parties.
      </Text>

      <Text as="p">
        We will not sell, share, or rent your personal information to any third
        party or use your e-mail address for unsolicited mail. Any emails sent
        by POSTEFFECT will only be in connection with the provision of agreed
        services and products.
      </Text>

      <Text as="p">
        You acknowledge that we have the right to have and complete statistical
        analyses of your and your Users' data and information arising from your
        use of the Service (excluding personally identifiable data). When we
        extract, compile, synthesize, or analyze such data, we will use it only
        in an anonymized, unidentified, or aggregate form without identifying
        its source. We collect such data for any lawful purpose and without
        accounting to you.
      </Text>

      <Text as="p">
        We welcome your suggestions, comments, error reports, feature requests,
        or other feedback ("Feedback"). We are not required to keep Feedback
        confidential even if you tell us it is confidential. If you provide
        Feedback, you grant POSTEFFECT a non-exclusive, worldwide, perpetual,
        irrevocable, transferable, sublicensable, royalty-free, fully paid
        license to use Feedback for any purpose.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Force Majeure
        </Text>
      </Heading>

      <Text as="p">
        Neither party shall be liable for any failure to perform any obligation
        under these Terms due to causes beyond their control, including but not
        limited to Acts of God, terrorism, war, Political insurgence,
        insurrection, riot, civil unrest, act of civil or military authority,
        uprising, earthquake, flood or any other natural or man-made eventuality
        outside of their control, which causes the termination of an agreement
        or contract entered into, nor which could have been reasonably foreseen.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Compelling Reasons
        </Text>
      </Heading>

      <Text as="p">
        Neither party shall be liable for failing to perform any obligation
        under these Terms due to reasons beyond their control, which could not
        reasonably be foreseen, including but not limited to Natural Disasters,
        terrorism, war, Political insurrection, rebellion, riot, civil disorder,
        actions of public or military authority, uprising, earthquake, flood, or
        any natural or man-made causes outside of our control. Any Party
        affected by such event shall promptly inform the other Party and will
        make all reasonable efforts to comply with the terms and conditions of
        any Agreement herein.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Assignability
        </Text>
      </Heading>

      <Text as="p">
        Neither party may transfer their rights, duties, and obligations under
        these Terms without the prior written consent of the other party (which
        shall not unreasonably be withheld or delayed); however, POSTEFFECT may
        assign these Terms and the licenses granted to POSTEFFECT under these
        conditions to a successor, without your consent, provided that the
        successor agrees to assume and fulfill POSTEFFECT's obligations under
        these Terms including successors through merger, acquisition, sale of
        assets, or by operation of law.
      </Text>
      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Waiver and Severability
        </Text>
      </Heading>
      <Text as="p">
        Any waiver of any term or condition by POSTEFFECT in these Terms does
        not imply a waiver of ongoing or additional terms and conditions, and
        non-reliance by POSTEFFECT on any right or provision under these Terms
        does not imply a waiver of such right or provision. If any provision of
        these Terms is found by a court or other competent jurisdiction to be
        invalid, unenforceable, or illegal for any reason, such provision shall
        be limited to the minimum extent so that the remaining provisions of the
        Terms remain in full force and effect.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Warranty
        </Text>
      </Heading>
      <Text as="p">
        THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE". THE RISK OF USING
        THE SERVICES IS ENTIRELY YOURS. TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT ANY WARRANTY, EITHER
        EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
        WITHOUT LIMITING THE ABOVE, POSTEFFECT AND ITS LICENSORS DO NOT WARRANT
        THAT THE CONTENT IS ACCURATE, RELIABLE, OR CORRECT; THAT THE SERVICE
        WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL BE AVAILABLE AT ANY
        PARTICULAR TIME OR LOCATION, UNINTERRUPTED, OR SECURE; THAT ANY DEFECTS
        OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR
        OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED
        THROUGH THE USE OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU
        WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
        MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD. WE DO NOT
        WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH OUR SERVICE OR
        ANY HYPERLINKED WEBSITE OR SERVICE, AND WE ARE NOT A PARTY TO OR IN ANY
        WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
        PRODUCTS OR SERVICES. WE ARE NOT LIABLE OR RESPONSIBLE IN ANY WAY FOR
        THE FAILURE OF ANY SUPPORTED PLATFORMS OR THIRD-PARTY SERVICES RELATED
        TO OR CONNECTED WITH THE SERVICES, INCLUDING FAILURE OF SUPPORTED
        PLATFORMS.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Indemnity
        </Text>
      </Heading>
      <Text as="p">
        You agree to indemnify, defend, and hold harmless POSTEFFECT, its
        directors, officers, representatives, employees, contractors, licensors,
        license holders, suppliers, and agents from any and all claims, losses,
        damages, liabilities, costs, actions, or demands. These include but are
        not limited to: (a) legal and accounting fees resulting from your use of
        the Service; (b) your breach of any of these Terms; (c) anything you
        post or upload to the Service; and (d) any activity related to your
        account. This includes any negligent or illegal conduct by you, any
        person or entity accessing the Service using your account whether such
        access is obtained via fraudulent or illegal means.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Limitation of Liability
        </Text>
      </Heading>
      <Text as="p">
        POSTEFFECT, its directors, employees, partners, representatives,
        suppliers, or affiliates shall not be liable for any direct or indirect,
        incidental, special, consequential, or punitive damages, including
        without limitation loss of profits, data, use, goodwill, or other
        intangible losses, resulting from (i) your access to or use of or
        inability to access or use the Service; (ii) any conduct or content of
        any third party on the Service; (iii) any content obtained from the
        Service; and (iv) unauthorized access, use, or alteration of your
        transmissions or content, whether based on warranty, contract, tort
        (including negligence), or any other legal theory, regardless of whether
        we have been informed of the possibility of such damage, and even if a
        remedy set forth herein is found to have failed its essential purpose.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Copyright and Trademark
        </Text>
      </Heading>
      <Text as="p">
        Copyright and other related intellectual property rights in all texts
        related to POSTEFFECT's services and all content on this website are
        owned by POSTEFFECT.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Notification of Changes
        </Text>
      </Heading>
      <Text as="p">
        We reserve the right to modify or amend these Terms at any time at our
        sole discretion. If a revision is material, we will make reasonable
        efforts to provide at least 15 days' notice before any new terms take
        effect. What constitutes a material change will be determined at our
        sole discretion.
      </Text>
      <Text as="p">
        By continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Notifications
        </Text>
      </Heading>
      <Text as="p">
        POSTEFFECT may provide notifications to you by placing a banner notice
        on the POSTEFFECT platform or POSTEFFECT's website. We may also
        communicate with you through the contact information you have provided,
        such as your POSTEFFECT account, email, phone, or through one or more
        social network accounts. If you fail to maintain accurate account
        information, such as contact details, you may not receive critical
        information about our Service or these Terms.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Notifications to POSTEFFECT
        </Text>
      </Heading>
      <Text as="p">
        For any notice you provide to POSTEFFECT under or in relation to the
        Agreement, you must notify POSTEFFECT at the email address
        info@Posteffect.ai.
      </Text>

      <Text as="p">
        If you have any questions about these Terms, please contact us at
        infol@Posteffect.ai.
      </Text>
    </Box>
  );
};
