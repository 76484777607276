import { FC } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
} from "recharts";

import { CustomTooltip } from "./custom-tooltip";

interface LineGraphProps {
  data: {
    name: string;
    data: number;
    lastData?: number;
  }[];
}

export const LineGraph: FC<LineGraphProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#57AAEC" stopOpacity={0.15} />
            <stop offset="100%" stopColor="#57AAEC" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis
          axisLine={{ stroke: "#B7BABF", strokeWidth: 1 }}
          dataKey="name"
          scale="point"
          tick={{ fontSize: 12, fontWeight: "400", fill: "#B7BABF" }}
          tickLine={{ stroke: "gray.smoke" }}
        />
        <YAxis
          axisLine={{ stroke: "#B7BABF", strokeWidth: 1 }}
          tick={{ fontSize: 12, fontWeight: "400", fill: "#B7BABF" }}
          tickLine={{ stroke: "gray.smoke" }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="data"
          stroke="#57AAEC"
          fill="url(#colorUv)"
        />
        <Line
          type="monotone"
          dataKey="data"
          stroke="#57AAEC"
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 5, fill: "white", stroke: "#57AAEC", strokeWidth: 2 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
