import { POST } from "@/api";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Text,
  FormErrorMessage,
  Divider,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { FC, useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "@/i18n";
import { useTriggerStore } from "@/context/trigger-store/trigger-store";
import { brandTitle } from "@/constant";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { ConnectPlusIcon } from "@/assets/icons/connect-plus-icon";

export type NewOrganizationForm = z.infer<typeof NewOrganizationForm>;

export const NewOrganizationForm = z.object({
  name: z.string().min(1),
});

export const CreateOrganizationModal: FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewOrganizationForm>({
    resolver: zodResolver(NewOrganizationForm),
  });

  const toast = useToast();

  const onSubmit: SubmitHandler<NewOrganizationForm> = useCallback(
    async (data) => {
      const { response } = await POST("/org/", {
        body: {
          name: data.name,
        },
      });

      if (response.ok) {
        useTriggerStore.getState().triggerOrganization();
        toast({
          status: "success",
          title: t("alert.success.success-organization-created"),
        });
        onClose();

        useAuthStore.getState().setUser();

        return;
      }

      if (response.status === 402) {
        toast({
          status: "error",
          title: t("errors.raise-your-membership"),
        });
        onClose();
        return;
      }

      toast({
        status: "error",
        title: t("errors.an-error-occurred"),
      });
    },
    [onClose, toast]
  );

  return (
    <Box>
      <Button
        onClick={onOpen}
        fontSize="15px"
        bg="blue.light"
        textColor="blue.shiny"
        border="1px solid"
        borderColor="blue.shiny"
        borderRadius="6px"
        _hover={{
          opacity: 0.8,
        }}
        h="38px"
      >
        <IconButton
          aria-label="connect-plus-icon"
          icon={<ConnectPlusIcon fill="#57AAEB" />}
          variant="unstyled"
          pl="10px"
        />
        {t("button.add-new-organization")}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent bg="white">
          <ModalHeader>
            <Text
              color="black.active"
              fontWeight="700"
              fontSize="20px"
              lineHeight="1"
            >
              {t("button.add-new-organization")}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <Divider w="90%" ml="5" my="2" />
          <ModalBody>
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel
                  fontWeight="500"
                  color="black.active"
                  fontSize="16px"
                  mb="4"
                >
                  {t("input.label.organization-name")}
                </FormLabel>
                <InputGroup rounded="6px" h="50px">
                  <Input
                    fontSize="16px"
                    h="full"
                    placeholder={`${brandTitle}.ai`}
                    {...register("name")}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <Flex justifyContent="right" mt="5" mb="10px">
                <Button
                  type="submit"
                  fontSize="15px"
                  bg="black.active"
                  bgColor="blue.shiny"
                  textColor="white"
                  _hover={{
                    opacity: 0.8,
                  }}
                  size="none"
                  h="35px"
                  px="20px"
                  w="160px"
                >
                  {t("button.save-and-create")}
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
