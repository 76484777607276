import { ChangePasswordIcon } from "@/assets/icons/change-password-icon";
import {
  Flex,
  Text,
  Box,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Step1 } from "./change-password/step1";
import { Step2 } from "./change-password/step2";
import { APISchemas, POST } from "@/api";
import { ImmediateSetInterval } from "@/utils/misc";
import { addMinutes, intervalToDuration, isAfter } from "date-fns";
import { Step3 } from "./change-password/step3";
import { Step4 } from "./change-password/step4";

interface EditPasswordProps {
  handleEdit: (
    data: ValidationSchemaType
  ) => Promise<{ path?: string | undefined; message: string }[] | undefined>;
  user: APISchemas["User"];
}

type EditErrorItem = {
  path?: string | undefined;
  message: string;
};

const useTranslatedValidation = () => {
  const { t } = useTranslation();

  const validationSchema = z
    .object({
      oldPassword: z
        .string()
        .min(8, t("errors.password-must-be-at-least-8-characters")),
      newPassword: z
        .string()
        .min(8, t("errors.password-must-be-at-least-8-characters")),
      confirmPassword: z
        .string()
        .min(8, t("errors.password-must-be-at-least-8-characters")),
    })
    .refine((input) => input.confirmPassword === input.newPassword, {
      message: t("errors.passwords-dont-match"),
      path: ["confirmPassword"],
    });
  return validationSchema;
};

type ValidationSchemaType = z.infer<ReturnType<typeof useTranslatedValidation>>;

type PasswordError = EditErrorItem[] | undefined;

export const EditPassword: React.FC<EditPasswordProps> = ({ user }) => {
  const toast = useToast();

  const [passwordError, setPasswordError] = useState<PasswordError>();

  const [verificationCode, setVerificationCode] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    if (passwordError?.some((error) => error !== null)) {
      const timeoutId = setTimeout(() => {
        setPasswordError(undefined);
      }, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [passwordError]);

  // const handleReset = useCallback(() => {
  //   reset({
  //     oldPassword: "",
  //     newPassword: "",
  //     confirmPassword: "",
  //   });
  // }, [reset]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeStep, setActiveStep] = useState(1);

  const openModal = () => {
    setActiveStep(1);
    onOpen();
  };

  const closeModal = () => {
    setActiveStep(1);
    onClose();
  };

  const goToNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const goToPreviousStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  const [duration, setDuration] = useState<{
    minutes: number;
    seconds: number;
    endDate: Date;
  }>({ minutes: 3, seconds: 0, endDate: new Date() });

  useEffect(() => {
    if (activeStep !== 2) return;

    const timer = ImmediateSetInterval(() => {
      setDuration((duration) => {
        const difference = intervalToDuration({
          start: Date.now(),
          end: duration.endDate,
        });
        if (isAfter(Date.now(), duration.endDate)) {
          clearInterval(timer);
          return {
            ...duration,
            minutes: 0,
            seconds: 0,
          };
        }
        return {
          ...duration,
          minutes: difference.minutes ?? 0,
          seconds: difference.seconds ?? 0,
        };
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [activeStep]);

  const onSubmitSendEmail: FormEventHandler<HTMLElement> = useCallback(
    async (event) => {
      event.preventDefault();
      const { response, error } = await POST("/auth/reset-password/", {
        body: { email: user.email },
      });

      if (response.ok) {
        toast({
          description: t("alert.success.verification-code-sent"),
          status: "success",
          isClosable: true,
        });
        setDuration(() => ({
          minutes: 3,
          seconds: 0,
          endDate: addMinutes(new Date(), 3),
        }));
        goToNextStep();
        return;
      }

      if (error) {
        toast({
          description: error.detail,
          status: "error",
          isClosable: true,
        });
      }
    },
    [toast, t]
  );

  const handleVerificationCode = useCallback(
    async (verification_code: string) => {
      const { response, error } = await POST("/auth/check-verification-code/", {
        body: { verification_code },
      });
      if (response.status === 204) {
        toast({
          description: t("page.forgot-password.verification-code-approved"),
          status: "success",
          isClosable: true,
        });
      } else {
        toast({
          description: error?.detail,
          status: "error",
          isClosable: true,
        });
      }

      setVerificationCode(verification_code);
      return response.ok;
    },
    [toast, t, setVerificationCode]
  );

  return (
    <Flex w="full" alignItems="center">
      <Box flex="2" justifyContent="space-between" alignItems="center" w="full">
        <Text
          fontWeight="600"
          lineHeight="20px"
          color="black.dark.default"
          fontSize="24px"
        >
          {t("password")}
        </Text>

        <Text color="gray.smoke" fontSize="13px" lineHeight="20px" mt="1.5">
          {t("page.settings.profile.raise")}
        </Text>
      </Box>
      <Box flex="3" justifyContent="flex-end" alignItems="center">
        <Button
          aria-label="change-password"
          alignItems="center"
          w="268px"
          h="57px"
          leftIcon={<ChangePasswordIcon />}
          bg="green.light"
          mr="20px"
          border="1px solid"
          borderColor="green.label"
          borderRadius="8px"
          variant="unstyled"
          color="green.label"
          onClick={openModal}
        >
          {t("page.settings.profile.change-password")}
        </Button>

        {activeStep === 1 && (
          <Step1
            isOpen={isOpen}
            onClose={closeModal}
            onSubmitSendEmail={onSubmitSendEmail}
          />
        )}
        {activeStep === 2 && (
          <Step2
            isOpen={isOpen}
            onClose={closeModal}
            onVerify={() => goToNextStep()}
            duration={duration}
            setDuration={setDuration}
            onSubmitSendEmail={onSubmitSendEmail}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            handleVerificationCode={handleVerificationCode}
          />
        )}
        {activeStep === 3 && (
          <Step3
            isOpen={isOpen}
            onClose={closeModal}
            activeStep={activeStep}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            verificationCode={verificationCode}
          />
        )}

        {activeStep === 4 && <Step4 isOpen={isOpen} onClose={closeModal} />}
      </Box>
    </Flex>
  );
};
