import { APISchemas } from "@/api";
import { getCurrencySymbol } from "@/constant";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Box,
  Flex,
  List,
  ListIcon,
  ListItem,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export interface PlanCardProps {
  data: APISchemas["Plan"];
  isCurrentPlan?: boolean;
  isDisabled?: boolean;
  isUpgrade?: boolean;
  isDowngrade?: boolean;
  onClick?: () => void;
  newOffer?: APISchemas["SpecialOffer"];
}

export const PlanCard: FC<PlanCardProps> = ({
  data,
  onClick,
  isDisabled,
  isCurrentPlan,
  isUpgrade,
  isDowngrade,
  newOffer,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trueKeys = useMemo(() => {
    const scopedData: Array<{ [key: string]: boolean }> = [];
    for (const subScope of Object.values(data.plan_scope)) {
      for (const [key, value] of Object.entries(subScope as object)) {
        if (value === true) {
          scopedData.push({ [key]: value });
        }
      }
    }
    return scopedData.flatMap((obj) => Object.keys(obj));
  }, [data]);

  const planName = t(`membership-packages.${data.name.toLowerCase()}`);
  return (
    <Flex
      flexShrink="0"
      flexDir="column"
      boxShadow={
        isCurrentPlan
          ? "rgba(0, 0, 0, 0.2) 0px 4px 25px -1px"
          : "rgba(0, 0, 0, 0.05) 0px 4px 25px -1px"
      }
      rounded="8px"
      w="310px"
      h="619px"
      bg="white"
      px="32px"
      pt="24px"
      mb="20"
    >
      <Box w="full">
        <Flex alignItems="center">
          <Text
            mr="8px"
            whiteSpace="nowrap"
            fontSize="32px"
            fontWeight="400"
            color="black.active"
            textTransform="uppercase"
            letterSpacing="-0.32px"
          >
            <Tooltip
              label={planName.length > 12 ? planName : ""}
              placement="top"
            >
              {planName.length > 12 ? planName.slice(0, 12) + "..." : planName}
            </Tooltip>
          </Text>
          {/* <Box
            visibility={data.name === "Basic" ? "visible" : "hidden"}
            bg="blue.shiny"
            color="white"
            w="76px"
            h="30px"
            borderRadius="100px"
          >
            <Text
              fontSize="12px"
              fontWeight="600"
              letterSpacing="0.12px"
              m="1.5"
              textAlign="center"
            >
          {t("membership-packages.best-deal")}
            </Text>
          </Box> */}
        </Flex>
        <Tooltip
          label={t(`membership-packages.${data.description}`)}
          bgColor="black.smooky"
        >
          <Text
            fontSize="16px"
            letterSpacing="0.16px"
            color="black.smooky"
            mt="8px"
            noOfLines={2}
          >
            {t(`membership-packages.${data.description}`)}
          </Text>
        </Tooltip>
      </Box>
      <Flex w="full" p="0px" mt="15px">
        {newOffer ? (
          <Box pos="relative">
            <Flex pos="absolute" top="-3">
              <Text
                mr="2px"
                fontSize="18px"
                textDecoration="line-through"
                color="gray.passive"
                letterSpacing="-0.32px"
                fontWeight="600"
              >
                {getCurrencySymbol(data.plan_kind[0].currency ?? "usd")}
              </Text>
              <Text
                fontSize="18px"
                fontWeight="700"
                color="gray.passive"
                textDecoration="line-through"
              >
                {data.plan_kind[0].price}
              </Text>
            </Flex>
            <Flex>
              <Text
                mr="2px"
                fontSize="32px"
                color="black"
                letterSpacing="-0.32px"
              >
                {getCurrencySymbol(newOffer.currency ?? "usd")}
              </Text>
              <Text fontSize="48px" fontWeight="600" mr="2px">
                {newOffer.amount}
              </Text>
              <Text
                fontSize="14px"
                color="gray.passive"
                position="relative"
                top="40px"
              >
                /{t(`membership-packages.${data.plan_kind[0].plan_period}`)}
              </Text>
            </Flex>
          </Box>
        ) : (
          <Flex>
            <Text
              mr="2px"
              fontSize="32px"
              color="black"
              letterSpacing="-0.32px"
            >
              {getCurrencySymbol(data.plan_kind[0].currency ?? "usd")}
            </Text>
            <Text fontSize="48px" fontWeight="600" mr="2px">
              {data.plan_kind[0].price}
            </Text>
            <Text
              fontSize="14px"
              color="gray.passive"
              position="relative"
              top="40px"
            >
              /{t(`membership-packages.${data.plan_kind[0].plan_period}`)}
            </Text>
          </Flex>
        )}
      </Flex>
      <Button
        mt="18px"
        p="10px"
        bg="blue.shiny"
        variant="unstyled"
        color="white"
        w="max-content"
        h="36px"
        fontSize="14px"
        letterSpacing="0.14px"
        borderRadius="4px"
        size="none"
        _hover={{
          opacity: "0.8",
        }}
        onClick={() => navigate("/membership/plans")}
      >
        {t("button.learn-more")}
      </Button>
      <Text
        mt="30px"
        fontWeight="500"
        fontSize="20px"
        color="#14171C"
        letterSpacing="0.2px"
      >
        {t("include")}
      </Text>
      <Text
        fontWeight="400"
        fontSize="14px"
        color="#536174"
        letterSpacing="0.16px"
      >
        {t("membership-packages.info")}
      </Text>
      <List color="#536174" fontSize="14px" mt="24px" h="160px">
        <OverlayScrollbarsComponent
          options={{ scrollbars: { autoHide: "leave" } }}
        >
          <Box h="160px">
            <ListItem pb="2">
              <ListIcon
                as={CheckIcon}
                boxSize="4"
                color="blue.shiny"
                mr="16px"
              />
              {data.user_limit}{" "}
              {t("membership-packages.plan-details.user_count")}
            </ListItem>
            <ListItem pb="2">
              <ListIcon
                color="blue.shiny"
                as={CheckIcon}
                boxSize="4"
                mr="16px"
              />
              {data.channel_limit}{" "}
              {t("membership-packages.plan-details.channel_limit")}{" "}
            </ListItem>
            <ListItem pb="2">
              <ListIcon
                color="blue.shiny"
                as={CheckIcon}
                boxSize="4"
                mr="16px"
              />

              {data.post_limit === 0
                ? t("limitless")
                : `${data.post_limit} ${t(
                    "membership-packages.plan-details.post_limit"
                  )}`}
            </ListItem>
            <ListItem pb="2">
              <ListIcon
                color="blue.shiny"
                as={CheckIcon}
                boxSize="4"
                mr="16px"
              />
              {data.organization_limit}{" "}
              {t("membership-packages.plan-details.organization_limit")}
            </ListItem>

            {trueKeys ? (
              trueKeys.map((key) => (
                <ListItem key={key} pb="2">
                  <ListIcon
                    color="blue.shiny"
                    as={CheckIcon}
                    boxSize="4"
                    mr="16px"
                  />
                  {t(`membership-packages.plan-details.${key}`)}
                </ListItem>
              ))
            ) : (
              <ListItem pb="2" h="29px"></ListItem>
            )}
          </Box>
        </OverlayScrollbarsComponent>
      </List>
      {isDisabled && (
        <Button
          mt="18px"
          p="10px"
          bg="red.main"
          variant="unstyled"
          color="white"
          w="full"
          h="43px"
          fontSize="16px"
          letterSpacing="0.14px"
          borderRadius="4px"
          size="none"
          fontWeight="600"
          _hover={{
            opacity: "0.8",
          }}
          cursor="not-allowed"
        >
          {t("button.you-are-not-eligible")}
        </Button>
      )}
      {!isDisabled && (
        <>
          <Button
            mt="18px"
            p="10px"
            bg={isCurrentPlan ? "gray.soft" : "blue.shiny"}
            variant="unstyled"
            color={isCurrentPlan ? "black.dark" : "white"}
            w="full"
            h="43px"
            fontSize="16px"
            letterSpacing="0.14px"
            borderRadius="4px"
            size="none"
            fontWeight="600"
            _hover={{
              opacity: "0.8",
            }}
            onClick={onClick}
          >
            {isUpgrade && t("button.upgrade-plan")}
            {isDowngrade && t("button.downgrade-plan")}
            {isCurrentPlan && t("button.current-plan")}
          </Button>
        </>
      )}
    </Flex>
  );
};
