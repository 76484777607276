import { FC, RefObject } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { APISchemas } from "@/api";

interface AlertDialogProps {
  isAlertOpen: boolean;
  onAlertClose: () => void;
  cancelRef: RefObject<HTMLButtonElement>;
  onClick: () => void;
  plan: APISchemas["Plan"];
  membership?: APISchemas["Membership"];
}

export const PlanDowngradeAlert: FC<AlertDialogProps> = ({
  isAlertOpen,
  onAlertClose,
  cancelRef,
  onClick,
  plan,
  membership,
}) => {
  const { t } = useTranslation();

  let alertMessage = "";

  if (
    membership?.plan_kind.plan_period === "monthly" &&
    plan?.name === "Free"
  ) {
    alertMessage = t("alert.downgrade-modal.downgrade-from-monthly");
  } else if (
    membership?.plan_kind.plan_period === "yearly" &&
    plan?.name === "Free"
  ) {
    alertMessage = t("alert.downgrade-modal.downgrade-from-yearly");
  } else {
    alertMessage = t("alert.downgrade-modal.are-you-sure");
  }

  return (
    <AlertDialog
      isOpen={isAlertOpen}
      leastDestructiveRef={cancelRef}
      onClose={onAlertClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="white.bg">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("alert.downgrade-modal.change-plan")}
          </AlertDialogHeader>

          <AlertDialogBody>{alertMessage}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              bg="red"
              color="white"
              ref={cancelRef}
              onClick={onAlertClose}
            >
              {t("alert.downgrade-modal.cancel")}
            </Button>
            <Button bg="green.label" color="white" onClick={onClick} ml={3}>
              {t("alert.downgrade-modal.confirm")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
