import {
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";

export interface AddMusicProps {
  onChange: (value?: string) => void;
}

export const AddMusicGroup: FC<AddMusicProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const [addedMusic, setAddedMusic] = useState<string | undefined>("");

  return (
    <Flex>
      <InputGroup alignItems="center" justifyContent="center">
        <Input
          value={addedMusic}
          onChange={(e) => setAddedMusic(e.target.value)}
          p="2"
          fontSize="14px"
          borderRadius="3px"
          _placeholder={{
            color: "gray.smoke",
            fontSize: "12px",
            fontWeight: "500",
          }}
          placeholder={t("edit-video-modal.rename-sound")}
          bgColor="white"
          color="black.active"
          variant="unstyled"
          h="7"
          fontWeight="500"
        />
        <InputRightElement h="full" w="min-content" justifyContent="center">
          <Button
            size="none"
            px="3"
            py="1"
            bgColor="green.label"
            cursor="pointer"
            color="white"
            onClick={() => {
              setAddedMusic("");
              onChange(addedMusic);
            }}
            fontSize="12px"
            fontWeight="500"
          >
            {t("edit-video-modal.rename")}
          </Button>
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};
