import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { useFormStore } from "../form-store";
import { StandartFile } from "../common";
import { APISchemas, PATCH, POST } from "@/api";
import { useToast } from "@chakra-ui/react";
import { YoutubeFormPresent } from "../forms-presents";
import { handleError } from "./_handle-errors";
import isEqual from "lodash.isequal";
import { formUpdateMS } from "../contant";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { i18n } from "@/i18n";

export type youtubeSchema = z.infer<typeof youtubeSchema>;
export const youtubeSchema = z
  .object({
    id: z.number().optional(),
    content: z.string().optional(),
    files: StandartFile.array().optional(),
    headline: z.string().min(1),
  })
  .refine((o) => o.files && o.files.length === 1 && o.files.at(0)?.data.video, {
    path: ["root"],
    message: "Youtube post must have one video!",
  });

export const YoutubeFormContainer: FC<{
  channel: APISchemas["Channel"] & { hasError?: boolean };
  defaultValue?: youtubeSchema;
}> = ({ channel, defaultValue }) => {
  const activeOrganization = useAuthStore((s) => s.activeOrganization);
  const defaultValueRef = useRef<typeof defaultValue>();
  const toast = useToast();

  const formSync = useFormStore((f) => f.formSync);

  const [formState, setFormState] = useFormStore((f) => [
    f.fromStates[channel.id.toString()] ?? {
      sync: true,
    },
    f.setFormState,
  ]);
  const [choosenPlaylist, setChoosenPlaylist] = useState<string | undefined>();

  const {
    main,
    scheduledAt,
    addYoutubePost,
    setForm,
    subscribe,
    unsubscribe,
    blockProgress,
    goToStep,
    setPlatformHasError,
    setGlobalError,
  } = useFormStore((s) => ({
    main: s.mainForms.main,
    setForm: s.setForm,
    subscribe: s.subscribe,
    unsubscribe: s.unsubscribe,
    addYoutubePost: s.addYoutubePost,
    scheduledAt: s.scheduledAt,
    blockProgress: s.setProgressBlock,
    goToStep: s.goToStep,
    setPlatformHasError: s.setPlatformHasError,
    setGlobalError: s.setGlobalError,
  }));

  const methods = useForm<youtubeSchema>({
    resolver: zodResolver(youtubeSchema),
  });

  const {
    getValues,
    trigger,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  if (!isEqual(defaultValue, defaultValueRef.current)) {
    reset(defaultValue);
    setFormState(channel.id.toString(), {
      sync: false,
    });
    defaultValueRef.current = defaultValue;
  }

  const draftForm = useCallback(async () => {
    const result = await trigger();

    if (!result) {
      setPlatformHasError(channel, true);

      return;
    }

    setPlatformHasError(channel, false);
    setForm(channel.id, getValues());
  }, [channel, getValues, setForm, setPlatformHasError, trigger]);

  const onSubmit: (
    status: APISchemas["StatusCf9Enum"]
  ) => SubmitHandler<youtubeSchema> = useCallback(
    (status) => async (data) => {
      if (!activeOrganization) return;
      const coverUrls: Record<string, string> = {};
      const userTags: APISchemas["MetadataRequest"]["user_tags"] = {};
      const audioNames: string[] = [];
      data.files?.forEach((file) => {
        if (file.data.metadata?.audioName) {
          audioNames.push(file.data.metadata.audioName);
        }

        if (file.data.metadata?.userTags) {
          const id = file.data.image?.id
            ? file.data.image?.id
            : file.data.video?.id
              ? file.data.video.id
              : null;

          if (id) {
            userTags[id] = {
              tags: file.data.metadata?.userTags,
            };
          }
        }

        if (!file.data.video) {
          return;
        }
        if (!("coverUrl" in file.data.video)) {
          return;
        }
        if (
          !file.data.video.coverUrl ||
          typeof file.data.video.coverUrl !== "string"
        ) {
          return;
        }
        coverUrls[file.data.video.id] = file.data.video.coverUrl;
      });
      const body: APISchemas["YoutubePostCreateRequest"] = {
        body: data.content,
        channel: channel.id,
        status,
        scheduled_at: scheduledAt.toISOString(),
        metadata: {
          cover_url: coverUrls,
          title: data.headline,
          playlist_id: choosenPlaylist,
        },

        media:
          data.files
            ?.map((f) => f.data.image?.id || f.data.video?.id)
            .filter((m): m is string => !!m)
            .map((m) => Number(m)) ?? [],
      };

      const { data: post, error } = defaultValueRef.current?.id
        ? await PATCH("/youtube/{org_pk}/post/{id}/", {
            params: {
              path: {
                org_pk: activeOrganization?.id.toString(),
                id: defaultValueRef.current.id,
              },
            },
            body,
          })
        : await POST("/youtube/{org_pk}/post/", {
            params: {
              path: {
                org_pk: activeOrganization.id.toString(),
              },
            },
            body,
          });

      if (error) {
        blockProgress(true);
        handleError(channel.id.toString(), error, setGlobalError);
        setPlatformHasError(channel, true);
        goToStep?.(1);
        return;
      }

      if (!post) {
        toast({
          status: "error",
          title: i18n.t("errors.unexpected-error-contact-customer-service"),
        });
        return;
      }

      setPlatformHasError(channel, false);
      addYoutubePost(post);
    },
    [
      channel,
      scheduledAt,
      setPlatformHasError,
      addYoutubePost,
      blockProgress,
      setGlobalError,
      goToStep,
      toast,
      activeOrganization,
      choosenPlaylist,
    ]
  );

  useEffect(() => {
    if (main && formState.sync) {
      const values = getValues();
      reset({
        ...values,
        content: main.content,
        files: main.files,
      });
    }
  }, [formState.sync, main, reset]);

  useEffect(() => {
    if (isDirty) {
      setFormState(channel.id.toString(), {
        sync: false,
      });
    }
  }, [channel.id, isDirty, setFormState]);

  useEffect(() => {
    const submitFn = handleSubmit(onSubmit("planned"));
    const draftFn = handleSubmit(onSubmit("draft"));
    subscribe("submit", submitFn);
    subscribe("check", draftForm);
    subscribe("draft", draftFn);

    return () => {
      unsubscribe("submit", submitFn);
      unsubscribe("check", draftForm);
      unsubscribe("draft", draftFn);
    };
  }, [draftForm, handleSubmit, onSubmit, subscribe, unsubscribe]);

  useEffect(() => {
    if (!formSync) {
      return;
    }

    let prev: youtubeSchema = {
      headline: "",
    };

    const interval = setInterval(() => {
      const values = getValues();

      if (isEqual(prev, values)) {
        return;
      }

      if (channel.hasError) {
        draftForm();
      }

      setForm(channel.id, values);
      prev = values;
    }, formUpdateMS);
    return () => {
      clearInterval(interval);
    };
  }, [channel, draftForm, formSync, getValues, setForm]);

  return (
    <FormProvider {...methods}>
      <YoutubeFormPresent
        channel={channel}
        playlist={(e) => setChoosenPlaylist(e)}
      />
    </FormProvider>
  );
};
