import { Box, Heading, Text } from "@chakra-ui/react";

export const TermAndConditionTr = () => {
  return (
    <Box>
      <Text as="p">
        Bu Hizmet Koşulları ("Koşullar", "Hizmet Koşulları" veya "Sözleşme"),
        Posteffect.ai'da bulunan web sitelerimiz ile birlikte GDH Medya ve
        Teknoloji Hizmetleri A.Ş. ("POSTEFFECT", "biz" veya "bizim") tarafından
        sağlanan ilgili web sitelerimiz, ağlarımız, uygulamalarımız,
        yazılımlarımız ve mobil uygulamalarımız (her biri "Uygulama" ve birlikte
        "Uygulamalar") ile ilişkinizi yönetmektedir.
      </Text>

      <Text as="p">
        Bu Koşulları, herhangi bir yazılım lisans anlaşmasıyla birlikte sizinle
        (her biri bir "Kullanıcı" ve toplu olarak "Kullanıcılar") sözleşmeye
        dayalı ilişkimizi oluşturan Gizlilik Politikamız ve Çerez Politikamızla
        birlikte okumalısınız. POSTEFFECT Gizlilik Politikası ve Çerez
        Politikası, bu referans ile bu Koşullara dahil edilmiş ve bir parçası
        haline getirilmiştir.
      </Text>

      <Text as="p">
        Lütfen bu Koşulları dikkatlice okuyun ve anladığınızdan emin olun. Bu
        Koşulları anlamazsanız veya herhangi bir bölümünü kabul etmezseniz,
        Hizmeti kullanamazsınız.
      </Text>
      <Text as="p">
        Hizmetlere erişiminiz ve Hizmetleri kullanımınız, bu Koşulları kabul
        etmeniz ve bunlara uymanıza bağlıdır. Bu Koşullar, Hizmete erişen veya
        Hizmeti kullanan tüm ziyaretçiler, kullanıcılar ve diğerleri için
        geçerlidir.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Üyelikler
        </Text>
      </Heading>

      <Text as="p">
        Hizmetin bazı bölümleri Üyelik temelinde faturalandırılır ("Üyelik" veya
        "Plan"). Tekerrür eden ve periyodik olarak önceden
        faturalandırılacaksınız ("Fatura Döngüsü"). Fatura döngüleri, bir Plan
        satın alırken seçtiğiniz Üyelik türüne bağlı olarak aylık veya yıllık
        olarak belirlenir.
      </Text>

      <Text as="p">
        Her Fatura Döngüsünün sonunda, Aboneliğiniz siz iptal etmediğiniz veya
        POSTEFFECT iptal etmediği sürece tamamen aynı koşullar altında otomatik
        olarak yenilenecektir. Üyelik yenilemenizi, Uygulama içindeki "Üyelik"
        sayfasına erişerek iptal edebilirsiniz.
      </Text>
      <Text as="p">
        Aboneliğinizin ödemesini işleme koymak için kredi kartı dahil olmak
        üzere geçerli bir ödeme yöntemi gereklidir. Tam ad, adres, eyalet, posta
        kodu, telefon numarası ve geçerli bir ödeme yöntemi bilgileri dahil
        olmak üzere POSTEFFECT'a doğru ve eksiksiz fatura bilgileri
        sağlamalısınız. Bu tür ödeme bilgilerini göndererek, hesabınız
        aracılığıyla oluşan tüm Üyelik ücretlerini bu tür ödeme araçlarından
        tahsil etmesi için POSTEFFECT'a otomatik olarak yetki vermiş
        olmaktasınız.
      </Text>
      <Text as="p">
        Otomatik faturalamanın herhangi bir nedenle gerçekleşmemesi durumunda,
        POSTEFFECT, faturada belirtilen fatura dönemine karşılık gelen tam ödeme
        ile, belirli bir son tarih içinde manüel olarak devam etmeniz
        gerektiğini belirten bir elektronik fatura düzenleyecektir.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Yükseltmeler ve Düşürmeler
        </Text>
      </Heading>

      <Text as="p">
        Üyelik süreniz boyunca Planınızı yükseltmeyi veya Planınıza herhangi bir
        Eklenti yapmayı seçerseniz, Üyelik sürenizde kalan gün sayısına göre
        orantılı olarak yükseltme veya Eklentiler için o sırada geçerli olan
        fiyat üzerinden ücretlendirileceksiniz. Hizmet siparişinizde aksi
        belirtilmedikçe, eklediğiniz herhangi bir yükseltme veya Eklenti, mevcut
        Plan ile aynı anda sona erecek ve Planınızla birlikte Üyelik döneminin
        sonunda otomatik olarak yenilenecektir. Planınızı eski sürüme geçirmeyi
        veya Planınızdan herhangi bir Eklentiyi kaldırmayı seçerseniz, eski
        sürüme geçme veya kaldırmayla bağlantılı olarak kullanılmayan ve önceden
        ödenmiş ücretler için size herhangi bir geri ödeme yapılmaz veya kredi
        verilmez. Planınızın eski sürüme geçirilmesi, hesabınızın içeriğinin,
        özelliklerinin veya kapasitesinin kaybolmasına neden olabilir ve bu tür
        kayıplar için herhangi bir sorumluluk kabul etmemekteyiz.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Ücret Değişiklikleri
        </Text>
      </Heading>

      <Text as="p">
        POSTEFFECT, tamamen kendi takdirine bağlı olarak ve herhangi bir
        zamanda, Üyelik ücretlerini değiştirebilir. Herhangi bir Üyelik ücreti
        değişikliği, o sırada geçerli olan Fatura Döngüsünün sonunda yürürlüğe
        girecektir.
      </Text>

      <Text as="p">
        POSTEFFECT, bu tür bir değişiklik yürürlüğe girmeden önce size
        Üyeliğinizi sonlandırma fırsatı vermek adına, Üyelik ücretlerindeki
        herhangi bir değişikliği size makul bir önceden bildirecektir.
      </Text>
      <Text as="p">
        Üyelik ücreti değişikliği yürürlüğe girdikten sonra Hizmeti kullanmaya
        devam etmeniz, değiştirilen Üyelik ücreti tutarını kabulünüz teşkil
        eder.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Vergiler
        </Text>
      </Heading>

      <Text as="p">
        Bize ödediğiniz tüm ücretler için tüm vergileri ödemekten alıcı firma
        yasal çerçeveler uhdesinde sorumludur. Yerel vergiler, ödeme yönteminize
        göre farklılık gösterebilir.
      </Text>
      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          İptal
        </Text>
      </Heading>

      <Text as="p">
        Bizimle olan yolculuğunuzun herhangi bir aşamasında Üyelik iptalini
        sizin için mümkün kılıyoruz.
      </Text>
      <Text as="p">
        Herhangi bir zamanda bir iptal talebinde bulunulabilirsiniz, ancak
        değişiklik yalnızca faturalandırma döngünüzün sonunda yürürlüğe girecek
        ve önceden ödenmiş ve kullanılmamış ücretler ile ilgili Planınız
        kapsamındaki ödenmemiş ücretler için herhangi bir geri ödeme alma
        hakkınız olmayacaktır ve ilgili Üyelik süresince ödenmemiş tüm
        meblağları derhal muaccel hale gelecektir.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          İçerik
        </Text>
      </Heading>

      <Text as="p">
        Hizmetlerimiz, belirli bilgileri, metinleri, grafikleri, videoları veya
        diğer materyalleri ("İçerik") yayınlamanıza, bağlantılandırmanıza,
        depolamanıza, paylaşmanıza ve başka şekillerde kullanmanıza olanak
        tanır. Yasallığı, güvenilirliği ve uygunluğu da dahil olmak üzere,
        Hizmete gönderdiğiniz İçerikten kullanıcı firma sorumludur.
      </Text>
      <Text as="p">
        İçeriği Hizmete göndererek, yükleyerek veya başka bir şekilde sunarak,
        bize bu İçeriği Hizmette ve Hizmet aracılığıyla kullanma, değiştirme,
        halka açık bir şekilde gerçekleştirme, halka açık bir şekilde
        görüntüleme, çoğaltma ve dağıtma hakkı ve lisansı vermiş olmaktasınız.
        Hizmet üzerinde veya aracılığıyla gönderdiğiniz, yayınladığınız,
        yüklediğiniz veya görüntülediğiniz herhangi bir İçeriğe ilişkin tüm
        haklarınızı saklı tutmaktasınız ve bu hakları korumaktan kullanıcı firma
        sorumludur.
      </Text>

      <Text as="p">
        Şunları beyan ve taahhüt edersiniz: (i) İçeriğin size ait olduğunu (size
        ait olduğunu) veya onu kullanma ve bu Koşullarda belirtilen hakları ve
        lisansı bize verme hakkına sahip olduğunuzu ve (ii) içeriğin
        yayınlanması, yüklenmesi veya sunulması Hizmette veya Hizmet
        aracılığıyla İçeriğiniz herhangi bir kişinin gizlilik haklarını, tanıtım
        haklarını, telif haklarını, sözleşme haklarını veya diğer haklarını
        ihlal etmediğini.
      </Text>
      <Text as="p">
        Aşağıdakileri belirlediğimiz iletişimleri veya materyalleri engelleme
        veya kaldırma hakkımızı saklı tutarız: (a) taciz edici, karalayıcı veya
        müstehcen; (b) hileli, aldatıcı veya yanıltıcı; (c) bir başkasının telif
        hakkını, ticari markasını veya diğer fikri mülkiyet hakkını ihlal eden;
        (d) tamamen kendi takdirimize bağlı olarak bizim için saldırgan veya
        başka şekilde kabul edilemez olan.
      </Text>
      <Text as="p">
        Size Hizmette kullanıcı tarafından oluşturulan içeriği görüntüleme ve
        dağıtma olanağı sağlayarak, yalnızca bu tür dağıtım için pasif bir kanal
        olarak hareket ettiğimizi ve Hizmetteki herhangi bir içerik veya
        faaliyetle ilgili herhangi bir yükümlülük veya sorumluluk
        üstlenmediğimizi kabul etmektesiniz.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Hesaplar
        </Text>
      </Heading>

      <Text as="p">
        Bizde bir hesap oluşturduğunuzda, bize her zaman doğru, eksiksiz ve
        güncel bilgiler sağlamalısınız. Bunu yapmamak, Koşulların ihlali
        anlamına gelir ve bu da Hizmetimizdeki hesabınızın derhal feshedilmesine
        neden olabilir.
      </Text>
      <Text as="p">
        Hizmete erişmek için kullandığınız şifreyi korumaktan ve Hizmetimizde
        veya bir üçüncü şahıs hizmetinde olsun, şifreniz altındaki tüm
        faaliyetler veya eylemlerden kullanıcı firma sorumludur
      </Text>

      <Text as="p">
        Şifrenizi herhangi bir üçüncü şahısla paylaşmamayı kabul etmektesiniz.
        Herhangi bir güvenlik ihlali veya hesabınızın yetkisiz kullanımından
        haberdar olur olmaz bizi hemen bilgilendirmelisiniz.
      </Text>
      <Text as="p">
        Kullanıcı adı olarak başka bir kişi veya kuruluşun adını veya yasal
        olarak kullanım için uygun olmayan bir adı, sizin dışınızda başka bir
        kişi veya kuruluşun haklarına tabi olan bir adı veya ticari markayı
        uygun yetkiye sahip olmaksızın veya saldırgan, kaba veya müstehcen olan
        bir adı kullanamazsınız.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Mobil Uygulama
        </Text>
      </Heading>

      <Text as="p">
        Mobil Uygulamaların kullanımı, mobil cihazınızın Mobil Uygulamalar ile
        uyumlu olmasını gerektirir. Mobil Uygulamalar, kullanıcı ayarlarınıza
        bağlı olarak zaman zaman otomatik olarak yükseltilebilir. Bu tür
        otomatik yükseltmeleri ve Mobil Uygulamaları kullanımınız için geçerli
        olabilecek standart operatör veri ücretlerini kabul etmektesiniz.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Üçüncü Taraf Ürün ve Hizmetleri
        </Text>
      </Heading>

      <Text as="p">
        POSTEFFECT hesabınızı oluştururken bize doğru bilgi vermelisiniz.
        POSTEFFECT hesabınız, zaman zaman ve tamamen kendi takdirimize bağlı
        olarak kurabileceğimiz ve sürdürebileceğimiz Hizmete ve işlevlere
        erişmenizi sağlar. Farklı türdeki kullanıcılar veya kuruluşlar için
        farklı hesap türleri tutabiliriz. POSTEFFECT'a üçüncü taraf bir hizmetle
        bağlanırsanız, bu hizmetin izin verdiği şekilde bu hizmetten
        bilgilerinize erişmemize ve kullanmamıza ve bu hizmet için oturum açma
        kimlik bilgilerinizi veya API belirteçlerinizi saklamamıza izin vermiş
        olmaktasınız. Başka bir kullanıcının hesabını izinsiz olarak asla
        kullanamazsınız.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Gizlilik Bildirimi
        </Text>
      </Heading>

      <Text as="p">
        Gizliliğinizi korumayı taahhüt etmektesiniz. POSTEFFECT bünyesindeki
        yetkili çalışanlar, bilmesi gereken temelde, yalnızca bireysel
        Kullanıcılardan toplanan bilgileri kullanır. Kullanıcılarımıza mümkün
        olan en iyi hizmeti sağlamak için sistemlerimizi ve verilerimizi sürekli
        gözden geçiriyoruz. Bilgisayar sistemlerine ve verilere karşı yetkisiz
        eylemler için belirli suçlar vardır. Sorumlulara karşı tazminat davası
        açmak ve / veya hukuk davası açmak amacıyla bu tür eylemleri
        soruşturacağız.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Gizlilik
        </Text>
      </Heading>

      <Text as="p">
        Kullanıcı ve ilgili Kullanıcı kayıtları ile ilgili her türlü bilgi
        üçüncü şahıslara aktarılabilir. Bununla birlikte, Kullanıcı kayıtları
        gizli olarak kabul edilir ve bu nedenle çalışanlarımız dışında herhangi
        bir üçüncü şahsa ve yasal olarak gerekli olması halinde uygun
        yetkililere ifşa edilmeyecektir. Kullanıcılar, tuttuğumuz tüm Kullanıcı
        kayıtlarının görüntülerini ve kopyalarını talep etme hakkına sahiptir,
        ancak bu tür bir talep bize makul bir süre önceden bildirilmiş
        olmalıdır. Kullanıcılardan, hizmetlerimizin sağlanmasıyla ilgili olarak
        yayınlanan her türlü belgenin kopyalarını saklamaları istenir. Uygun
        olduğu durumlarda, her iki tarafın da menfaati için, üzerinde anlaşmaya
        varılan bir sözleşmenin parçası olarak Kullanıcıya uygun yazılı
        bilgileri, bildirileri veya kayıtların kopyalarını vereceğiz.
      </Text>

      <Text as="p">
        Kişisel bilgilerinizi herhangi bir üçüncü şahsa satmayacağız,
        paylaşmayacağız veya kiralamayacağız veya e-posta adresinizi istenmeyen
        postalar için kullanmayacağız. POSTEFFECT tarafından gönderilen tüm
        e-postalar, yalnızca kararlaştırılan hizmetlerin ve ürünlerin
        sağlanmasıyla bağlantılı olacaktır. Sizin veya Kullanıcılarınızın
        Hizmeti kullanımından kaynaklanan verileriniz ve bilgileriniz üzerinde
        istatistiksel analizlere sahip olma ve bunları tamamlama hakkına sahip
        olduğumuzu kabul edersiniz (kişisel olarak tanımlanabilir veriler
        hariç). Bu verileri çıkardığımızda, derlediğimizde, sentezlediğimizde
        veya analiz ettiğimizde, verilerin kaynağını belirtmeden yalnızca
        anonimleştirilmiş, tanımlanmamış veya toplu biçimde kullanacağız. Bu tür
        verileri herhangi bir yasal amaç için ve size karşı bir muhasebe
        yükümlülüğü olmaksızın topluyoruz.
      </Text>

      <Text as="p">
        Önerilerinizi, yorumlarınızı, hata raporlarınızı, özellik taleplerinizi
        veya diğer geri bildirimlerinizi ("Geri Bildirim") bekliyoruz. Bize
        gizli olduğunu söyleseniz bile Geri Bildirimi gizli tutmak zorunda
        değiliz. Geri Bildirim sağlarsanız, Geri Bildirimi herhangi bir amaçla
        kullanmak için POSTEFFECT'a münhasır olmayan, dünya çapında, kalıcı,
        geri alınamaz, devredilebilir, alt lisanslanabilir, telifsiz, tamamen
        ödenmiş bir lisans verirsiniz.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Zorlayıcı Sebepler
        </Text>
      </Heading>

      <Text as="p">
        Herhangi bir taraf, kontrolümüz dışında ve bir sözleşmenin veya
        anlaşmanın feshine sebebiyet verecek veya makul şekilde öngörülemeyecek
        olan Doğal Afetler, terörizm, savaş, Politik ayaklanma, başkaldırı,
        isyan, sivil kargaşa, kamu veya askeri makam eylemleri, ayaklanma,
        deprem, sel veya kontrolümüz dışında olan bir doğal veya insan yapımı
        sebepler dahil olmak üzere diğer tarafın kontrolü dışındaki bir sebepten
        ötürü işbu Koşullardaki herhangi bir yükümlülüğü ifa edemezse bundan
        sorumlu değildir. Bu tür olaydan etkilenen herhangi bir Taraf, diğer
        Tarafı derhal bilgilendirecek ve burada yer alan herhangi bir
        Sözleşmenin hüküm ve koşullarına uymak için tüm makul çabayı
        gösterecektir.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Devredilebilirlik
        </Text>
      </Heading>

      <Text as="p">
        Taraflardan hiçbiri, diğer tarafın önceden yazılı izni olmaksızın (makul
        bir sebep olmaksızın imtina edilemeyecek veya geciktirilmeyecektir) bu
        Koşullar kapsamındaki haklarını, görevlerini ve yükümlülüklerini
        devredemez; ancak, POSTEFFECT, işbu Koşulları ve POSTEFFECT'a işbu
        koşullarda verilen lisansları, sizin onayınız olmadan ve halefin
        POSTEFFECT'ın işbu Koşullardaki yükümlülüklerini üstlenmeyi ve yerine
        getirmeyi kabul etmesi kaydıyla bir halefe temlik edebilir (birleşme,
        devralma, malvarlığının satılması veya kanunun uygulanması yoluyla halef
        olanlar dahil).
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Feragat ve Bölünebilirlik
        </Text>
      </Heading>

      <Text as="p">
        POSTEFFECT tarafından bu Koşullarda belirtilen herhangi bir şart veya
        koşuldan feragat edilmesi, bu Koşulların sair hükümlerinden devam eden
        veya ek feragat anlamına gelmez ve POSTEFFECT'ın işbu Koşullar
        kapsamındaki bir hak veya hükme dayanmaması, bu hak veya hükümden
        feragat edildiği anlamına gelmez. Bu Koşulların herhangi bir hükmünün
        bir mahkeme veya başka bir yetkili yargı mercii tarafından herhangi bir
        nedenle geçersiz, uygulanamaz veya yasa dışı olduğuna karar verilirse,
        bu hüküm, Koşulların geri kalan hükümleri tam yürürlükte olmaya kalacak
        şekilde asgari ölçüde sınırlandırılacaktır.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Garanti
        </Text>
      </Heading>
      <Text as="p">
        HİZMET "OLDUĞU GİBİ" VE "MEVCUT OLDUĞU GİBİ" ESASINA GÖRE SAĞLANIR.
        HİZMETLERİ KULLANMANIZA İLİŞKİN RİSK TAMAMEN SİZE AİTTİR. UYGULANABİLİR
        YASALARIN İZİN VERDİĞİ AZAMİ ÖLÇÜDE, HİZMET, TİCARİ ELDE EDİLEBİLİRLİK,
        BELİRLİ BİR AMACA UYGUNLUK VEYA BELİRLİ BİR AMACA UYGUNLUK DAHİL ANCAK
        BUNLARLA SINIRLI OLMAMAK ÜZERE AÇIK VEYA ZIMNİ HERHANGİ BİR GARANTİ
        OLMAKSIZIN SAĞLANIR. YUKARIDAKİLERİ SINIRLAMAKSIZIN, POSTEFFECT VE
        LİSANS VERENLERİ, İÇERİĞİN DOĞRU, GÜVENİLİR VEYA DOĞRU OLDUĞUNU,
        HİZMETİN GEREKSİNİMLERİNİZİ KARŞILAYACAĞINI; HİZMETİN HERHANGİ BİR
        ZAMANDA VEYA KONUMDA, KESİNTİSİZ VEYA GÜVENLİ OLACAĞINI; HERHANGİ BİR
        KUSUR VEYA HATA DÜZELTİLECEĞİNİ VEYA HİZMETİN VİRÜSLER VEYA DİĞER
        ZARARLI BİLEŞENLER İÇERMEDİĞİNİ TAAHHÜT ETMEZ. HİZMETİN KULLANIMI
        YOLUYLA İNDİRİLEN VEYA BAŞKA BİR ŞEKİLDE ELDE EDİLEN HERHANGİ BİR İÇERİK
        KENDİ RİSKİNİZDE İNDİRİLİR VE BİLGİSAYAR SİSTEMİNİZİN VEYA MOBİL
        CİHAZINIZIN YA DA HİZMETİNİZİN KULLANIMINDAN KAYNAKLANAN VERİLERİN
        KAYBINDAN YALNIZCA SİZİN SORUMLU OLACAKSINIZ. HİZMETİMİZ YA DA HERHANGİ
        BİR WEB SİTESİ VEYA HİZMET ARACILIĞIYLA SUNULAN ÜÇÜNCÜ TARAF HİZMETLERİ
        İÇİN GARANTİ VERMİYOR, ONAYLAMIYORUZ, GARANTİ VERMİYOR VEYA SORUMLULUK
        KABUL ETMİYORUZ VE SİZİN VE ÜRÜN VEYA HİZMET SAĞLAYICILARI ÜÇÜNCÜ
        TARAFIN ARASINDAKİ HERHANGİ BİR İŞLEMİN TARAFI DEĞİLİZ VEYA HERHANGİ BİR
        ŞEKİLDE BUNLARI İZLEMEMEKTEYİZ. BU ÜÇÜNCÜ TARAF HİZMETLERİNİN VEYA
        DESTEKLENEN PLATFORMLARIN BAŞARISIZ OLMASI DAHİL, HİZMETLERLE İLGİLİ
        VEYA HİZMETLERLE BAĞLANTILI OLARAK KULLANILAN DESTEKLENEN PLATFORMLARDAN
        HİÇBİR ŞEKİLDE SORUMLU VEYA YÜKÜMLÜ DEĞİLİZ.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Tazminat
        </Text>
      </Heading>
      <Text as="p">
        POSTEFFECT'ı, müdürlerini, yöneticilerini, temsilcilerini,
        çalışanlarını, yüklenicileri, lisans verenlerini, lisans sahiplerini,
        tedarikçilerini ve acentelerini her türlü talep, kayıp, hasar, kayıp,
        yükümlülük, maliyet, eylem veya taleplere karşı tazmin etmeyi, savunmayı
        ve zarardan ari tutmayı kabul etmektesiniz.
      </Text>
      <Text as="p">
        Bunlara aşağıdakiler dahildir ancak bunlarla sınırlı değildir: (a)
        Hizmeti kullanımınızdan kaynaklanan yasal ve muhasebe ücretleri; (b) bu
        Koşullardan herhangi birini ihlal etmeniz; (c) Hizmete gönderdiğiniz
        veya yüklediğiniz veya başka şekilde gönderdiğiniz her şey; ve (d)
        hesabınızla ilgili herhangi bir etkinlik. Bu, sizin, hesabınızı
        kullanarak Hizmete erişen herhangi bir kişi veya kuruluş tarafından
        hileli veya yasa dışı yollarla elde edilmiş olsun, herhangi bir ihmal
        veya yasa dışı davranışı içerir.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Sorumluluğun Sınırlandırılması
        </Text>
      </Heading>
      <Text as="p">
        POSTEFFECT, yöneticileri, çalışanları, ortakları, temsilcileri,
        tedarikçileri veya bağlı kuruluşları, ekonomik kayıp, kayıp veya hasar
        dahil ancak bunlarla sınırlı olmamak üzere şunlardan kaynaklanan
        doğrudan veya dolaylı, arızi, özel, sonuçsal veya cezai zararlardan
        sorumlu olmayacaktır: (i) Hizmete erişiminiz veya Hizmeti kullanmanızdan
        kaynaklanan elektronik ortam veya veriler, itibar veya diğer maddi
        olmayan kayıplar; (ii) Hizmete erişememeniz veya Hizmeti kullanamamanız;
        (iii) Hizmet üzerindeki veya Hizmetle ilgili herhangi bir üçüncü tarafın
        herhangi bir davranışı veya içeriği; (iiv) Hizmetten veya Hizmet
        aracılığıyla elde edilen herhangi bir içerik; ve (v) ister garantiye,
        sözleşmeye, haksız fiile (ihmal dahil) veya kanundaki herhangi bir hak
        talebine dayalı olsun, bu zararın ihtimaline ilişkin bilgilendirilmiş
        olup olmadığına bakılmaksızın ve burada belirtilen bir çarenin asli
        amacını yerine getirmediği tespit edilse dahi, iletilerinize veya
        içeriğinize yetkisiz erişim, bunları kullanma veya değiştirme.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Telif Hakkı ve Ticari Marka
        </Text>
      </Heading>
      <Text as="p">
        Telif hakkı ve diğer ilgili fikri mülkiyet hakları, POSTEFFECT'ın
        hizmetleriyle ilgili tüm metinlerde ve bu web sitesinin tüm içeriğinde
        mevcuttur.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Değişikliklerin Bildirilmesi
        </Text>
      </Heading>
      <Text as="p">
        Tamamen kendi takdirimize bağlı olarak, bu Koşulları herhangi bir
        zamanda modifiye etme veya değiştirme hakkımızı saklı tutarız. Bir
        revizyon esaslıysa, yeni şartların yürürlüğe girmesinden önce en az 15
        gün önceden bildirimde bulunmak adına makul çaba göstereceğiz. Esaslı
        değişikliği neyin oluşturduğuna tamamen kendi takdirimizdedir.
      </Text>
      <Text as="p">
        Bu revizyonlar yürürlüğe girdikten sonra Hizmetimize erişmeye veya
        kullanmaya devam ederseniz, revize edilen şartlara bağlı kalmayı kabul
        etmiş olursunuz. Yeni şartları tamamen veya kısmen kabul etmiyorsanız,
        lütfen web sitesini ve Hizmeti kullanmayı bırakın.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          Bildirimler
        </Text>
      </Heading>
      <Text as="p">
        POSTEFFECT platformuna veya POSTEFFECT'ın web sitesine bir banner
        bildirimi yerleştirerek size bildirimde bulunabilir. Ayrıca sizinle
        POSTEFFECT hesabınız veya e-posta veya telefon gibi sağladığınız
        iletişim bilgileri veya bir veya daha fazla Sosyal Ağdaki hesabınız
        aracılığıyla da iletişim kurabiliriz. İletişim bilgileri gibi doğru
        hesap bilgilerini tutamazsanız, Hizmetimiz veya bu Koşullar hakkında
        kritik bilgiler alamayabilirsiniz.
      </Text>

      <Heading my={4} fontSize="15px">
        <Text as="span" fontWeight="bold">
          POSTEFFECT'a Yapılacak Bildirimler
        </Text>
      </Heading>
      <Text as="p">
        Sözleşme kapsamında veya Sözleşmeyle ilgili POSTEFFECT'a yapacağınız
        herhangi bir bildirim için, POSTEFFECT'ı hukuk@Posteffect.ai e-mail
        adresine bildirim yapmalısınız.
      </Text>

      <Text as="p">
        Bu Koşullarla ilgili herhangi bir sorunuz varsa, lütfen bize şu adresten
        ulaşın: hukuk@Posteffect.ai
      </Text>
    </Box>
  );
};
