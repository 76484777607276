import { FC } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { CustomTooltip } from "./custom-tooltip";

interface DotsGraphProps {
  data: {
    name: string;
    data: number;
    lastData?: number;
  }[];
}

export const DotsGraph: FC<DotsGraphProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart height={60} data={data}>
        <CartesianGrid strokeDasharray="2 2" horizontal={false} />
        <XAxis
          axisLine={{ stroke: "#B7BABF", strokeWidth: 1 }}
          dataKey="name"
          scale="point"
          padding={{ left: 60 }}
          tick={{ fontSize: 12, fontWeight: "400", fill: "#B7BABF" }}
          tickLine={{ stroke: "gray.smoke" }}
        />
        <YAxis
          axisLine={{ stroke: "#B7BABF", strokeWidth: 1 }}
          dataKey="data"
          tick={{ fontSize: 12, fontWeight: "400", fill: "#B7BABF" }}
          tickLine={{ stroke: "gray.smoke" }}
        />
        <Tooltip content={<CustomTooltip />} />

        <Line
          type="monotone"
          dataKey="data"
          stroke="none"
          dot={{ fill: "#57AAEC", r: 5 }}
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
