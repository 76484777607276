import React from "react";

export const VideoIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3129 0.124512H2.18818C0.984683 0.124512 0 1.10919 0 2.3127V9.68688C0 10.8904 0.984683 11.8751 2.18818 11.8751H11.3129C12.5164 11.8751 13.5011 10.8904 13.5011 9.68688V2.3127C13.5011 1.08731 12.5164 0.124512 11.3129 0.124512Z"
        fill="#98CBF3"
      />
      <path
        d="M18.2865 1.3287C18.1552 1.35058 18.024 1.41623 17.9145 1.48188L14.501 3.45124V8.52783L17.9364 10.4972C18.571 10.8692 19.3587 10.6504 19.7307 10.0158C19.8401 9.81886 19.9058 9.60004 19.9058 9.35934V2.59785C19.9058 1.78822 19.1399 1.13177 18.2865 1.3287Z"
        fill="#98CBF3"
      />
    </svg>
  );
};
