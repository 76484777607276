import { DELETE, GET, PATCH, POST } from "@/api";
import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Flex,
  Tooltip,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  useDisclosure,
  useToast,
  Input,
  Icon,
  FormControl,
} from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import InviteMemberModal from "./components/invite-member-modal";
import { CreateOrganizationModal } from "@/components/create-organization-modal";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Pagination } from "@/components/pagination";
import { useTriggerStore } from "@/context/trigger-store/trigger-store";
import { useOrganization } from "@/hooks/use-organization";
import { ConnectPlusIcon } from "@/assets/icons/connect-plus-icon";
import { DeleteMemberIcon } from "@/assets/icons/delete-member-icon";
import { StatusAcceptIcon } from "@/assets/icons/status-accept-icon";
import { StatusPendingIcon } from "@/assets/icons/status-pending-icon";
import { StatusRejectIcon } from "@/assets/icons/status-reject-icon";
import { SearchBoxIcon } from "@/assets/icons/search-box-icon";
import { EditSquareIconBg } from "@/assets/icons/edit-square-icon-bg";
import { CancelSquareIcon } from "@/assets/icons/cancel-square-icon";
import { CheckSquareIcon } from "@/assets/icons/check-square-icon";
import { EditSquareIconDisable } from "@/assets/icons/edit-square-disable";

const Members: FC = () => {
  const { organization, refetchUser, user } = useAuthStore((state) => ({
    organization: state.activeOrganization,
    setActiveOrg: state.setActiveOrganization,
    refetchUser: state.refetchUser,
    user: state.user,
  }));
  const triggerOrganization = useTriggerStore((s) => s.triggerOrganization);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { t } = useTranslation();
  const toast = useToast();
  const [searchInput, setSearchInput] = useState<string>("");
  const [editOrganizationName, setEditOrganizationName] =
    useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>(
    organization?.name ? organization?.name : t("no_organization_found")
  );

  useEffect(() => {
    const s = useAuthStore.subscribe((state, prevState) => {
      if (prevState.activeOrganization !== state.activeOrganization) {
        setInputValue(state.activeOrganization?.name ?? "");
      }
    });

    return () => {
      s();
    };
  }, []);

  const { data: OrganizationList } = useOrganization();
  const { data: membersData, refetch: membersDataRefetch } = useQuery(
    ["members", organization?.id],
    async () => {
      if (!organization) return;
      const { data } = await GET("/org/members/{org_pk}/", {
        params: {
          path: {
            org_pk: organization?.id.toString(),
          },
        },
      });
      return data;
    }
  );

  const { data: rolesData } = useQuery(
    ["roles", organization?.id],
    async () => {
      if (!organization) return;
      const { data } = await GET("/org/roles/{org_pk}/", {
        params: {
          path: {
            org_pk: organization?.id.toString(),
          },
        },
      });

      return data;
    }
  );

  const updateRoles = useCallback(
    async (
      organizationId: number | undefined,
      memberId: number,
      roleId: number
    ) => {
      if (!organizationId) return;
      const { data, response } = await PATCH("/roles/{org_pk}/change/", {
        params: {
          path: {
            org_pk: organizationId.toString(),
          },
        },
        body: {
          user: memberId,
          role: roleId,
        },
      });

      if (response.status === 403) {
        toast({
          status: "error",
          description: t("no_authorization_letter"),
          duration: 3000,
        });
        membersDataRefetch();
        return;
      }
      toast({
        status: "success",
        description: t("role_updated_successfully"),
        duration: 3000,
      });
      membersDataRefetch();
      return data;
    },
    [membersDataRefetch, t, toast]
  );

  const deleteMember = useCallback(
    async (memberId: number, organizationId: number) => {
      const { error, response } = await POST("/roles/{org_pk}/remove-user/", {
        params: {
          path: {
            org_pk: organizationId.toString(),
          },
        },
        body: {
          user: memberId,
        },
      });
      if (error) {
        console.error("deleteMember error", error);

        toast({
          status: "error",
          description: error.detail || t("transaction_failed"),
          duration: 3000,
        });

        return;
      }
      setTimeout(() => {
        membersDataRefetch();
      }, 1000);

      if (response.status === 200) {
        toast({
          status: "success",
          description: t("user_deleted_successfully"),
          duration: 3000,
        });

        return;
      }
      if (response.status === 400) {
        toast({
          status: "error",
          description: t("failed_to_delete_user"),
          duration: 3000,
        });

        return;
      }
      if (response.status === 403) {
        toast({
          status: "error",
          description: t("you_are_not_authorized_to_delete_users"),
          duration: 3000,
        });

        return;
      }

      toast({
        status: "error",
        description: t("transaction_failed"),
        duration: 3000,
      });
    },
    [membersDataRefetch, t, toast]
  );
  const membersPerPage = 5;

  const filteredMembers = membersData?.filter((member) => {
    const fullName = `${member.profile.first_name} ${member.profile.last_name}`;
    return (
      fullName.toLowerCase().includes(searchInput.toLowerCase()) ||
      member.profile.email.toLowerCase().includes(searchInput.toLowerCase())
    );
  });

  const totalPages = filteredMembers
    ? Math.ceil(filteredMembers.length / membersPerPage)
    : 0;
  const startIndex = (currentPage - 1) * membersPerPage;
  const endIndex = startIndex + membersPerPage;

  const currentPageMembers = filteredMembers?.slice(startIndex, endIndex) ?? [];

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const updateOrganizationName = useCallback(
    async (name: string) => {
      if (!organization?.id) {
        return;
      }

      const { data, error } = await PATCH("/org/{id}/", {
        params: {
          path: {
            id: organization.id,
          },
        },
        body: {
          name,
        },
      });
      if (error) {
        toast({
          status: "error",
          description: error.detail || t("transaction_failed"),
          duration: 3000,
        });
        return;
      }

      if (data) {
        toast({
          status: "success",
          description: t("transaction_success"),
          duration: 3000,
        });

        triggerOrganization();
        refetchUser(true);
      }
    },
    [organization, refetchUser, t, toast, triggerOrganization]
  );

  const { data: InviteHistory, refetch: InviteHistoryRefetch } = useQuery(
    ["inviteHistory", organization?.id],
    async () => {
      if (!organization?.id) {
        return;
      }
      const { data } = await GET("/invitation/{org_pk}/list/", {
        params: {
          path: {
            org_pk: organization.id.toString(),
          },
        },
      });
      return data;
    }
  );

  const deleteHistory = useCallback(
    async (id: number) => {
      const { error, response } = await DELETE("/invitation/{id}/", {
        params: {
          path: {
            id: id,
          },
        },
      });

      if (error) {
        console.error("Delete invite error", error);
      }

      setTimeout(() => {
        InviteHistoryRefetch();
      });

      if (response.status === 200) {
        toast({
          status: "success",
          description: t("user_deleted_successfully"),
          duration: 3000,
        });
      }
      if (response.status === 400) {
        toast({
          status: "error",
          description: t("failed_to_delete_user"),
          duration: 3000,
        });
      }
      if (response.status === 403) {
        toast({
          status: "error",
          description: t("you_are_not_authorized_to_delete_users"),
          duration: 3000,
        });
      }
      if (!error) {
        toast({
          status: "success",
          description: t("user_deleted_successfully"),
          duration: 3000,
        });
      }
    },
    [InviteHistoryRefetch, toast, t]
  );

  // const removeOrganization = useCallback(async () => {
  //   if (!organization) {
  //     return;
  //   }

  //   const { response, error } = await DELETE("/org/{id}/", {
  //     params: {
  //       path: {
  //         id: organization.id,
  //       },
  //     },
  //   });

  //   if (response.ok) {
  //     toast({
  //       status: "success",
  //       title: t("alert.success.channel-removed-succesfully"),
  //       duration: 3000,
  //     });
  //     triggerOrganization();
  //     if (OrganizationList) {
  //       setActiveOrg(OrganizationList?.[0]);
  //     }

  //     return;
  //   }

  //   toast({
  //     status: "error",
  //     description: t("errors.an-unexpected-error-occurred-removing-channel"),
  //     duration: 3000,
  //   });
  // }, [organization, toast, t]);

  const filteredInviteHistory = InviteHistory?.filter(
    (invitation) =>
      invitation.name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
      invitation.email?.toLowerCase().includes(searchInput?.toLowerCase())
  );

  return (
    <Flex
      flexDir="column"
      h="full"
      mt="42px"
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.08)"
      borderRadius="6px"
      bg="white.normal"
    >
      <Flex
        p="40px"
        alignItems="center"
        w="full"
        shadow="sm"
        borderTopRadius="6px"
        h="150px"
      >
        <Flex w="100%" h="full" gap={3}>
          {/* <Text color="gray" fontSize="18px" fontWeight="500">
            {`${t("organization_name")}:`}
          </Text> */}
          {/* {organization && (
            <EditableInput
              tooltipCondition={organization.owner !== user?.id}
              tooltipMessage={t("no_authorization_letter")}
              color="black.active"
              fontSize="18px"
              fontWeight="700"
              onSubmit={updateOrganizationName}
              isDisabled={organization.owner !== user?.id}
              key={organization.name + organization.id}
              defaultValue={
                organization.name
                  ? organization.name
                  : t("no_organization_found")
              }
            />
          )} */}

          <FormControl
            // isInvalid={!!errors.name}
            borderRadius="4px"
            transition="borderColor 0.6s ease"
            w="50%"
          >
            <Text
              pos="absolute"
              top="4px"
              fontSize="16px"
              zIndex="9"
              ml="2.5"
              px="4"
              fontWeight="400"
              lineHeight="1"
              pr="8"
              bg="white"
            >
              {t("organization_name")}
            </Text>
            {organization && (
              <Input
                border="1px solid"
                borderColor={
                  editOrganizationName ? "blue.shiny" : "gray.passive"
                }
                borderRadius="4px"
                w="full"
                mt="3"
                p="3"
                color="gray.passive"
                disabled={!editOrganizationName}
                variant="unstyled"
                _disabled={{
                  color: "gray.passive",
                }}
                type="text"
                _placeholder={{
                  color: "gray.passive",
                }}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                value={inputValue}
              />
            )}
          </FormControl>

          <Flex alignItems="center">
            {!editOrganizationName ? (
              <Flex>
                <Button
                  cursor="pointer"
                  variant="unstyled"
                  type="submit"
                  onClick={() => {
                    organization?.owner === user?.id
                      ? setEditOrganizationName(!editOrganizationName)
                      : toast({
                          status: "error",
                          description: t("no_authorization_letter"),
                          duration: 3000,
                        });
                  }}
                >
                  {organization?.owner === user?.id ? (
                    <Flex alignItems="center" justifyContent="center" gap={4}>
                      <EditSquareIconBg width="40px" height="40px" />
                    </Flex>
                  ) : (
                    <Flex alignItems="center" justifyContent="center">
                      <EditSquareIconDisable width="40px" height="40px" />
                    </Flex>
                  )}
                </Button>
                <Button
                  ml="14px"
                  cursor="pointer"
                  variant="unstyled"
                  type="submit"
                  onClick={() => {
                    organization?.owner === user?.id
                      ? setEditOrganizationName(!editOrganizationName)
                      : toast({
                          status: "error",
                          description: t("no_authorization_letter"),
                          duration: 3000,
                        });
                  }}
                >
                  {organization?.owner === user?.id ? (
                    <Flex alignItems="center" justifyContent="center" gap={4}>
                      {/* <CancelSquareIcon
                        width="40px"
                        height="40px"
                        fill="pink"
                        onClick={removeOrganization}
                      /> */}
                    </Flex>
                  ) : (
                    <Flex alignItems="center" justifyContent="center">
                      {/* <EditSquareIconDisable width="40px" height="40px" /> */}
                    </Flex>
                  )}
                </Button>
              </Flex>
            ) : (
              <Flex>
                <Button
                  border-radius="3px"
                  color="white"
                  onClick={() =>
                    updateOrganizationName(inputValue).then(() =>
                      setEditOrganizationName(!editOrganizationName)
                    )
                  }
                  _hover={{
                    opacity: "0.6",
                  }}
                  type="submit"
                  width="40px"
                  height="40px"
                >
                  <Flex alignItems="center" justifyContent="center">
                    <CheckSquareIcon width="40px" height="40px" />
                  </Flex>
                </Button>
                <Button
                  border-radius="3px"
                  color="white"
                  onClick={() => {
                    setEditOrganizationName(!editOrganizationName);
                    setInputValue(organization?.name ?? "");
                  }}
                  _hover={{
                    opacity: "0.6",
                  }}
                  width="40px"
                  height="40px"
                  ml="14px"
                >
                  <Flex alignItems="center" justifyContent="center">
                    <CancelSquareIcon width="40px" height="40px" />
                  </Flex>
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex alignItems="center" justifyContent="flex-end" w="full" gap="5">
          <Flex>
            <CreateOrganizationModal />
          </Flex>
        </Flex>
      </Flex>

      <Flex p="40px" alignItems="center" w="full" shadow="sm" h="110px">
        <Flex w="100%" gap={2} alignItems="center" justifyContent="flex-start">
          <Box whiteSpace="nowrap">
            <Text
              fontWeight="600"
              lineHeight="20px"
              color="#353B48"
              fontSize="24px"
            >
              {t("members")}
            </Text>

            <Text color="#B7BABF" fontSize="13px" lineHeight="20px" mt="1.5">
              {t("page.settings.account-settings.manage-member")}
            </Text>
          </Box>
        </Flex>

        <Flex alignItems="center" justifyContent="flex-end" w="full" gap="5">
          <Flex
            border="1px solid #57AAEC"
            borderRadius="6px"
            w="194px"
            h="38px"
            pos="relative"
          >
            <IconButton
              pos="absolute"
              left="10px"
              top="-2px"
              aria-label="search-box-icon"
              icon={<SearchBoxIcon />}
              variant="unstyled"
            />
            <Input
              variant="unstyled"
              textAlign="left"
              color="blue.shiny"
              ml="51px"
              placeholder={t("page.settings.account-settings.search-name")}
              _placeholder={{ color: "blue.shiny", fontWeight: "600" }}
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              h="38px"
            />
          </Flex>

          <Flex>
            <Button
              onClick={() => onOpen()}
              fontSize="15px"
              bg="blue.light"
              textColor="blue.shiny"
              border="1px solid"
              borderColor="blue.shiny"
              borderRadius="6px"
              cursor="pointer"
              _hover={{
                opacity: 0.8,
              }}
              type="submit"
              h="38px"
              w="full"
            >
              <IconButton
                aria-label="connect-plus-icon"
                icon={<ConnectPlusIcon fill="#57AAEB" />}
                variant="unstyled"
                pl="10px"
              />
              {t("add_new_team_member")}
            </Button>

            <InviteMemberModal
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              OrganizationList={OrganizationList ?? []}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        px="4"
        pt="6"
        pb="8"
        w="full"
        borderBottomRadius="6px"
        flexDir="column"
        gap="6"
        bg="white"
        overflowY="auto"
      >
        <Box py="1" h="420px">
          <TableContainer justifyContent="space-between" alignItems="center">
            <Table variant="simple" justifyContent="space-between">
              <Tbody mt="2">
                {Array.isArray(currentPageMembers) &&
                  currentPageMembers?.map((member) => (
                    <Tr
                      key={member.profile.id}
                      alignItems="center"
                      borderBottom="1px solid rgba(144, 123, 123, 0.08)"
                    >
                      <Td borderWidth="0">
                        <Tooltip
                          visibility={
                            `${member.profile.first_name} ${member.profile.last_name}`
                              .length > 20
                              ? "visible"
                              : "hidden"
                          }
                          shadow="0 0 10px rgba(0, 0, 0, 0.3)"
                          minW="140px"
                          fontWeight="500"
                          fontSize="16px"
                          bgColor="white"
                          p="6px"
                          color="black.active"
                          borderRadius="6px"
                          label={`${member.profile.first_name} ${member.profile.last_name}`}
                        >
                          <Flex gap={2} alignItems="center">
                            <Avatar
                              size="sm"
                              name={member.profile.first_name}
                              src={member.profile.picture ?? undefined}
                            />
                            <Text
                              cursor="pointer"
                              color="black.active"
                              fontSize="md"
                              textTransform="capitalize"
                              fontWeight="semibold"
                              lineHeight="16px"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                            >
                              {`${member.profile.first_name} ${member.profile.last_name}`
                                .length > 20
                                ? `${`${member.profile.first_name} ${member.profile.last_name}`.slice(0, 20)}...`
                                : `${member.profile.first_name} ${member.profile.last_name}`}
                            </Text>
                          </Flex>
                        </Tooltip>
                      </Td>
                      {/* <Td borderWidth="0">
                        <StatusBadge
                          status={member.profile.status}
                          text={member.profile.statusText}
                        />
                      </Td> */}

                      <Td borderWidth="0" alignItems="center" cursor="pointer">
                        {member.profile.email}
                      </Td>
                      <Th borderWidth="0" w="100%"></Th>
                      <Th borderWidth="0" w="100%"></Th>

                      <Td borderWidth="0" alignItems="center" cursor="pointer">
                        <Popover>
                          {({ isOpen, onClose }) => (
                            <>
                              {member.role === "Owner" ? (
                                <Box
                                  mr="34px"
                                  px="2"
                                  width="170px"
                                  h="36px"
                                  bg="white"
                                  border="1px solid #3B5998 "
                                  borderRadius="6px"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Text
                                    textAlign="center"
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    fontSize="14px"
                                    fontWeight="600"
                                    color="blue.main"
                                  >
                                    {t(
                                      `role_names.${member.role.toLowerCase()}`
                                    )}
                                  </Text>
                                </Box>
                              ) : (
                                <PopoverTrigger>
                                  <Button
                                    mr="34px"
                                    px="2"
                                    width="170px"
                                    h="36px"
                                    bg="white"
                                    border="1px solid #3B5998 "
                                    borderRadius="6px"
                                  >
                                    <Flex
                                      justifyContent="space-between"
                                      alignItems="center"
                                      w="full"
                                    >
                                      <Text
                                        textAlign="center"
                                        as="span"
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                        fontSize="14px"
                                        color="blue.main"
                                      >
                                        {t(
                                          `role_names.${member.role.toLowerCase()}`
                                        ) || t("select-role")}{" "}
                                      </Text>
                                      {Array.isArray(rolesData) &&
                                        rolesData.length > 1 &&
                                        (isOpen ? (
                                          <Box>
                                            <ChevronUpIcon color="blue.main" />
                                          </Box>
                                        ) : (
                                          <Box>
                                            <ChevronDownIcon color="blue.main" />
                                          </Box>
                                        ))}
                                    </Flex>
                                  </Button>
                                </PopoverTrigger>
                              )}
                              <PopoverContent
                                bg="white"
                                borderRadius="6px"
                                w="170px"
                                border={
                                  Array.isArray(rolesData) &&
                                  rolesData.length > 0
                                    ? "1px solid #3B5998"
                                    : "none"
                                }
                              >
                                {Array.isArray(rolesData) &&
                                  rolesData?.map((role, index) => (
                                    <React.Fragment key={role.id}>
                                      <PopoverBody
                                        _hover={{
                                          bg: "gray.soft",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        <Button
                                          variant="unstyled"
                                          textOverflow="ellipsis"
                                          overflow="hidden"
                                          fontSize="14px"
                                          textAlign={"left"}
                                          w="full"
                                          size={"none"}
                                          color="blue.main"
                                          fontWeight="600"
                                          key={index}
                                          onClick={() => {
                                            updateRoles(
                                              organization?.id,
                                              member.profile.id,
                                              role.id
                                            );
                                            onClose();
                                          }}
                                        >
                                          {t(
                                            `role_names.${role.title.toLowerCase()}`
                                          )}
                                        </Button>
                                      </PopoverBody>
                                      {index !== rolesData?.length - 1 && (
                                        <Divider />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </PopoverContent>
                            </>
                          )}
                        </Popover>
                      </Td>
                      <Td borderWidth="0">
                        <Flex
                          justifyContent="flex-end"
                          gap={4}
                          alignItems="center"
                        >
                          {/* <IconButton
                            cursor="pointer"
                            aria-label="remove-channel"
                            variant="unstyled"
                            onClick={onOpenAlert}
                          >
                            <RefreshBoxIcon width="33px" height="33px" />
                          </IconButton> */}
                          <DeleteAlert
                            channel={member.profile.email}
                            onDelete={() => {
                              if (organization?.id) {
                                deleteMember(
                                  member.profile.id,
                                  organization.id
                                );
                                return;
                              }
                              toast({
                                status: "error",
                                description: t("user-could-not-be-deleted"),
                                duration: 3000,
                              });
                            }}
                            trigger={(onOpen) => (
                              <Button
                                size="none"
                                cursor="pointer"
                                variant="unstyled"
                                onClick={onOpen}
                              >
                                <DeleteMemberIcon />
                              </Button>
                            )}
                          />

                          {/* <AlertDialog
                            leastDestructiveRef={cancelRef}
                            onClose={onCloseAlert}
                            isOpen={isOpenAlert}
                            isCentered
                          >
                            <AlertDialogOverlay>
                              <AlertDialogContent bg="white.bg">
                                <AlertDialogHeader
                                  fontSize="lg"
                                  fontWeight="bold"
                                >
                                  You are deleting {member.profile.email}
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                  Are you sure? You can't undo this action
                                  afterwards.
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                  <Button onClick={onClose}>Cancel</Button>
                                  <Button
                                    colorScheme="red"
                                    onClick={() => {
                                      if (organization?.id) {
                                        deleteMember(
                                          member.profile.id,
                                          organization.id
                                        );
                                        return;
                                      }
                                      toast({
                                        status: "error",
                                        description: t(
                                          "user-could-not-be-deleted"
                                        ),
                                        duration: 3000,
                                      });
                                    }}
                                    ml={3}
                                  >
                                    Delete
                                  </Button>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialogOverlay>
                          </AlertDialog> */}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}

                {Array.isArray(filteredInviteHistory) &&
                  filteredInviteHistory?.map((invitation) =>
                    invitation.status === "pending" ? (
                      <Tr key={invitation.id} alignItems="center">
                        <Td>
                          <Flex alignItems="center">
                            <Avatar
                              size="sm"
                              name={invitation.name ?? undefined}
                              src={invitation.picture ?? undefined}
                            />
                            <Box ml="3">
                              <Text
                                fontWeight="semibold"
                                textTransform="capitalize"
                              >
                                {invitation.name}
                              </Text>
                            </Box>
                          </Flex>
                        </Td>

                        <Td>
                          <Text>{invitation.email}</Text>
                        </Td>
                        <Td w="100%"></Td>

                        <Td>
                          <Flex>
                            <StatusIconText status={invitation.status} />
                          </Flex>
                        </Td>

                        <Td>
                          <Flex>
                            <Box
                              mr="34px"
                              px="2"
                              width="170px"
                              h="36px"
                              bg="white"
                              border="1px solid #3B5998 "
                              borderRadius="6px"
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Text
                                textAlign="center"
                                textTransform="capitalize"
                                textOverflow="ellipsis"
                                overflow="hidden"
                                fontSize="14px"
                                fontWeight="600"
                                color="blue.main"
                              >
                                {t("member")}
                              </Text>
                            </Box>
                          </Flex>
                        </Td>

                        <Td>
                          <DeleteAlert
                            channel={invitation.email}
                            onDelete={() => {
                              if (organization?.id) {
                                deleteHistory(invitation.id);
                                return;
                              }
                              toast({
                                status: "error",
                                description: t("user-could-not-be-deleted"),
                                duration: 3000,
                              });
                            }}
                            trigger={(onOpen) => (
                              <Button
                                size="none"
                                cursor="pointer"
                                variant="unstyled"
                                onClick={onOpen}
                              >
                                <DeleteMemberIcon />
                              </Button>
                            )}
                          />
                        </Td>
                      </Tr>
                    ) : null
                  )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          position="relative"
          top="10"
        >
          {membersData?.length !== 0 && (
            <Box p={4}>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </Box>
          )}{" "}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const DeleteAlert: FC<{
  trigger: (onOpen: () => void) => React.ReactNode;
  onDelete?: () => void;
  channel: string;
}> = ({ trigger, onDelete, channel }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  return (
    <>
      {trigger(onOpen)}

      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="white.bg">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("alert.remove-modal.you-are-deleting-c")} {channel}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t("alert.remove-modal.are-you-sure")}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                onClick={onClose}
                bgColor="gray.light"
                width="80px"
                height="35px"
              >
                {t("alert.remove-modal.cancel")}
              </Button>
              <Button
                colorScheme="red"
                width="80px"
                height="35px"
                onClick={onDelete}
                ml={3}
              >
                {t("alert.remove-modal.delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

interface StatusProps {
  status: string;
}

const StatusIconText: React.FC<StatusProps> = ({ status }) => {
  let icon;
  let color;
  let text;
  const { t } = useTranslation();
  switch (status) {
    case "pending":
      icon = <StatusPendingIcon />;
      color = "yellow.500";
      text = t("pending");
      break;
    case "accepted":
      icon = <StatusAcceptIcon />;
      color = "green.500";
      text = t("accepted");
      break;
    case "rejected":
      icon = <StatusRejectIcon />;
      color = "red.500";
      text = t("rejected");
      break;
    default:
      icon = null;
      text = "";
      color = "";
  }

  return (
    <Flex w="100px" justifyContent="flex-end" alignItems="flex-start">
      <Box>
        {icon && (
          <Icon w="6" h="6">
            {icon}
          </Icon>
        )}
      </Box>
      <Box>
        <Text whiteSpace="nowrap" fontWeight="semibold" color={color}>
          {text}
        </Text>
      </Box>
    </Flex>
  );
};
export default Members;
