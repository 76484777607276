import {
  Flex,
  Text,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
} from "@chakra-ui/react";
import { APISchemas } from "@/api";
import { LinkIcon } from "@/assets/icons/link-icon";
import { AvatarWithBadge } from "./avatar-with-badge";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
interface AvatarInfoBoxProps {
  avatar: APISchemas["Channel"];
  children?: ReactNode;
  onPress?: () => void;
  border?: string;
  status?: string;
  href?: string;
  active: boolean;
}

export const AvatarInfoBox: React.FC<AvatarInfoBoxProps> = ({
  avatar,
  active,
  onPress,
  status,
  href,
}) => {
  const { t } = useTranslation();
  return (
    <Popover placement="bottom-start" trigger="hover">
      <PopoverTrigger>
        <Box>
          <AvatarWithBadge
            opacity={!active ? "0.5" : "1"}
            status={status}
            picture={avatar.picture ?? undefined}
            channel={avatar.channel_type}
            channel_id={avatar.id.toString()}
            w="34px"
            h="34px"
            key={avatar.id}
            tooltip={false}
            onClick={onPress}
            email={
              avatar.username
                ? avatar.username.startsWith("@")
                  ? `${avatar.username}`
                  : `@${avatar.username}`
                : avatar.name
            }
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent w="full" overflow="hidden">
        <PopoverBody bg="white.bg" p="0px">
          <Flex p="1" w="full" h="full" alignItems="center">
            <AvatarWithBadge
              status={status}
              badgeSize="15px"
              picture={avatar.picture ?? ""}
              channel={avatar.channel_type}
              channel_id={avatar.id.toString()}
              tooltip={false}
              w="28px"
              h="28px"
              key={avatar.id}
              email={
                avatar.username
                  ? avatar.username.startsWith("@")
                    ? avatar.username
                    : `@${avatar.username}`
                  : avatar.name
              }
            />
            <Text
              key={avatar.id}
              fontSize="12px"
              ml="2"
              mr="3"
              w="full"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {avatar.username
                ? avatar.username.startsWith("@")
                  ? avatar.username
                  : `@${avatar.username}`
                : avatar.name}
            </Text>
            <Flex w="full" justifyContent="flex-end" mr="1" ml="5">
              {status === "posted" && !!href && (
                <Link href={href} isExternal>
                  <LinkIcon />
                </Link>
              )}
              <Box
                textTransform="capitalize"
                w="70px"
                h="20px"
                color="white"
                fontSize="14px"
                fontWeight="300"
                borderRadius="2px"
                textAlign="center"
                ml="3"
                bgColor={
                  status === "posted"
                    ? " #50CD89"
                    : status === "draft"
                      ? "#FBC531"
                      : status === "failed"
                        ? "#E52625"
                        : status === "cancelled"
                          ? "#E52625"
                          : status === "planned"
                            ? "#50CD89"
                            : status === "deleted"
                              ? "#E52625"
                              : status === "prepared"
                                ? "#50CD89"
                                : "white"
                }
              >
                {t(`${status}`)}
              </Box>
            </Flex>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
