import { POST } from "@/api";
import { AddCoverLibraryIcon } from "@/assets/icons/add-cover-library-icon";
import { AddPersonIcon } from "@/assets/icons/add-person-icon";
import { MusicIcon } from "@/assets/icons/music-icon";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Divider,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useToast,
  Center,
  AspectRatio,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import MediaInfoFactory from "mediainfo.js";
import React, { FC, useCallback, useRef, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { TagUserItem } from "../tag-user/tag-user-item";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { TagInputGroup } from "../tag-user/tag-input-group";
import { AddMusicGroup } from "../add-music";
import { DropzoneFile } from "@/components/Dropzone";
import { AlertIcon } from "@/assets/icons/alert-icon";
import { MusicSignIcon } from "@/assets/icons/music-sign-icon";
import { BlackCrossIcon } from "@/assets/icons/black-cross-icon";

export interface TaggedAccounts {
  username: string;
  x?: number;
  y?: number;
}

export interface EditMediaModalProps {
  type: "video" | "image";
  isOpen: boolean;
  close: () => void;
  data: DropzoneFile["data"];
  updateFile: ({
    thumbnail_url,
    taggedAccounts,
    audioName,
  }: {
    thumbnail_url?: string | null;
    taggedAccounts?: TaggedAccounts[];
    audioName?: string | null;
  }) => void;
}

export const EditMediaModal: React.FC<EditMediaModalProps> = ({
  close,
  type,
  isOpen,
  data,
  updateFile,
}) => {
  const toast = useToast();
  const coverUrl = useRef(data.video?.coverUrl);
  const [loading, setLoading] = useState<boolean>(false);
  const [choosedAction, setChoosedAction] = useState<string | undefined>();
  const [addedAccounts, setAddedAccounts] = useState<TaggedAccounts[]>(
    data.metadata?.userTags ?? []
  );
  const [musicGroup, setMusicGroup] = useState<string>(
    data.metadata?.audioName || ""
  );
  const [tagInput, setTagInput] = useState<string>("");

  const imageRef = useRef<HTMLImageElement>(null);
  const [newThumbnailData, setNewThumbnailData] = useState<{
    localUrl?: string;
    uploadUrl?: string;
    formData?: FormData;
    downloadUrl?: string;
  }>();
  const defaultThumbnailUrl = data.video?.thumbnail_url;
  const [showOverlay, setShowOverlay] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [imageAlert, setImageAlert] = useState<string | undefined>("");

  const onImageDrop = useCallback(
    async (files: File[]) => {
      for (const file of files) {
        setLoading(true);
        const mediaInfo = await MediaInfoFactory();

        const info = await mediaInfo.analyzeData(
          () => file.size,
          async (chunkSize, offset) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target?.error) {
                  reject(event.target.error);
                  return;
                }

                if (event.target?.result) {
                  if (typeof event.target.result === "string") {
                    const encoder = new TextEncoder();
                    resolve(
                      new Uint8Array(encoder.encode(event.target.result).buffer)
                    );
                    return;
                  }
                  resolve(new Uint8Array(event.target.result));
                }

                resolve(new Uint8Array());
              };
              reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
            })
        );

        const file_type = file.type || `video/${file.name.split(".").pop()}`;

        const type: "image" | "video" | null = file_type.startsWith("image")
          ? "image"
          : file_type.startsWith("video")
            ? "video"
            : null;

        if (!type) {
          toast({
            status: "error",
            title: t("errors.could-not-recognized-media"),
          });
          return;
        }

        const img = info.media?.track.find((t) => t["@type"] === "Image");

        const { data } = await POST("/media/upload/image/", {
          body: {
            height: img?.Height?.toString() ?? "",
            width: img?.Width?.toString() ?? "",
            size: file.size.toString(),
            raw: JSON.parse(JSON.stringify(info?.media?.track)),
          },
        });
        if (!data) {
          toast({
            status: "error",
            title: t("errors.unexpected_error"),
          });
          return;
        }

        const formData = new FormData();

        setNewThumbnailData({
          localUrl: URL.createObjectURL(file),
          uploadUrl: data.uploadUrl,
          formData: formData,
          downloadUrl: data.downloadUrl,
        });
        setLoading(false);
        return;
      }
    },
    [setNewThumbnailData, toast, t]
  );

  const handleThumbnailUpdate = useCallback(
    (
      fileId: string,
      uploadUrl: string,
      formData: FormData,
      downloadUrl: string
    ) => {
      const toastId = toast({
        status: "info",
        title: t("edit-video-modal.video-cover-is-uploading"),
        isClosable: true,
        duration: null,
      });

      axios
        .request({
          url: uploadUrl,
          method: "POST",
          data: formData,
        })
        .then(async () => {
          if (data.video?.id === fileId) {
            updateFile({ thumbnail_url: downloadUrl });
          }
        })
        .then(() => {
          toast.update(toastId, {
            duration: 3000,
            status: "success",
            title: t("alert.success.video-cover-uploaded"),
          });
          close();
        })
        .catch((reason: AxiosError) => {
          toast.update(toastId, {
            duration: 3000,
            status: "error",
            title:
              reason.cause?.message ??
              reason.message ??
              JSON.stringify(
                reason.response?.data ??
                  t("errors.unexpected-error-during-upload")
              ),
          });
        });
    },
    [toast, data, updateFile, close, t]
  );

  useEffect(() => {
    const image = imageRef.current?.getBoundingClientRect();
    if (image) {
      const invalidTag = addedAccounts.find((account) => {
        if (
          account.x &&
          account.y &&
          (image.width < account.x ||
            account.x < 0 ||
            image.height < account.y ||
            account.y < 0)
        )
          return account;
      });
      if (invalidTag) {
        setImageAlert(
          t("edit-video-modal.invalid-tag-area-alert", {
            username: invalidTag.username,
          })
        );
        return;
      }
      setImageAlert("");
    }
  }, [addedAccounts, t]);

  const handleCoverDelete = useCallback(async () => {
    const toastId = toast({
      title: t("alert.success.video-cover-deleting"),
      status: "info",
      isClosable: true,
      duration: null,
    });

    await updateFile({ thumbnail_url: null });
    setNewThumbnailData({
      localUrl: "",
      uploadUrl: "",
      formData: new FormData(),
      downloadUrl: "",
    });

    toast.update(toastId, {
      duration: 3000,
      status: "success",
      title: t("alert.success.video-cover-deleted"),
    });

    close();
  }, [toast, updateFile, close, t]);

  const { getRootProps: fileRoot, getInputProps: fileInput } = useDropzone({
    onDrop: onImageDrop,
    accept: {
      "image/jpg": [".png", ".jpg", ".jpeg"],
    },
  });

  const handleRemoveClick = (account: string) => {
    setAddedAccounts(addedAccounts.filter((t) => account !== t.username));
  };

  const [draggingOffset, setDraggingOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    setDraggingIndex(index);
    const account = addedAccounts[index];
    setDraggingOffset({
      x: e.clientX - (account.x || 0),
      y: e.clientY - (account.y || 0),
    });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (draggingIndex !== null) {
      const newAccounts = [...addedAccounts];
      newAccounts[draggingIndex] = {
        ...newAccounts[draggingIndex],
        x: e.clientX - draggingOffset.x,
        y: e.clientY - draggingOffset.y,
      };
      setAddedAccounts(newAccounts);
    }
  };

  const handleMouseUp = () => {
    setDraggingIndex(null);
  };

  const handleMouseLeave = () => {
    if (draggingIndex !== null) {
      setDraggingIndex(null);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      isCentered
      closeOnOverlayClick={true}
      blockScrollOnMount={false}
      scrollBehavior="outside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent boxShadow="md" w="full" bgColor="white">
        <ModalHeader
          pos="relative"
          fontWeight="500"
          fontSize="18px"
          display="flex"
          bgColor="white"
        >
          {type === "video" ? t("edit-video") : t("edit-image")}
        </ModalHeader>
        <ModalBody borderRadius="3px" bgColor="white" h="full">
          <Text
            fontSize="14px"
            fontWeight="500"
            color="red.main"
            position="absolute"
            top="43px"
          >
            {imageAlert}
          </Text>
          <Box
            pos="relative"
            flexShrink="0"
            ref={ref}
            onMouseOver={() => setShowOverlay(true)}
            onMouseLeave={() => setShowOverlay(false)}
          >
            {!showOverlay ||
              (newThumbnailData?.localUrl && (
                <Flex
                  position="absolute"
                  top="0"
                  right="0"
                  bottom="0"
                  left="0"
                  bg="rgba(0, 0, 0, 0.5)"
                  justifyContent="center"
                  alignItems="center"
                >
                  <RemoveButton
                    onClick={() => {
                      setNewThumbnailData({
                        localUrl: "",
                        uploadUrl: "",
                        formData: new FormData(),
                        downloadUrl: "",
                      });
                    }}
                  />
                </Flex>
              ))}
            {!showOverlay ||
              (coverUrl.current && (
                <Flex
                  position="absolute"
                  top="0"
                  right="0"
                  bottom="0"
                  left="0"
                  bg="rgba(0, 0, 0, 0.5)"
                  justifyContent="center"
                  alignItems="center"
                >
                  <RemoveButton
                    onClick={() => {
                      handleCoverDelete();
                      setNewThumbnailData({
                        localUrl: "",
                        uploadUrl: "",
                        formData: new FormData(),
                        downloadUrl: "",
                      });
                    }}
                  />
                </Flex>
              ))}
            <Box
              position="relative"
              w="full"
              userSelect="none"
              outline={
                choosedAction === "tag" && type === "image"
                  ? "2px solid #50CD89"
                  : undefined
              }
              borderRadius={
                choosedAction === "tag" && type === "image" ? "3px" : undefined
              }
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
            >
              <AspectRatio w="full" h="full">
                <Image
                  userSelect="none"
                  w="full"
                  ref={imageRef}
                  objectFit="contain"
                  bgColor="white.alabaster"
                  src={
                    type === "video"
                      ? coverUrl.current
                        ? coverUrl.current
                        : newThumbnailData?.localUrl
                          ? newThumbnailData.localUrl
                          : defaultThumbnailUrl
                      : data.image?.downloadUrl
                  }
                />
              </AspectRatio>

              {type == "image" && choosedAction === "music" && musicGroup && (
                <Center>
                  <Flex
                    position="absolute"
                    bottom="3"
                    bgColor="gray.primary"
                    p="2"
                    w="95%"
                    alignItems="center"
                    borderRadius="3px"
                  >
                    <MusicSignIcon width="20px" height="20px" />
                    <Text
                      fontWeight="600"
                      color="black.active"
                      fontSize="14px"
                      w="full"
                      pl="3"
                      textTransform="capitalize"
                    >
                      {musicGroup.length > 60
                        ? musicGroup.slice(0, 60) + "..."
                        : musicGroup}
                    </Text>
                    <IconButton
                      aria-label="cross"
                      size="none"
                      variant="unstyled"
                      onClick={() => setMusicGroup("")}
                      icon={
                        <BlackCrossIcon
                          width="20px"
                          height="20px"
                          fill="#33373D"
                        />
                      }
                    />
                  </Flex>
                </Center>
              )}

              {type == "image" &&
                choosedAction === "tag" &&
                addedAccounts.map((account, index) => (
                  <Box
                    px="4"
                    borderRadius="3px"
                    position="absolute"
                    top={`${account.y || 0}px`}
                    left={`${account.x || 0}px`}
                    onMouseDown={(e) => handleMouseDown(e, index)}
                    key={index}
                    bgColor="black.active"
                    cursor="pointer"
                    userSelect="none"
                    _before={{
                      content: '""',
                      position: "absolute",
                      top: "-6px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      borderWidth: "0 7px 7px 7px",
                      borderStyle: "solid",
                      borderColor:
                        "transparent transparent #33373D transparent",
                    }}
                  >
                    <Text fontWeight="500" color="white" fontSize="14px">
                      {account.username}
                    </Text>
                  </Box>
                ))}

              {/* {choosedAction === "tag" && clickPosition && (
                <Box
                  position="absolute"
                  top={`${clickPosition.y}px`}
                  left={`${clickPosition.x}px`}
                >
                  <TagInputGroup
                    type="image"
                    tagInput={tagInput}
                    setTagInput={setTagInput}
                    setAddedAccounts={(tagInput) => {
                      setAddedAccounts([
                        ...addedAccounts,
                        {
                          username: tagInput,
                          x: clickPosition.x,
                          y: clickPosition.y,
                        },
                      ]);
                    }}
                  />
                </Box>
              )} */}
            </Box>
          </Box>
          {type === "video" && (
            <Button
              {...fileRoot()}
              mt="3"
              h="48px"
              borderRadius="3px"
              rightIcon={<AddCoverLibraryIcon />}
              bgColor="gray.border"
              variant="outline"
              w="full"
              fontWeight="500"
              fontSize="14px"
            >
              <input {...fileInput()} />
              {t("edit-video-modal.add-cover-photo")}
            </Button>
          )}

          <HStack justify="flex-start" mt="3" spacing={4}>
            <IconButton
              onClick={() => {
                choosedAction === "tag"
                  ? setChoosedAction("")
                  : setChoosedAction("tag");
              }}
              aria-label="Add Person"
              rounded="full"
              bgColor={
                choosedAction === "tag" ? "gray.antrasit" : "gray.border"
              }
              width={10}
              height={10}
              icon={
                <AddPersonIcon fill={choosedAction === "tag" ? "#fff" : "s"} />
              }
              variant="add-person"
            />
            <IconButton
              onClick={() => {
                choosedAction === "music"
                  ? setChoosedAction("")
                  : setChoosedAction("music");
              }}
              aria-label="music"
              rounded="full"
              bgColor={
                choosedAction === "music" ? "gray.antrasit" : "gray.border"
              }
              width={10}
              height={10}
              icon={
                <MusicIcon fill={choosedAction === "music" ? "#fff" : "s"} />
              }
              variant="add-person"
            />
          </HStack>

          <Box h="40" mt="3" borderRadius="3px">
            {choosedAction === "tag" && (
              <Box bgColor="gray.athens" borderRadius="3px" p="3">
                <TagInputGroup
                  tagInput={tagInput}
                  setTagInput={setTagInput}
                  setAddedAccounts={(tagInput: string) => {
                    setAddedAccounts([
                      ...addedAccounts,
                      { username: tagInput, x: 0, y: 0 },
                    ]);
                  }}
                />
                <Box borderRadius="3px" mt="3">
                  <OverlayScrollbarsComponent
                    options={{
                      scrollbars: {
                        autoHide: "scroll",
                        autoHideDelay: 300,
                      },
                    }}
                  >
                    {addedAccounts.length > 0 && (
                      <Box maxH="28" bgColor="white" borderRadius="3px">
                        {addedAccounts.map((account, index) => (
                          <React.Fragment>
                            <TagUserItem
                              border={
                                index === 0 ||
                                index !== addedAccounts.length - 1
                              }
                              text={account.username}
                              onRemove={() =>
                                handleRemoveClick(account.username)
                              }
                            />
                            {index !== addedAccounts.length - 1 && (
                              <Center>
                                <Divider
                                  bgColor="gray.athens"
                                  w="97%"
                                  h="1px"
                                />
                              </Center>
                            )}
                          </React.Fragment>
                        ))}
                      </Box>
                    )}
                  </OverlayScrollbarsComponent>
                </Box>
              </Box>
            )}

            {choosedAction === "music" && (
              <Box bgColor="gray.athens" borderRadius="3px" p="3">
                <AddMusicGroup onChange={(name) => setMusicGroup(name || "")} />
                <Flex
                  bg="blue.water"
                  alignItems="center"
                  px="5px"
                  mt="7px"
                  gap="10px"
                  py="10px"
                  rounded="6px"
                  border="1px solid"
                  borderColor="blue.shiny"
                >
                  <Box>
                    <AlertIcon width="17px" height="17px" />
                  </Box>
                  <Text fontWeight="500" fontSize="12px" color="blue.shiny">
                    {t("edit-video-modal.add.music-info")}
                  </Text>
                </Flex>
              </Box>
            )}
          </Box>

          {/* <HStack justify="flex-start" mt={6} spacing={4}>
            <IconButton
              aria-label="Music"
              rounded="full"
              bgColor="#E2E4E7"
              w={10}
              h={10}
              icon={<MusicIcon />}
              variant="ghost"
            />

            <IconButton
              aria-label="Scissors"
              rounded="full"
              bgColor="#E2E4E7"
              w={10}
              h={10}
              icon={<ScissorsIcon />}
              variant="ghost"
            />
            <IconButton
              aria-label="Resize"
              rounded="full"
              bgColor="#E2E4E7"
              icon={<ResizeIcon />}
              variant="ghost"
            />
            <IconButton
              aria-label="Acceleration"
              rounded="full"
              bgColor="#E2E4E7"
              icon={<AcclerationIcon />}
              variant="ghost"
            />
          </HStack> */}
        </ModalBody>
        <ModalFooter mt="10">
          <Flex width="100%" justifyContent="flex-end">
            <Button
              onClick={() => close()}
              mr={3}
              bgColor="gray.smoke"
              fontWeight="500"
              width="147px"
              height="35px"
              borderRadius="3px"
              fontSize="14px"
            >
              {t("cancel")}
            </Button>
            <Button
              isDisabled={!!imageAlert}
              _hover={{ opacity: 0.8 }}
              isLoading={loading}
              bgColor="green.label"
              onClick={() => {
                updateFile({
                  taggedAccounts: addedAccounts,
                  audioName: musicGroup,
                });

                if (type == "video") {
                  if (
                    newThumbnailData?.uploadUrl &&
                    newThumbnailData?.formData &&
                    newThumbnailData?.downloadUrl &&
                    data.video?.id
                  ) {
                    handleThumbnailUpdate(
                      data.video?.id,
                      newThumbnailData.uploadUrl,
                      newThumbnailData.formData,
                      newThumbnailData.downloadUrl
                    );
                  }
                }

                close();
              }}
              width="147px"
              height="35px"
              borderRadius="3px"
              fontWeight="500"
              color="white"
              fontSize="14px"
            >
              {t("save-and-continue")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
const RemoveButton: FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <Tooltip label="Remove" placement="bottom">
      <IconButton
        aria-label="remove-media"
        display="flex"
        justifyContent="center"
        alignItems="center"
        variant="unstyled"
        bg="#F4F6F8"
        size="none"
        w="30px"
        h="30px"
        rounded="full"
        onClick={onClick}
      >
        <Icon w="16px" h="19px">
          <svg
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.88186 5.24658C7.69454 5.24658 7.54272 5.3984 7.54272 5.58572V11.9954C7.54272 12.1826 7.69454 12.3346 7.88186 12.3346C8.06918 12.3346 8.221 12.1826 8.221 11.9954V5.58572C8.221 5.3984 8.06918 5.24658 7.88186 5.24658Z"
              fill="#353B48"
            />
            <path
              d="M3.87967 5.24658C3.69234 5.24658 3.54053 5.3984 3.54053 5.58572V11.9954C3.54053 12.1826 3.69234 12.3346 3.87967 12.3346C4.06699 12.3346 4.2188 12.1826 4.2188 11.9954V5.58572C4.2188 5.3984 4.06699 5.24658 3.87967 5.24658Z"
              fill="#353B48"
            />
            <path
              d="M0.963207 4.3112V12.6668C0.963207 13.1607 1.1443 13.6245 1.46065 13.9573C1.77555 14.291 2.21378 14.4804 2.67241 14.4812H9.089C9.54776 14.4804 9.98599 14.291 10.3008 13.9573C10.6171 13.6245 10.7982 13.1607 10.7982 12.6668V4.3112C11.4271 4.14428 11.8346 3.53675 11.7504 2.89146C11.6662 2.2463 11.1165 1.76369 10.4658 1.76356H8.72946V1.33964C8.73145 0.983147 8.59049 0.64083 8.33813 0.388993C8.08576 0.137289 7.74291 -0.00286991 7.38642 4.45599e-05H4.37499C4.01849 -0.00286991 3.67565 0.137289 3.42328 0.388993C3.17091 0.64083 3.02996 0.983147 3.03195 1.33964V1.76356H1.29559C0.644867 1.76369 0.0952259 2.2463 0.0109713 2.89146C-0.0731507 3.53675 0.334344 4.14428 0.963207 4.3112ZM9.089 13.803H2.67241C2.09256 13.803 1.64148 13.3048 1.64148 12.6668V4.34101H10.1199V12.6668C10.1199 13.3048 9.66885 13.803 9.089 13.803ZM3.71022 1.33964C3.70797 1.16305 3.77739 0.993082 3.90271 0.868423C4.0279 0.743763 4.19826 0.675273 4.37499 0.67832H7.38642C7.56315 0.675273 7.73351 0.743763 7.8587 0.868423C7.98402 0.99295 8.05344 1.16305 8.05119 1.33964V1.76356H3.71022V1.33964ZM1.29559 2.44184H10.4658C10.803 2.44184 11.0763 2.71513 11.0763 3.05228C11.0763 3.38944 10.803 3.66273 10.4658 3.66273H1.29559C0.958437 3.66273 0.68514 3.38944 0.68514 3.05228C0.68514 2.71513 0.958437 2.44184 1.29559 2.44184Z"
              fill="#353B48"
            />
            <path
              d="M5.88089 5.24658C5.69357 5.24658 5.54175 5.3984 5.54175 5.58572V11.9954C5.54175 12.1826 5.69357 12.3346 5.88089 12.3346C6.06821 12.3346 6.22002 12.1826 6.22002 11.9954V5.58572C6.22002 5.3984 6.06821 5.24658 5.88089 5.24658Z"
              fill="#353B48"
            />
          </svg>
        </Icon>
      </IconButton>
    </Tooltip>
  );
};
