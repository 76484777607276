import { Text, Flex, Box } from "@chakra-ui/react";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotableDatesBarChart } from "./notable-dates-bar-chart";
import { ChevronFilledIcon } from "@/assets/icons/chevron-filled-icon";
import Slider, { Settings } from "react-slick";

import { colors } from "@/theme/colors";

interface NotableDatesProps {
  clickCount: number;
  lastUpdate: string;
  variation: number;
  increased?: boolean;
  clickData: Array<{
    date: string;
    data: number;
  }>;
}

export const NotableDates: FC<NotableDatesProps> = ({
  clickCount,
  lastUpdate,
  variation,
  increased,
}) => {
  const { t } = useTranslation();
  const sliderRef = useRef<Slider>(null);
  const [index, setIndex] = useState<number>(0);

  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, next) => setIndex(next),
    customPaging: (i: number) => (
      <div
        key={i}
        style={{
          margin: "0 2px",
          display: "inline-block",
          backgroundColor:
            i === index ? colors.blue.shiny : colors.blue.baby.default,
          borderRadius: "3px",
          width: "26px",
          height: "4px",
          cursor: "pointer",
          position: "relative",
          bottom: "20px",
        }}
        onClick={() => {
          if (sliderRef.current) {
            sliderRef.current.slickGoTo(i);
          }
        }}
      />
    ),
  };

  return (
    <Box w="full" height="full" bgColor="white" p="16px" borderRadius="6px">
      <Box w="full" alignItems="center">
        <Text
          fontWeight="600"
          lineHeight="14px"
          fontSize="16px"
          color="black.active"
        >
          {t("page.analytics.notable-datas")}{" "}
        </Text>
        <Text
          fontWeight="400"
          lineHeight="14px"
          fontSize="12px"
          color="gray.smoke"
          mt="12px"
        >
          {t("page.analytics.social-media-channels-overview")}
        </Text>
      </Box>

      <Flex
        w="full"
        mt="6"
        h="36px"
        pos="relative"
        flexDir={{ base: "column", "2xl": "row" }}
        rowGap="3"
      >
        <Flex h="36px" alignItems="flex-end">
          <Text
            lineHeight="36px"
            fontWeight="600"
            fontSize="2.7vw"
            color="black.active"
            mr="1"
          >
            {clickCount}
          </Text>
          <Text
            fontWeight="400"
            fontSize="16px"
            color="black.active"
            lineHeight="14px"
            ml="1"
          >
            {t("page.analytics.clicks")}
          </Text>
        </Flex>

        <Flex display="column" h="full" w="full">
          <Flex
            justifyContent={{ base: "start", "2xl": "end" }}
            alignItems="start"
            h="50%"
          >
            {increased ? (
              <Box position="relative" top="6px">
                <ChevronFilledIcon fill="#50CD89" />
              </Box>
            ) : (
              <Box
                transform="translate(-50%, -50%)"
                position="relative"
                top="6px"
              >
                <ChevronFilledIcon fill="#F13232" />
              </Box>
            )}
            <Text
              ml="1"
              fontWeight="400"
              lineHeight="14px"
              fontSize="13px"
              color={increased ? "green.label" : "red.main"}
            >
              {increased
                ? t("page.analytics.increased-today", { variation: variation })
                : t("page.analytics.decreased-today", {
                    variation: variation,
                  })}{" "}
            </Text>
          </Flex>

          <Flex
            justifyContent={{ base: "start", "2xl": "end" }}
            alignItems="end"
            h="50%"
          >
            <Text
              fontWeight="400"
              lineHeight="14px"
              fontSize="13px"
              color="gray.smoke"
            >
              {t("page.analytics.last-updated")} {lastUpdate}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box w="full" height="200" mt="16">
        <Slider ref={sliderRef} {...settings}>
          {data.map((item, idx) => (
            <Box w="full" height="205px" key={idx}>
              <NotableDatesBarChart data={item} />
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

const data = [
  [
    {
      name: "19 Feb",
      data: 130,
    },
    {
      name: "20 Feb",
      data: 200,
    },
    {
      name: "21 Feb",
      data: 200,
    },
    {
      name: "22 Feb",
      data: 278,
    },
    {
      name: "23 Feb",
      data: 189,
    },
    {
      name: "24 Feb",
      data: 239,
    },
    {
      name: "25 Feb",
      data: 349,
    },
    {
      name: "65 Feb",
      data: 209,
    },
  ],
  [
    {
      name: "19 Feb",
      data: 130,
    },
    {
      name: "20 Feb",
      data: 200,
    },
    {
      name: "21 Feb",
      data: 200,
    },
    {
      name: "22 Feb",
      data: 278,
    },
    {
      name: "23 Feb",
      data: 189,
    },
    {
      name: "24 Feb",
      data: 239,
    },
    {
      name: "25 Feb",
      data: 349,
    },
    {
      name: "65 Feb",
      data: 209,
    },
  ],
  [
    {
      name: "19 Feb",
      data: 800,
    },
    {
      name: "20 Feb",
      data: 900,
    },
    {
      name: "21 Feb",
      data: 1000,
    },
    {
      name: "22 Feb",
      data: 398,
    },
    {
      name: "23 Feb",
      data: 399,
    },
    {
      name: "24 Feb",
      data: 999,
    },
    {
      name: "25 Feb",
      data: 333,
    },
    {
      name: "65 Feb",
      data: 666,
    },
  ],
  [
    {
      name: "19 Feb",
      data: 100,
    },
    {
      name: "20 Feb",
      data: 200,
    },
    {
      name: "21 Feb",
      data: 200,
    },
    {
      name: "22 Feb",
      data: 278,
    },
    {
      name: "23 Feb",
      data: 189,
    },
    {
      name: "24 Feb",
      data: 239,
    },
    {
      name: "25 Feb",
      data: 349,
    },
    {
      name: "65 Feb",
      data: 209,
    },
  ],
];
