import { Box, Flex, Text } from "@chakra-ui/react";

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CreditCard } from "../_payment-select";
import { WirelessIcon } from "@/assets/icons/wireless-icon";
import { ChipIcon } from "@/assets/icons/chip-icon";

export const CreditCardPreview: FC<{ card?: CreditCard }> = ({ card }) => {
  const { t } = useTranslation();
  return (
    <Box
      w="476px"
      h="241px"
      border="1.758px solid #B7BABF"
      boxShadow="14.647px 17.576px 29.294px 0px rgba(0, 0, 0, 0.05)"
      backdropFilter="blur(10.252809524536133px)"
      bg="linear-gradient(113deg, rgba(255, 255, 255, 0.51) 3.51%, rgba(255, 255, 255, 0.00) 111.71%)"
      rounded="3xl"
      color="black.dark"
      fontWeight="600"
    >
      <Flex
        justifyContent="space-between"
        mt="29px"
        ml="27px"
        mr="25px"
        alignItems="center"
      >
        <ChipIcon />
        <WirelessIcon />
      </Flex>
      <Box ml="23px" mt="21px" mb="62px">
        <Text
          fontSize="xs"
          letterSpacing="wide"
          opacity="0.9"
          mb="8px"
          lineHeight="8px"
        >
          {t("page.membership.Card_Number")}
        </Text>
        <Text
          fontSize="15px"
          letterSpacing="wide"
          opacity="0.9"
          lineHeight="14px"
        >
          {card?.cardNumber
            ?.replace(/([0-9*]{4})/g, (a: string) => a + " ")
            .trim()}
        </Text>
      </Box>
      <Flex ml="23px" alignItems="center" mb="32px">
        <Text
          fontSize="15px"
          letterSpacing="wide"
          opacity="0.9"
          lineHeight="14px"
          mr="108px"
          w="20ch"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {card?.name}
        </Text>
        <Box>
          <Text
            fontSize="xs"
            letterSpacing="wide"
            opacity="0.9"
            lineHeight="8px"
            mb="9px"
          >
            {t("page.membership.valid_thru")}
          </Text>
          <Text
            fontSize="15px"
            letterSpacing="wide"
            opacity="0.9"
            lineHeight="14px"
          >
            {card?.expiryMonth || "**"}/{card?.expiryYear || "**"}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
