import { Flex, Text, Icon, Progress } from "@chakra-ui/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ProgressInfoLineProps {
  percentage: number;
  icon: JSX.Element;
  postCount: number;
}

export const ProgressInfoLine: FC<ProgressInfoLineProps> = ({
  percentage,
  icon,
  postCount,
}) => {
  const { t } = useTranslation();
  return (
    <Flex mb="4">
      <Icon justifyContent="center" alignItems="center" mt="1px">
        {icon}
      </Icon>
      <Progress
        value={percentage}
        w="80%"
        borderRadius="3px"
        mx="2"
        h="12px"
        bgColor="gray.athens.default"
        css={{
          "& > div": {
            backgroundColor: "#57AAEC",
          },
        }}
      />
      <Text fontSize="0.9vw" fontWeight="400" lineHeight="14px" mr="2px">
        {postCount}
      </Text>
      <Text
        fontSize="0.8vw"
        fontWeight="400"
        whiteSpace="nowrap"
        lineHeight="14px"
      >
        {t("page.analytics.post")}
      </Text>
    </Flex>
  );
};
