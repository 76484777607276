import { APISchemas, DELETE, GET, PATCH } from "@/api";
import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  Box,
  Table,
  Input,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Menu,
  Checkbox,
  CheckboxGroup,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  IconButton,
  Text,
  useToast,
  Switch,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import { useTranslation } from "react-i18next";
import { SearchBoxIcon } from "@/assets/icons/search-box-icon";
import { UserRevokedIcon } from "@/assets/icons/user-revoked-icon";
import { Pagination } from "@/components/pagination";
import { useChannel } from "@/hooks/use-channels";
import { ConnectPlusIcon } from "@/assets/icons/connect-plus-icon";
import { SettingFilterIcon } from "@/assets/icons/settings-filter-icon";
import { DeleteAccountIcon } from "@/assets/icons/delete-account-icon";
import { ChannelIcon } from "@/components/channel-icon";
import { colors } from "@/theme/colors";

export const AccountSettings: FC = () => {
  const { organization } = useAuthStore((state) => ({
    user: state.user,
    organization: state.activeOrganization,
  }));

  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { t } = useTranslation();
  const toast = useToast();
  const [searchInput, setSearchInput] = useState<string>("");

  const { data: channels, refetch } = useQuery(
    ["channels", organization?.id],
    async () => {
      if (!organization) {
        return;
      }
      const { data } = await GET("/org/{org_pk}/channels/", {
        params: {
          path: {
            org_pk: organization.id.toString(),
          },
        },
      });

      return data;
    }
  );

  const handleSwitch = useCallback(
    async (item: APISchemas["Channel"]) => {
      const updatedStatus = item.status === "active" ? "inactive" : "active";

      if (!organization) return;

      const { response, error } = await PATCH("/org/{org_pk}/channels/{id}/", {
        params: {
          path: {
            org_pk: organization?.id.toString(),
            id: item.id,
          },
        },
        body: {
          organization: organization?.id,
          status: updatedStatus,
        },
      });
      if (response.status === 200) {
        toast({
          status: "success",
          description: t("alert.success.channel-updated-succesfully"),
          duration: 3000,
        });
        refetch();

        return;
      }
      if (error?.issues) {
        error.issues.map((err) => {
          toast({
            description: err.message,
            status: "error",
            isClosable: true,
          });
        });

        return;
      }

      if (error?.detail) {
        toast({
          description: error.detail,
          status: "error",
          isClosable: true,
        });

        return;
      }
      //  else {
      //   toast({
      //     status: "error",
      //     description: "An unexpected error ocurred while updating channel",
      //     duration: 3000,
      //   });
      // }
    },
    [organization, toast, refetch, t]
  );

  const navigate = useNavigate();

  const removeChannel = useCallback(
    async (channel: APISchemas["Channel"]) => {
      if (!organization) {
        return;
      }

      const { response, error } = await DELETE("/org/{org_pk}/channels/{id}/", {
        params: {
          path: {
            id: channel.id,
            org_pk: organization.id.toString(),
          },
        },
      });

      if (error) {
        toast({
          status: "error",
          description:
            error.detail ||
            t("errors.an-unexpected-error-occurred-removing-channel"),
          duration: 3000,
        });
        return;
      }

      if (response.ok) {
        toast({
          status: "success",
          title: t("alert.success.channel-removed-succesfully"),
          duration: 3000,
        });
        refetch();
        return;
      }

      toast({
        status: "error",
        description: t("errors.an-unexpected-error-occurred-removing-channel"),
        duration: 3000,
      });
    },
    [organization, toast, refetch, t]
  );

  const filteredChannels = useMemo(
    () =>
      channels?.filter((item) => {
        if (
          selectedChannels.length > 0 &&
          !selectedChannels.includes(
            item.channel_type.toLowerCase().split("_")[0]
          )
        ) {
          return false;
        }
        const nameMatch = item.name
          .toLowerCase()
          .includes(searchInput.toLowerCase());
        const channelMatch = item.channel_type
          .toLowerCase()
          .includes(searchInput.toLowerCase());

        return nameMatch || channelMatch;
      }),
    [channels, searchInput, selectedChannels]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput, selectedChannels]);

  const handleCheckboxChange = useCallback((selected: string[]) => {
    setSelectedChannels(selected);
  }, []);
  const accountPerPage = 5;

  const totalPages = filteredChannels
    ? Math.ceil(filteredChannels?.length / accountPerPage)
    : 0;
  const startIndex = (currentPage - 1) * accountPerPage;
  const endIndex = startIndex + accountPerPage;
  const currentPageAccounts = filteredChannels?.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  let activeCount = 0;
  let revokedCount = 0;
  let inactiveCount = 0;

  const { data: ValidChannels } = useChannel();

  ValidChannels?.forEach((channel) => {
    if (channel.status === "active") {
      activeCount++;
    }
  });

  channels?.forEach((channel) => {
    // if (channel.status === "active") {
    //   activeCount++;
    // } else
    if (channel.status === "revoked") {
      revokedCount++;
    } else if (channel.status === "inactive") {
      inactiveCount++;
    }
  });

  return (
    <Flex
      flexDir="column"
      h="full"
      mt="42px"
      bg="white.normal"
      borderRadius="6px"
      p="2px"
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.08)"
    >
      <Flex
        p="35px"
        alignItems="center"
        w="full"
        shadow="sm"
        borderRadius="6px"
        h="110px"
      >
        <Flex w="full" h="full" alignItems="center" flex="2">
          <Box ml="0" w="full">
            <Text
              color="black.active"
              fontSize="24px"
              fontWeight="600"
              lineHeight="20px"
              whiteSpace="nowrap"
            >
              {t("accounts")}
            </Text>
            <Text
              color="black.active"
              fontSize="13px"
              lineHeight="20px"
              mt="1.5"
            >
              {t("page.settings.account-settings.manage-member")}
            </Text>
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          flex="3"
          w="full"
          gap="16px"
          justifyContent="flex-end"
        >
          <Flex>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              mr="1"
            >
              {t("page.settings.account-settings.active")}:
            </Text>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              mr="3"
            >
              {activeCount}
            </Text>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              mr="1"
            >
              {t("page.settings.account-settings.inactive")}:
            </Text>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              mr="3"
            >
              {inactiveCount}{" "}
            </Text>
            <Text
              color="black.active"
              whiteSpace="nowrap"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              mr="1"
            >
              {t("page.settings.account-settings.revoked")}:
            </Text>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              mr="3"
            >
              {revokedCount}
            </Text>
          </Flex>

          <Flex
            border="1px solid"
            borderColor="blue.shiny"
            borderRadius="6px"
            w="184px"
            h="38px"
            pos="relative"
          >
            <IconButton
              pos="absolute"
              left="10px"
              top="-2px"
              aria-label="search-box-icon"
              icon={<SearchBoxIcon />}
              variant="unstyled"
            />
            <Input
              variant="unstyled"
              textAlign="left"
              color="blue.shiny"
              ml="51px"
              placeholder={t("page.settings.account-settings.search-name")}
              _placeholder={{ color: "blue.shiny", fontWeight: "600" }}
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              h="38px"
            />
          </Flex>
          <Menu
            closeOnSelect={false}
            placement="bottom-end"
            strategy="fixed"
            eventListeners={{
              resize: true,
            }}
          >
            <MenuButton
              w="130px"
              display="flex"
              h="38px"
              border="1px solid"
              borderColor="blue.shiny"
              borderRadius="6px"
              pos="relative"
              bg="transparent"
            >
              <IconButton
                aria-label="filter-box-icon"
                icon={<SettingFilterIcon />}
                variant="unstyled"
                size="none"
                mr="10px"
              />

              <Text
                as="span"
                color="blue.shiny"
                fontSize="16px"
                fontWeight="600"
                lineHeight="20px"
              >
                {t("filter")}
              </Text>
            </MenuButton>

            <MenuList borderRadius="6px">
              <CheckboxGroup
                value={selectedChannels}
                onChange={handleCheckboxChange}
              >
                <MenuItem>
                  <Checkbox h="full" value="twitter">
                    Twitter
                  </Checkbox>
                </MenuItem>
                <MenuItem>
                  <Checkbox h="full" value="facebook">
                    Facebook
                  </Checkbox>
                </MenuItem>

                <MenuItem>
                  <Checkbox h="full" value="instagram">
                    Instagram
                  </Checkbox>
                </MenuItem>
                <MenuItem>
                  <Checkbox h="full" value="linkedin">
                    LinkedIn
                  </Checkbox>
                </MenuItem>
              </CheckboxGroup>
            </MenuList>
          </Menu>
          <Flex>
            <Button
              fontSize="15px"
              bg="green.light"
              textColor="green.label"
              border="1px solid"
              borderColor="green.label.default"
              borderRadius="6px"
              onClick={() => navigate("/channels")}
              _hover={{
                opacity: 0.8,
              }}
              h="38px"
              w="202px"
            >
              <IconButton
                pos="absolute"
                left="15px"
                top="-3px"
                aria-label="connect-plus-icon"
                icon={<ConnectPlusIcon fill={colors.green.label.default} />}
                variant="unstyled"
              />
              <Text fontSize="16px" fontWeight="600">
                {t("connect_channel")}
              </Text>
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        p="16px"
        w="full"
        flexDir="column"
        gap="6"
        h="full"
        borderRadius="6px"
        shadow="sm"
      >
        <Box h="420px">
          <TableContainer>
            <Table variant="simple" h="max-content">
              <Tbody>
                {currentPageAccounts?.map((c) => (
                  <Tr
                    key={c.id}
                    alignItems="center"
                    borderBottom="1px solid rgba(144, 123, 123, 0.08)"
                  >
                    <Tr>
                      <Td borderWidth="0" w="300px">
                        <Flex alignItems="center">
                          <AvatarWithBadge
                            channel={c.channel_type}
                            picture={c.picture ?? undefined}
                            name={c.name}
                            w="45px"
                            h="45px"
                            border="1px solid transparent"
                          />
                          <Text ml="24px">
                            {c?.username
                              ? c.username.startsWith("@")
                                ? c.username
                                : `@${c.username}`
                              : c?.name}
                          </Text>
                        </Flex>
                      </Td>

                      <Td borderWidth="0">
                        <Flex alignItems="left">
                          <Text
                            mt="4px"
                            as="span"
                            display="block"
                            fontSize="13px"
                            color="gray.smoke"
                            fontWeight="500"
                          >
                            {c?.username
                              ? c.username.startsWith("@")
                                ? c.username
                                : `@${c.username}`
                              : `@${c.name}`}
                          </Text>
                        </Flex>
                      </Td>
                    </Tr>

                    <Td borderWidth="0">
                      <Flex alignItems="center">
                        <ChannelIcon
                          channel={c.channel_type}
                          w="25px"
                          h="25px"
                        />
                        <Text
                          color="black.active"
                          fontSize="16px"
                          fontWeight="500"
                          lineHeight="20px"
                          textAlign="center"
                          textTransform="capitalize"
                          ml="15px"
                        >
                          {c.channel_type.split("_")[0]}
                        </Text>
                      </Flex>
                    </Td>

                    <Td borderWidth="0" textAlign="center" fontSize="16px">
                      {c.status === "revoked" ? (
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gap="6px"
                        >
                          <Box width="30px" height="30px">
                            <UserRevokedIcon />
                          </Box>
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="black.active"
                            lineHeight="20px"
                          >
                            {t("page.settings.account-settings.revoked")}
                          </Text>
                        </Flex>
                      ) : c.status === "active" || c.status === "inactive" ? (
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gap="1"
                        >
                          <Text
                            fontSize="16spx"
                            fontWeight="500"
                            lineHeight="20px"
                            color="black.active"
                            mr="1"
                          >
                            {t("page.settings.account-settings.inactive")}
                          </Text>
                          <DeleteAlert
                            status={c.status}
                            type="updateStatus"
                            trigger={(onOpen) => (
                              <Switch
                                size="lg"
                                isChecked={c.status === "active"}
                                sx={{
                                  "&>span": {
                                    bg:
                                      c.status === "active"
                                        ? "blue.shiny"
                                        : undefined,
                                  },
                                }}
                                onChange={
                                  c.status === "active"
                                    ? () => onOpen()
                                    : () => handleSwitch(c)
                                }
                              />
                            )}
                            removeFn={() => handleSwitch(c)}
                            channel={c}
                          />

                          {/* <Switch isChecked={c.status === "active"} /> */}
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            lineHeight="20px"
                            color="black.active"
                            ml="1"
                          >
                            {t("page.settings.account-settings.active")}
                          </Text>
                        </Flex>
                      ) : (
                        <Text>
                          {t(`page.settings.account-settings.${c.status}`)}
                        </Text>
                      )}
                    </Td>
                    <Td borderWidth="0">
                      <Flex justifyContent="flex-end" gap="16px">
                        {/* <Button
                          cursor="pointer"
                          size="none"
                          variant="unstyled"
                          onClick={() => refetch()}
                        >
                          <ReconnectAccountIcon />
                        </Button> */}
                        <DeleteAlert
                          type="delete"
                          trigger={(onOpen) => (
                            <Button
                              size="none"
                              cursor="pointer"
                              variant="unstyled"
                              onClick={onOpen}
                            >
                              <DeleteAccountIcon />
                            </Button>
                          )}
                          removeFn={() => removeChannel(c)}
                          channel={c}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <Flex
          justifyContent="center"
          alignItems="flex-end"
          position="relative"
          top="5"
        >
          {filteredChannels?.length !== 0 && (
            <Box p={4}>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const DeleteAlert: FC<{
  type: "delete" | "updateStatus";
  trigger: (onOpen: () => void) => React.ReactNode;
  removeFn: () => void;
  channel?: APISchemas["Channel"];
  status?: string;
}> = ({ trigger, removeFn, status, type }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  return (
    <>
      {trigger(onOpen)}

      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="white.bg">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {type === "delete"
                ? t("alert.remove-modal.you-are-deleting-c")
                : status === "inactive"
                  ? t("alert.account-status-update.title-active")
                  : t("alert.account-status-update.title-deactive")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {type === "delete"
                ? t("alert.remove-modal.are-you-sure")
                : status === "inactive"
                  ? t("alert.account-status-update.description-active")
                  : t("alert.account-status-update.description-inactive")}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}>
                {t("alert.remove-modal.cancel")}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  removeFn();
                  onClose();
                }}
                ml={3}
              >
                {type === "delete"
                  ? t("alert.remove-modal.delete")
                  : t("update")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
