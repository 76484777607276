import { useAuthStore } from "@/context/auth-store/auth-store";
import { Flex, Image, Text, Box, useToast, Button } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { APISchemas, PATCH, POST } from "@/api";
import { useDropzone } from "react-dropzone";
import axios, { AxiosError } from "axios";
import MediaInfoFactory from "mediainfo.js";
import { useTranslation } from "react-i18next";
import { UploadProfileIcon } from "@/assets/icons/upload-profile-icon";
import { DeleteProfileIcon } from "@/assets/icons/delete-profile-icon";
import { UserAvatarBg } from "@/assets/icons/user-avatar-bg";

export const EditProfilePhoto: FC = () => {
  const { user, setUser } = useAuthStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }));
  const { t } = useTranslation();
  const toast = useToast();

  const onImageDrop = useCallback(
    async (files: File[]) => {
      for (const file of files) {
        const mediaInfo = await MediaInfoFactory();

        const info = await mediaInfo.analyzeData(
          () => file.size,
          async (chunkSize, offset) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target?.error) {
                  reject(event.target.error);
                  return;
                }

                if (event.target?.result) {
                  if (typeof event.target.result === "string") {
                    const encoder = new TextEncoder();
                    resolve(
                      new Uint8Array(encoder.encode(event.target.result).buffer)
                    );
                    return;
                  }
                  resolve(new Uint8Array(event.target.result));
                }

                resolve(new Uint8Array());
              };
              reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
            })
        );

        const file_type = file.type || `video/${file.name.split(".").pop()}`;

        const type: "image" | "video" | null = file_type.startsWith("image")
          ? "image"
          : file_type.startsWith("video")
            ? "video"
            : null;

        if (!type) {
          toast({
            status: "error",
            title: t("errors.could-not-recognized-media"),
          });
          return;
        }
        const general = info.media?.track.find((t) => t["@type"] === "General");

        if (type === "video") {
          toast({
            title: t("errors.can-not-add-video-to-your-profile-picture"),
            status: "error",
          });
          return;
        }

        const img = info.media?.track.find((t) => t["@type"] === "Image");

        if (!general || !img || !info.media?.track) {
          toast({
            title: t("errors.error-reading-its-image-informations", {
              file: file.name,
            }),
            status: "error",
          });
          return;
        }

        for (const key of ["Height", "Width"]) {
          if (!(key in img)) {
            toast({
              title: t("errors.error-reading-its-image-informations", {
                file: file.name,
              }),
              status: "error",
            });
            console.error(`key: ${key} not found in file meta`);
            return;
          }
        }

        const { data, error } = await POST("/media/upload/image/", {
          body: {
            height: img?.Height?.toString() ?? "",
            width: img?.Width?.toString() ?? "",
            size: file.size.toString(),
            raw: JSON.parse(JSON.stringify(info?.media?.track)),
          },
        });

        if (error) {
          toast({
            status: "error",
            title: t("errors.an-error-occurred"),
          });
          return;
        }

        if (!data) {
          console.error("Unexpected error data is undefined");
          return;
        }

        const formData = new FormData();
        formData.append("file", file);

        const toastId = toast({
          status: "info",
          title: t("alert.success.user-picture-is-uploading"),
          isClosable: true,
          duration: null,
        });

        axios
          .request({
            url: data.uploadUrl,
            method: "POST",
            data: formData,
          })
          .then(async () => {
            if (!user) {
              console.error("Unexpected error user is undefined");
              return;
            }

            PATCH("/auth/user/{id}/", {
              params: {
                path: {
                  id: user.id,
                },
              },
              body: {
                picture: data.downloadUrl,
              },
            }).then(() => {
              toast.update(toastId, {
                status: "success",
                title: t("alert.success.user-picture-is-updated"),
                duration: 3000,
              });

              setUser();
            });
          })
          .catch((reason: AxiosError) => {
            toast.update(toastId, {
              duration: 3000,
              status: "error",
              title:
                reason.cause?.message ??
                reason.message ??
                JSON.stringify(
                  reason.response?.data ?? t("errors.an-error-occurred")
                ),
            });
          });

        return;
      }
    },
    [setUser, toast, user, t]
  );

  const { getRootProps: fileRoot, getInputProps: fileInput } = useDropzone({
    onDrop: onImageDrop,
    accept: {
      "image/jpg": [".png", ".jpg", ".jpeg"],
    },
  });

  const deleteProfilePhoto = useCallback(
    async (user?: APISchemas["User"]) => {
      if (!user) {
        return;
      }

      const { response } = await PATCH("/auth/user/{id}/", {
        params: {
          path: {
            id: user.id,
          },
        },
        body: {
          picture: null,
        },
      });

      if (response.ok) {
        setUser();
        toast({
          title: t("alert.success.user-picture-is-deleted"),
          duration: 3000,
        });

        return;
      }

      toast({
        status: "error",
        description: t(
          "errors.an-unexpected-error-occures-editing-profile-info"
        ),
        duration: 3000,
      });
    },
    [toast, setUser, t]
  );

  return (
    <Flex alignItems="center" w="full" h="133px">
      <Flex w="full" alignItems="center">
        <Box flex="2">
          <Text
            fontSize="24px"
            fontWeight="600"
            lineHeight="20px"
            color="#353B48"
          >
            {t("page.settings.profile.profile-photo")}
          </Text>
          <Text color="#B7BABF" fontSize="13px" lineHeight="20px" mt="1.5">
            {t("page.settings.profile.this-will")}
          </Text>
        </Box>

        <Box position="relative" flex="0.75">
          <Box
            w="90px"
            h="90px"
            transition="opacity 0.5s ease"
            color="white.normal"
            justifyContent="center"
            alignItems="center"
            display="flex"
            fontSize="20px"
            fontWeight="600"
          >
            {user?.picture ? (
              <Image
                pos="absolute"
                top="0px"
                left="0px"
                src={user?.picture ?? undefined}
                w="90px"
                h="90px"
                transition="opacity 0.5s ease"
                border="0.5px solid"
                borderColor="white.normal"
                borderRadius="50%"
                shadow="md"
                objectFit="cover"
                objectPosition="center"
              />
            ) : (
              <UserAvatarBg />
            )}
            {/* <Flex>
              <Text textTransform="capitalize">
                {user?.first_name?.slice(0, 1) ?? ""}
              </Text>
              <Text textTransform="capitalize">
                {user?.last_name?.slice(0, 1) ?? ""}
              </Text>
            </Flex> */}

            {/* <Avatar
            color="black.active"
            bg="white.bg"
            name={fullName}
            src={user?.picture ?? undefined}
            w="80px"
            h="80px"
            border="0.5px solid white"
            shadow="md"
          /> */}
          </Box>
        </Box>

        {/* <Button
        hidden={true}
        w="180px"
        h="43px"
        bg="red.main"
        border-radius="6px"
        color="white"
        _hover={{
          opacity: "0.6",
        }}
      >
        <Flex alignItems="center" justifyContent="center">
          <Text fontWeight="600" fontSize="15px" lineHeight="14px">
            {t("button.delete-profile")}
          </Text>
        </Flex>
      </Button> */}

        <Box flex="2.25">
          <input {...fileInput()} />
          <Button
            {...fileRoot()}
            aria-label="edit"
            w="150px"
            h="34px"
            leftIcon={<UploadProfileIcon />}
            bg="#E8F9F0"
            mr="20px"
            border={`1px solid #50CD89`}
            borderRadius="28px"
            variant="unstyled"
            color="#50CD89"
          >
            {t("page.settings.profile.upload")}
          </Button>

          <Button
            aria-label="cancel"
            w="150px"
            h="34px"
            leftIcon={<DeleteProfileIcon />}
            onClick={() => deleteProfilePhoto(user)}
            bg="#FDE5E5"
            border={`1px solid #F13232`}
            borderRadius="28px"
            variant="unstyled"
            color="#F13232"
          >
            {t("delete")}
          </Button>

          <Text fontSize="13px" color="#B7BABF" mt="5px">
            {t("page.settings.profile.accepted-formats")}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
