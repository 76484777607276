import { FC } from "react";

export const DeleteMemberIcon: FC<React.SVGAttributes<SVGElement>> = (
  props
) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.4713 12.6275L18.4348 11.5911L15.7964 14.2295L13.1579 11.5911L12.1214 12.6275L14.7598 15.266L12.1214 17.9045L13.1579 18.941L15.7964 16.3025L18.4348 18.941L19.4713 17.9045L16.8328 15.266L19.4713 12.6275Z"
        fill="#F13232"
      />
      <path
        d="M10.0795 0.20459C7.08897 0.20459 4.65596 2.63759 4.65596 5.62814C4.65596 7.4805 5.58966 9.11866 7.01087 10.0975C5.68393 10.5554 4.46772 11.3118 3.44595 12.3335C1.67407 14.1055 0.698242 16.4613 0.698242 18.9671H2.16407C2.16407 14.6025 5.71491 11.0517 10.0795 11.0517C13.0701 11.0517 15.5031 8.61872 15.5031 5.62814C15.5031 2.63759 13.0701 0.20459 10.0795 0.20459ZM10.0795 9.58586C7.89723 9.58586 6.12179 7.81046 6.12179 5.62814C6.12179 3.44582 7.89723 1.67042 10.0795 1.67042C12.2618 1.67042 14.0372 3.44582 14.0372 5.62814C14.0372 7.81046 12.2618 9.58586 10.0795 9.58586Z"
        fill="#F13232"
      />
    </svg>
  );
};
