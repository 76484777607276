import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { CreditCard } from "../../Membership/_payment-select";
import { AmericanExpressIcon } from "@/assets/icons/american-express-icon";
import { MasterCardIcon } from "@/assets/icons/mastercard-logo";
import { VisaLogoIcon } from "@/assets/icons/visa-logo-icon";
import creditCardType from "credit-card-type";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { POST } from "@/api";
import { AddCardPreview } from "../../Membership/components/add-card-preview";

const CreditCardPreviewContext: FC = () => {
  const { watch } = useFormContext<CreditCard>();
  const [creditCard, setCreditCard] = useState<CreditCard>();

  useEffect(() => {
    const s = watch((v) => {
      if (!v) {
        console.log(v);
        return;
      }

      setCreditCard(v as CreditCard);
    });

    return () => {
      s.unsubscribe();
    };
  });

  return <AddCardPreview card={creditCard} />;
};

type AddNewCardProps = {
  onButtonClick: () => void;
};

export const AddNewCard: FC<AddNewCardProps> = ({ onButtonClick }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const CreditCardSchema = z.object({
    name: z.string().min(1),
    cardNumber: z.string().refine(
      (v) => {
        const cardType = creditCardType(v)[0];
        return cardType.lengths[0] === v.length;
      },
      {
        message: t("errors.card-number-is-invalid"),
      }
    ),
    expirationDate: z.string(),
    expiryYear: z
      .string()
      .length(2)
      .refine((v) => Number(v) > 0 && Number(v) < 99, {
        message: t("errors.expiry-year-is-invalid"),
      }),
    expiryMonth: z
      .string()
      .length(2)
      .refine((v) => Number(v) > 0 && Number(v) < 13, {
        message: t("errors.expiry-month-is-invalid"),
      }),
    CVV: z
      .string()
      .min(3, t("errors.cvv-is-invalid"))
      .max(4, t("errors.cvv-is-invalid")),
    bankCode: z.string(),
  });

  const methods = useForm<z.infer<typeof CreditCardSchema>>({
    resolver: zodResolver(CreditCardSchema),
  });

  const { register, formState, setValue, control, handleSubmit } = methods;

  const onSubmit: SubmitHandler<z.infer<typeof CreditCardSchema>> = useCallback(
    async (values) => {
      const { response, error, data } = await POST("/payment/save-card/", {
        body: {
          cardHolderName: values.name,
          cardNumber: values.cardNumber,
          cardMonth: values.expiryMonth,
          cardYear: "20" + values.expiryYear,
        },
      });

      if (response.ok) {
        toast({
          status: "success",
          title: t("card-successfully-added"),
        });

        onButtonClick();
        methods.reset();
      }

      if (error?.issues) {
        error.issues.map((err) => {
          toast({
            description: err.message,
            status: "error",
            isClosable: true,
          });
        });

        return;
      }

      if (error?.detail) {
        toast({
          description: error.detail,
          status: "error",
          isClosable: true,
        });
        console.error(error.detail);
        return;
      }

      if (data?.result.startsWith("Invalid")) {
        toast({
          description: data.resultMessage,
          status: "error",
          isClosable: true,
        });

        return data;
      }

      return data;
    },
    [toast, t]
  );

  return (
    <Box
      h="full"
      my="42px"
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.08)"
      borderRadius="6px"
      p="36px"
      bg="white.normal"
    >
      <Flex w="full" justifyContent="space-between" mb="37px">
        <Box ml="0">
          <Text
            color="black.active"
            fontSize="24px"
            fontWeight="600"
            lineHeight="20px"
            mb="20px"
          >
            {t("payment_method")}
          </Text>
          <Text
            color="gray.light"
            fontSize="16px"
            lineHeight="17px"
            mt="1.5"
            w="60%"
          >
            {t("payment-info")}
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Flex flex={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid templateColumns="repeat(3, 1fr)" gap="37px" w="36vw">
              <GridItem colSpan={3}>
                <FormControl isInvalid={!!formState.errors.name}>
                  <FormLabel
                    pos="absolute"
                    top="4px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("page.membership.Name")}
                  </FormLabel>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Input
                          type="text"
                          border="1px solid"
                          borderColor="gray.passive"
                          borderRadius="4px"
                          maxLength={20}
                          h="50px"
                          w="full"
                          mt="3"
                          p="3"
                          color="gray.passive"
                          placeholder={t("page.membership.Name")}
                          _placeholder={{
                            color: "gray.passive",
                          }}
                          {...register("name")}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage pos="absolute" top="58px">
                    {formState.errors.name?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={3}>
                <FormControl isInvalid={!!formState.errors.cardNumber}>
                  <FormLabel
                    pos="absolute"
                    top="4px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("page.membership.Card_Number")}
                  </FormLabel>
                  <Controller
                    name="cardNumber"
                    control={control}
                    render={({ field }) => {
                      const formattedValue = field.value
                        ?.replace(/([^0-9*])/g, "")
                        ?.replace(/([0-9*]{4})/g, (a: string) => {
                          return a + " ";
                        })
                        .trim();

                      const cardType = creditCardType(field.value)?.[0]?.type;

                      setValue("bankCode", cardType);

                      return (
                        <InputGroup>
                          <InputLeftElement
                            bg="none"
                            borderRight="none"
                            h="100%"
                            mt="6px"
                          >
                            {cardType === "mastercard" ? (
                              <Box
                                bg="black.dark.default"
                                rounded="base"
                                width="27px"
                                height="20px"
                                p="2px"
                              >
                                <MasterCardIcon />
                              </Box>
                            ) : cardType === "visa" ? (
                              <Box width="27px" height="29px">
                                <VisaLogoIcon />
                              </Box>
                            ) : cardType === "american-express" ? (
                              <Box width="30px" height="29px">
                                <AmericanExpressIcon />
                              </Box>
                            ) : null}
                          </InputLeftElement>
                          <Input
                            type="tel"
                            maxLength={19}
                            border="1px solid"
                            borderColor="gray.passive"
                            borderRadius="4px"
                            w="full"
                            h="50px"
                            mt="3"
                            p="6"
                            pl="40px"
                            color="gray.passive"
                            placeholder="**** **** **** **** ****"
                            _placeholder={{
                              color: "gray.passive",
                            }}
                            onChange={(e) =>
                              field.onChange(
                                e.target.value
                                  ?.replace(/([^0-9*])/g, "")
                                  .replace(/  +/g, " ")
                              )
                            }
                            value={formattedValue?.replace(/  +/g, " ")}
                          />
                        </InputGroup>
                      );
                    }}
                  />

                  <FormErrorMessage pos="absolute" top="58px">
                    {formState.errors.cardNumber?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={3}>
                <SimpleGrid columns={2} gap="6">
                  <FormControl
                    isInvalid={
                      !!formState.errors.expiryMonth ||
                      !!formState.errors.expiryYear
                    }
                  >
                    <FormLabel
                      pos="absolute"
                      top="4px"
                      fontSize="16px"
                      zIndex="9"
                      ml="2.5"
                      px="4"
                      fontWeight="400"
                      lineHeight="1"
                      pr="8"
                      bg="white.normal"
                      whiteSpace="nowrap"
                    >
                      {t("page.membership.expiration-date")}
                    </FormLabel>
                    <Controller
                      name="expirationDate"
                      control={control}
                      render={({ field }) => {
                        const value = field.value?.replace(/([^0-9*])/g, "");
                        const month = value?.slice(0, 2);
                        const year = value?.slice(2, 4);

                        const formattedValue = year
                          ? `${month}/${year}`
                          : month;

                        setValue("expiryMonth", month);
                        setValue("expiryYear", year);

                        return (
                          <Input
                            type="tel"
                            placeholder={"MM/YY"}
                            border="1px solid"
                            borderColor="gray.passive"
                            borderRadius="4px"
                            mt="3"
                            p="6"
                            maxLength={5}
                            onChange={field.onChange}
                            value={formattedValue}
                          />
                        );
                      }}
                    />

                    <FormErrorMessage pos="absolute" top="58px">
                      <Text> {formState.errors.expiryMonth?.message} </Text>
                      <Text> {formState.errors.expiryYear?.message}</Text>
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!formState.errors.CVV}>
                    <FormLabel
                      pos="absolute"
                      top="4px"
                      fontSize="16px"
                      zIndex="9"
                      ml="2.5"
                      px="4"
                      fontWeight="400"
                      lineHeight="1"
                      pr="8"
                      bg="white.normal"
                    >
                      CVV
                    </FormLabel>
                    <Controller
                      name="CVV"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            type="tel"
                            border="1px solid"
                            borderColor="gray.passive"
                            borderRadius="4px"
                            mt="3"
                            p="6"
                            onChange={(e) => field.onChange(e.target.value)}
                            value={field.value}
                            placeholder="***"
                            maxLength={4}
                          />
                        );
                      }}
                    />
                    <FormErrorMessage pos="absolute" top="58px">
                      {formState.errors.CVV?.message}
                    </FormErrorMessage>
                  </FormControl>
                </SimpleGrid>
              </GridItem>
              <GridItem colSpan={3}>
                <Button
                  bg="blue.shiny"
                  color="white"
                  w="full"
                  borderRadius="3px"
                  py="18px"
                  size="none"
                  _hover={{
                    opacity: "0.8",
                  }}
                  type="submit"
                >
                  {t("add-card")}
                </Button>
              </GridItem>
              <GridItem colSpan={3}>
                <Button
                  bg="gray.smoke"
                  color="white"
                  w="full"
                  borderRadius="3px"
                  py="18px"
                  mt="-12px"
                  size="none"
                  _hover={{
                    opacity: "0.8",
                  }}
                  onClick={onButtonClick}
                >
                  {t("cancel")}
                </Button>
              </GridItem>
            </Grid>
          </form>
        </Flex>
        <Flex
          flex={window.innerWidth > 1024 ? 2 : 1}
          justifyContent="center"
          m="20px"
        >
          <FormProvider {...methods}>
            <CreditCardPreviewContext />
          </FormProvider>
        </Flex>
      </Flex>
    </Box>
  );
};
