import { useAuthStore } from "@/context/auth-store/auth-store";
import { Flex, useToast, Divider } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { useQuery } from "react-query";
import { EditPersonalInfo } from "./edit-personal-info";
import { EditBillingInfo } from "./edit-billing-info";
import { APISchemas, PATCH, GET, POST } from "@/api";
import { useTranslation } from "react-i18next";
import { EditPassword } from "./edit-password";
import { EditProfilePhoto } from "./edit-profil-photo";

interface editPersonalProps {
  name: string;
  surname: string;
  phone: string;
}
interface editBillingProps {
  name: string;
  street: string;
  county: string;
  city: string;
  postal_code: string;
  country: string;
  detail: string;
  phone: string;
  contact_name: string;
  tin?: string;
  address_type?: "P" | "B";
  tax_office?: string;
}

interface editPassword {
  oldPassword: string;
  newPassword: string;
}

export const Profile: FC = () => {
  const { user, setUser } = useAuthStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }));

  const { t } = useTranslation();
  const toast = useToast();

  const { data: address, refetch } = useQuery(
    ["address", user?.id],
    async () => {
      const { data } = await GET("/auth/address/", {});

      return data;
    }
  );

  const editPersonalInfo = useCallback(
    async (data: editPersonalProps, user: APISchemas["User"]) => {
      if (!user) {
        return;
      }

      const { response } = await PATCH("/auth/user/{id}/", {
        params: {
          path: {
            id: user.id,
          },
        },
        body: {
          first_name: data.name,
          last_name: data.surname,
          phone: data.phone.replaceAll(" ", ""),
          biography: "test",
          description: "test",
        },
      });

      if (response.ok) {
        setUser();
        toast({
          status: "success",
          title: t("alert.success.profile-edited-successfully"),
          duration: 3000,
        });

        return;
      }

      toast({
        status: "error",
        description: t(
          "errors.an-unexpected-error-occures-editing-profile-info"
        ),
        duration: 3000,
      });
    },
    [toast, setUser, t]
  );

  const editPasswordInfo = useCallback(
    async (data: editPassword, user: APISchemas["User"]) => {
      if (!user) {
        return;
      }

      const { response, error } = await POST("/auth/change-password/", {
        params: {
          query: {
            id: user.id,
          },
        },
        body: {
          old_password: data.oldPassword,
          new_password: data.newPassword,
        },
      });

      if (response.ok) {
        setUser();
        toast({
          status: "success",
          title: t("alert.success.password-changed-successfully"),
          duration: 3000,
        });

        return;
      }

      return error?.issues;
    },
    [setUser, toast, t]
  );

  const editBillingInfo = (
    data: editBillingProps,
    individual: boolean,
    user: APISchemas["User"]
  ) => {
    if (address && address.length > 0) {
      patchBillingInfo(data, individual);
    } else postBillingInfo(data, individual, user);
  };

  const postBillingInfo = useCallback(
    async (
      data: editBillingProps,
      individual: boolean,
      user: APISchemas["User"]
    ) => {
      if (!user) {
        return;
      }
      const address_type = individual ? "P" : "B";
      const { response, error } = await POST("/auth/address/", {
        body: {
          name: data.name,
          country: data.country,
          city: data.city,
          street: data.street,
          county: data.county,
          postal_code: data.postal_code,
          detail: data.detail,
          phone: data.phone.replaceAll(" ", ""),
          contact_name: data.contact_name,
          tin: individual ? undefined : data.tin,
          tax_office: individual ? undefined : data.tax_office,
          address_type: address_type,
        },
      });
      if (response.ok) {
        refetch();
        toast({
          status: "success",
          title: t("alert.success.billing-info-edited-successfully"),
          duration: 3000,
        });

        return;
      }
      if (error) {
        toast({
          status: "error",
          description: error.detail ?? t("errors.an-error-occurred"),
          duration: 3000,
        });
      }
      return error?.issues;
    },
    [toast, refetch, t]
  );

  const patchBillingInfo = useCallback(
    async (data: editBillingProps, individual: boolean) => {
      if (!address) {
        return;
      }
      const address_type = individual ? "P" : "B";
      const { response, error } = await PATCH("/auth/address/{id}/", {
        params: {
          path: {
            id: address[0].id,
          },
        },
        body: {
          name: data.name,
          country: data.country,
          city: data.city,
          street: data.street,
          county: data.county,
          postal_code: data.postal_code,
          detail: data.detail,
          phone: data.phone.replaceAll(" ", ""),
          contact_name: data.contact_name,
          tin: individual ? undefined : data.tin,
          tax_office: individual ? undefined : data.tax_office,
          address_type: address_type,
        },
      });
      if (response.ok) {
        refetch();
        toast({
          status: "success",
          title: t("alert.success.billing-info-edited-successfully"),
          duration: 3000,
        });

        return;
      }

      if (error) {
        toast({
          status: "error",
          description: error.detail ?? t("errors.an-error-occurred"),
          duration: 3000,
        });
      }
      return error?.issues;
    },

    [toast, refetch, address, t]
  );

  return (
    <Flex
      flexDir="column"
      h="full"
      w="full"
      mt="42px"
      bg="white.normal"
      borderRadius="6px"
      p="2px"
      boxShadow={"0px 0px 20px rgba(0, 0, 0, 0.08)"}
    >
      <Flex flexDir="column" bg="white.normal" p="35px">
        {user && <EditProfilePhoto />}
      </Flex>

      <Divider w="calc(100% - 70px)" mx="auto" />

      <Flex shadow="sm" flexDir="column" bg="white.normal" p="35px">
        {user && (
          <EditPersonalInfo
            handleEdit={(data) => editPersonalInfo(data, user)}
            userInfo={user}
          />
        )}
      </Flex>
      <Divider w="calc(100% - 70px)" mx="auto" />
      <Flex flexDir="column" bg="white.normal" shadow="sm" p="35px">
        {user && (
          <EditPassword
            handleEdit={(data) => editPasswordInfo(data, user)}
            user={user}
          />
        )}
      </Flex>
      <Divider />
      <Flex flexDir="column" bg="white.normal" shadow="sm" p="40px">
        {user && (
          <EditBillingInfo
            handleEdit={(data, individual) =>
              editBillingInfo(data, individual, user)
            }
            billingInfo={address ? address[0] : undefined}
          />
        )}
      </Flex>
      {/* <Flex
          border="1px solid #E1E3EA"
          px="3"
          flexDir="column"
          bg="white.normal"
          borderRadius="6px"
          shadow="sm"
        >
          {user && (
            <EditSubscriptionInfo
            //handleEdit={(data) => editBillingInfo(data, user)}
            />
          )}
        </Flex> */}
    </Flex>
  );
};
