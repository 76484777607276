import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback, useEffect, useRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "@/i18n";
import { useFormStore } from "../form-store";
import { FacebookFormPresent } from "../forms-presents/facebook-form-present";
import { StandartFile } from "../common";
import { Box, useToast } from "@chakra-ui/react";
import { APISchemas, PATCH, POST } from "@/api";
import { handleError } from "./_handle-errors";
import isEqual from "lodash.isequal";
import { formUpdateMS } from "../contant";
import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  checkVideoSize,
  checkGeneralImageFormat,
  checkContentMention,
  checkContentHashtag,
  checkMPEGVideoFormat,
  checkVideoDuration,
  checkImageSize,
  checkVideoAspectRatio,
  checkSingleMediaType,
} from "../../Post/platform-validations";
import { i18n } from "@/i18n";
import { useTranslation } from "react-i18next";

export type facebookSchema = z.infer<typeof facebookSchema>;
// eslint-disable-next-line react-refresh/only-export-components
export const facebookSchema = z
  .object({
    id: z.number().optional(),
    content: z
      .string()
      .max(6000, i18n.t("post-validations.facebook.character-limit")),
    files: StandartFile.array().optional(),
  })
  .refine((o) => o.content || (o.files && o.files.length > 0), {
    path: ["root"],
    message: i18n.t("post-validations.facebook.has-content"),
  })
  .refine((o) => checkContentHashtag(o, 30), {
    path: ["root"],
    message: i18n.t("post-validations.facebook.hashtag-limit"),
  })
  .refine((o) => checkContentMention(o, 20), {
    path: ["root"],
    message: i18n.t("post-validations.facebook.mentions-limit"),
  })

  .refine(checkMPEGVideoFormat, {
    path: ["root"],
    message: i18n.t("post-validations.facebook.video-format"),
  })
  .refine(checkGeneralImageFormat, {
    path: ["root"],
    message: i18n.t("post-validations.facebook.image-format"),
  })
  .refine((o) => checkImageSize(o, 10 * 1024 * 1024), {
    path: ["root"],
    message: i18n.t("post-validations.facebook.image-size"),
  })
  .refine((o) => checkVideoDuration(o, 7200, 0), {
    path: ["root"],
    message: i18n.t("post-validations.facebook.video-duration"),
  })
  .refine((o) => checkVideoSize(o, 1 * 1024 * 1024 * 1024), {
    path: ["root"],
    message: i18n.t("post-validations.facebook.video-size"),
  })
  .refine((o) => checkVideoAspectRatio(o, 0.25, 4), {
    path: ["root"],
    message: i18n.t("post-validations.facebook.video-aspectRatio"),
  })
  .refine(checkSingleMediaType, {
    path: ["root"],
    message: i18n.t("post-validations.facebook.single-media-type"),
  });

export const FacebookFormContainer: FC<{
  channel: APISchemas["Channel"] & { hasError?: boolean };
  defaultValue?: facebookSchema;
}> = ({ channel, defaultValue }) => {
  const toast = useToast();
  const defaultValueRef = useRef<typeof defaultValue>();
  const activeOrganization = useAuthStore((f) => f.activeOrganization);

  const [formState, setFormState] = useFormStore((f) => [
    f.fromStates[channel.id.toString()] ?? {
      sync: true,
    },
    f.setFormState,
  ]);

  const formSync = useFormStore((f) => f.formSync);

  const {
    main,
    addFacebookPost,
    scheduledAt,
    setForm,
    subscribe,
    unsubscribe,
    blockProgress,
    goToStep,
    setPlatformHasError,
    setGlobalError,
  } = useFormStore((s) => ({
    main: s.mainForms.main,
    setForm: s.setForm,
    subscribe: s.subscribe,
    unsubscribe: s.unsubscribe,
    scheduledAt: s.scheduledAt,
    addFacebookPost: s.addFacebookPost,
    blockProgress: s.setProgressBlock,
    goToStep: s.goToStep,
    setPlatformHasError: s.setPlatformHasError,
    setGlobalError: s.setGlobalError,
  }));

  const methods = useForm<facebookSchema>({
    resolver: zodResolver(facebookSchema),
  });

  const {
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty },
    trigger,
  } = methods;

  if (!isEqual(defaultValue, defaultValueRef.current)) {
    reset(defaultValue);
    setFormState(channel.id.toString(), {
      sync: false,
    });
    defaultValueRef.current = defaultValue;
  }

  const { t } = useTranslation();
  const onSubmit: (
    status: APISchemas["StatusCf9Enum"]
  ) => SubmitHandler<facebookSchema> = useCallback(
    (status) => async (data) => {
      if (!activeOrganization) {
        return;
      }
      const coverUrls: Record<string, string> = {};
      const userTags: APISchemas["MetadataRequest"]["user_tags"] = {};
      const audioNames: string[] = [];
      if (!activeOrganization) return;

      data.files?.forEach((file) => {
        if (file.data.metadata?.audioName) {
          audioNames.push(file.data.metadata.audioName);
        }

        if (file.data.metadata?.userTags) {
          const id = file.data.image?.id
            ? file.data.image?.id
            : file.data.video?.id
              ? file.data.video.id
              : null;

          if (id) {
            userTags[id] = {
              tags: file.data.metadata?.userTags,
            };
          }
        }
        if (!file.data.video) {
          return;
        }
        if (!("coverUrl" in file.data.video)) {
          return;
        }
        if (
          !file.data.video.coverUrl ||
          typeof file.data.video.coverUrl !== "string"
        ) {
          return;
        }
        coverUrls[file.data.video.id] = file.data.video.coverUrl;
      });
      const body: APISchemas["FacebookPostCreateRequest"] = {
        body: data.content,
        channel: channel.id,
        status,
        scheduled_at: scheduledAt.toISOString(),
        metadata: { cover_url: coverUrls },
        media:
          data.files
            ?.map((f) => f.data.image?.id || f.data.video?.id)
            .filter((m): m is string => !!m)
            .map((m) => Number(m)) ?? [],
      };
      const { data: post, error } = defaultValueRef.current?.id
        ? await PATCH("/facebook/{org_pk}/post/{id}/", {
            params: {
              path: {
                org_pk: activeOrganization.id.toString(),
                id: defaultValueRef.current.id,
              },
            },
            body,
          })
        : await POST("/facebook/{org_pk}/post/", {
            params: {
              path: {
                org_pk: activeOrganization.id.toString(),
              },
            },
            body,
          });

      if (error) {
        blockProgress(true);
        handleError(channel.id.toString(), error, setGlobalError);
        setPlatformHasError(channel, true);
        goToStep?.(1);
        return;
      }

      if (!post) {
        toast({
          status: "error",
          title: t("errors.unexpected-error-contact-customer-service"),
        });
        return;
      }
      setPlatformHasError(channel, false);
      addFacebookPost(post);
    },
    [
      activeOrganization,
      addFacebookPost,
      blockProgress,
      channel,
      goToStep,
      scheduledAt,
      setGlobalError,
      setPlatformHasError,
      toast,
      t,
    ]
  );

  const draftForm = useCallback(async () => {
    const result = await trigger();

    if (!result) {
      setPlatformHasError(channel, true);
      return;
    }

    setPlatformHasError(channel, false);
    setForm(channel.id, getValues());
  }, [channel, getValues, setForm, setPlatformHasError, trigger]);

  useEffect(() => {
    if (main && formState.sync) {
      reset({
        content: main.content,
        files: main.files as any,
      });
    }
  }, [formState.sync, main, reset]);

  useEffect(() => {
    if (isDirty) {
      setFormState(channel.id.toString(), {
        sync: false,
      });
    }
  }, [channel.id, isDirty, setFormState]);

  useEffect(() => {
    const submitFn = handleSubmit(onSubmit("planned"));
    const draftFn = handleSubmit(onSubmit("draft"));
    subscribe("submit", submitFn);
    subscribe("check", draftForm);
    subscribe("draft", draftFn);

    return () => {
      unsubscribe("submit", submitFn);
      unsubscribe("check", draftForm);
      unsubscribe("draft", draftFn);
    };
  }, [draftForm, handleSubmit, onSubmit, subscribe, unsubscribe]);

  useEffect(() => {
    if (!formSync) {
      return;
    }

    let prev: facebookSchema = {
      content: "",
    };

    const interval = setInterval(() => {
      const values = getValues();

      if (isEqual(prev, values)) {
        return;
      }

      if (channel.hasError) {
        draftForm();
      }

      setForm(channel.id, values);
      prev = values;
    }, formUpdateMS);

    return () => {
      clearInterval(interval);
    };
  }, [channel, draftForm, formSync, getValues, setForm]);

  return (
    <Box border="1px solid #E2E4E6" rounded="md" px="3" pos="relative">
      <form>
        <FormProvider {...methods}>
          <FacebookFormPresent channel={channel} />
        </FormProvider>
      </form>
    </Box>
  );
};
