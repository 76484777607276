export const PasswordCheckIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.9997 39.6668C31.2213 39.6668 34.138 38.361 36.2492 36.2497C38.3605 34.1385 39.6663 31.2218 39.6663 28.0002C39.6663 24.7785 38.3605 21.8619 36.2492 19.7506C34.138 17.6393 31.2213 16.3335 27.9997 16.3335C24.778 16.3335 21.8614 17.6393 19.7501 19.7506C17.6388 21.8619 16.333 24.7785 16.333 28.0002C16.333 31.2218 17.6388 34.1385 19.7501 36.2497C21.8614 38.361 24.778 39.6668 27.9997 39.6668Z"
        fill="#F5F5F5"
        stroke="#57AAEC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M23.333 28L26.833 31.5L33.833 24.5"
        stroke="#57AAEC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
