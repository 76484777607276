import { FC } from "react";
import { Text, Box, Grid, GridItem } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { InteractionInfoLine } from "./components/interaction-info-line";
import { ImageIcon } from "@/assets/icons/image-icon";
import { VideoIcon } from "@/assets/icons/video-icon";
import { TIcon } from "@/assets/icons/t-icon";
import { CircularChart } from "./components/circular-chart";
import { ProgressInfoLine } from "./components/progress-info-line";

export const ContentPerformance: FC = () => {
  const { t } = useTranslation();
  return (
    <Box w="full" h="full" bgColor="white" p="4" borderRadius="6px">
      <Box w="full" alignItems="center ">
        <Text
          fontWeight="600"
          lineHeight="14px"
          fontSize="16px"
          color="black.active"
        >
          {t("page.analytics.content-performance")}
        </Text>
        <Text
          fontWeight="400"
          lineHeight="14px"
          fontSize="12px"
          color="gray.smoke"
          mt="12px"
        >
          {t("page.analytics.content-performance-subtitle")}
        </Text>
      </Box>

      <Grid templateColumns="repeat(9, 1fr)" my="10" gap="1">
        <GridItem
          colSpan={4}
          alignItems="center"
          justifyContent="center"
          w="full"
          h="full"
        >
          <CircularChart />
        </GridItem>

        <GridItem
          w="full"
          h="full"
          alignItems="center"
          display="flex"
          colSpan={5}
        >
          <Box>
            <InteractionInfoLine
              text="Photo Interaction"
              data="44B"
              icon={<ImageIcon width="20px" height="20px" />}
            />
            <InteractionInfoLine
              text="Video Interaction"
              data="44B"
              icon={<VideoIcon width="20px" height="20px" />}
            />
            <InteractionInfoLine
              text="Text Interaction"
              data="44B"
              icon={<TIcon width="20px" height="20px" />}
            />
          </Box>
        </GridItem>
      </Grid>
      <Box>
        <ProgressInfoLine
          percentage={70}
          icon={<ImageIcon width="20px" height="20px" />}
          postCount={30}
        />
        <ProgressInfoLine
          percentage={40}
          icon={<VideoIcon width="20px" height="20px" />}
          postCount={100}
        />
        <ProgressInfoLine
          percentage={20}
          icon={<TIcon width="20px" height="20px" />}
          postCount={150}
        />
      </Box>
    </Box>
  );
};
