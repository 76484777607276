import React from "react";

export const DeleteProfileIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.74319 5.18738L2.57348 15.2103C2.63298 15.9332 3.24866 16.4999 3.97423 16.4999H11.6244C12.3499 16.4999 12.9656 15.9332 13.0251 15.2103L13.8554 5.18738H1.74319ZM5.4551 14.6249C5.20973 14.6249 5.00329 14.434 4.98773 14.1854L4.51898 6.62291C4.50294 6.36428 4.69932 6.14182 4.95751 6.12578C5.22529 6.107 5.43816 6.30569 5.45463 6.56432L5.92338 14.1268C5.93998 14.3946 5.72807 14.6249 5.4551 14.6249ZM8.26804 14.1561C8.26804 14.4152 8.05838 14.6249 7.79929 14.6249C7.54019 14.6249 7.33054 14.4152 7.33054 14.1561V6.59363C7.33054 6.33453 7.54019 6.12488 7.79929 6.12488C8.05838 6.12488 8.26804 6.33453 8.26804 6.59363V14.1561ZM11.0796 6.62294L10.6109 14.1854C10.5954 14.4315 10.3904 14.6366 10.1137 14.624C9.85554 14.6079 9.65916 14.3855 9.67519 14.1268L10.1439 6.56435C10.16 6.30572 10.3866 6.11757 10.6411 6.12582C10.8993 6.14185 11.0956 6.36432 11.0796 6.62294Z"
        fill="#F13232"
      />
      <path
        d="M13.893 2.37488H11.0805V1.90613C11.0805 1.13069 10.4497 0.499878 9.67426 0.499878H5.92426C5.14882 0.499878 4.51801 1.13069 4.51801 1.90613V2.37488H1.70551C1.18772 2.37488 0.768005 2.7946 0.768005 3.31238C0.768005 3.8301 1.18772 4.24988 1.70551 4.24988C6.01694 4.24988 9.58172 4.24988 13.893 4.24988C14.4108 4.24988 14.8305 3.8301 14.8305 3.31238C14.8305 2.7946 14.4108 2.37488 13.893 2.37488ZM10.143 2.37488H5.45551V1.90613C5.45551 1.6475 5.66563 1.43738 5.92426 1.43738H9.67426C9.93288 1.43738 10.143 1.6475 10.143 1.90613V2.37488Z"
        fill="#F13232"
      />
    </svg>
  );
};
