import {
  Box,
  HStack,
  VStack,
  Text,
  Image,
  AspectRatio,
  Icon,
} from "@chakra-ui/react";
import { FC, useRef } from "react";
import { Forms, useFormStore } from "../form-store";
import { mainSchema } from "../form-containers";
import { BunnyPlayer } from "@/components/bunny-player/bunny-player";
import React from "react";
import Slider from "react-slick";
import { colors } from "@/theme/colors";
import { LogoBlue } from "@/assets/logo/logo-blue";

export const MainPreview: FC = () => {
  const mainForms = useFormStore((s) => s.mainForms);
  const selectedPlatform = useFormStore((s) => s.selectedPlatform);
  const dataRef = useRef<Forms | mainSchema>();

  if (selectedPlatform) {
    return null;
  }

  if (!selectedPlatform) {
    dataRef.current = mainForms.main;
  }

  if (!dataRef.current) {
    return null;
  }

  const name = "Posteffect.ai";
  const username = "posteffectai";

  return (
    <Box
      w="full"
      maxW="md"
      h="min-content"
      bg="white"
      rounded="md"
      boxShadow="0px 13px 29px 0px rgba(0, 0, 0, 0.22)"
      pb="5"
      pt="4"
      px="8"
    >
      <HStack gap="2" my="0.5">
        <Icon boxSize="9">
          <LogoBlue width="100%" height="100%" />
        </Icon>
        <VStack gap="0" alignItems="flex-start">
          <Text
            fontSize="md"
            lineHeight="5"
            fontWeight="bold"
            color="black.active"
          >
            {name}
          </Text>
          <Text
            fontSize="xs"
            lineHeight="5"
            fontWeight="light"
            color="gray.passive"
          >
            @{username}
          </Text>
        </VStack>
      </HStack>

      <Box
        mt="4"
        hidden={!dataRef.current.files || dataRef.current.files.length === 0}
        w="full"
        h="20rem"
        sx={{
          "& .slick-active button:before": {
            color: `${colors.blue.hashtag} !important`,
            opacity: "1 !important",
          },

          "& .slick-dots button:before": {
            color: colors.gray.passive,
            opacity: "1",
          },
          "& .slick-dots button": {
            width: "12px",
            height: "12px",
          },
        }}
      >
        <Slider infinite={false} dots arrows={false}>
          {dataRef.current.files?.slice(0, 4).map((file, index) => (
            <React.Fragment key={index}>
              {file.data.image ? (
                <AspectRatio h="80">
                  <Image src={file.data.image.downloadUrl} />
                </AspectRatio>
              ) : file.data.video ? (
                <Box h="80" w="full">
                  <BunnyPlayer src={file.data.video.url} />
                </Box>
              ) : null}
            </React.Fragment>
          ))}
        </Slider>
      </Box>
      <Text
        fontSize="sm"
        lineHeight="5"
        color="#353B48"
        mt="8"
        whiteSpace="pre-wrap"
        wordBreak="break-word"
      >
        {dataRef.current.content}
      </Text>
    </Box>
  );
};
