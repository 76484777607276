import React from "react";

export const CheckSquareIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="32"
        height="32"
        rx="5.5"
        fill="#50CD89"
        fillOpacity="0.13"
        stroke="#50CD89"
      />
      <path
        d="M24.6466 10.4197C24.0876 9.85994 23.1799 9.86029 22.6202 10.4197L13.5001 19.5402L9.44654 15.4867C8.88681 14.927 7.97951 14.927 7.41979 15.4867C6.86007 16.0464 6.86007 16.9537 7.41979 17.5134L12.4865 22.5801C12.7662 22.8598 13.1329 23 13.4997 23C13.8664 23 14.2335 22.8602 14.5132 22.5801L24.6466 12.4464C25.2063 11.887 25.2063 10.9793 24.6466 10.4197Z"
        fill="#50CD89"
      />
    </svg>
  );
};
