import React from "react";
import { Box } from "@chakra-ui/react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const data = [
  { name: "Photo", value: 400 },
  { name: "Video", value: 300 },
  { name: "Text", value: 300 },
];

const COLORS = ["#57AAEC", "#98CBF3", "#C5E0F5"];

const renderCustomizedLabel = () => {
  return (
    <text
      x="50%"
      y="50%"
      fill="#353B48"
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize="14px"
      fontWeight="400"
    >
      <tspan x="50%" dy="-0.5em" color="black.active">
        213,2 B
      </tspan>
      <tspan x="50%" dy="1.4em" fontSize="12px" fontWeight="300">
        interaction
      </tspan>
    </text>
  );
};

export const CircularChart: React.FC = () => {
  return (
    <Box
      w="full"
      height="full"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={36}
            outerRadius={56}
            fill="#8884d8"
            dataKey="value"
            startAngle={90}
            endAngle={450}
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            {renderCustomizedLabel()}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};
