import { APISchemas, GET, POST } from "@/api";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  VStack,
  useDisclosure,
  useSteps,
  Text,
  HStack,
  useToast,
  Center,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { PlanCard } from "@/components/plan-card";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { SummaryCard } from "./components/summary-card";
import { OrganizationsIcon } from "@/assets/icons/organizations-icon";
import { TeamMembersIcon } from "@/assets/icons/team-members-icon";
import { ConnectedIcon } from "@/assets/icons/connected-icon";
import { parseISO } from "date-fns";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useTranslation } from "react-i18next";
import { CrossCircleIcon } from "@/assets/icons/cross-circle-icon";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import { PlanDowngradeAlert } from "./components/plan-downgrade-alert";

export interface MembershipPlansProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const MembershipPlans: FC<MembershipPlansProps> = () => {
  const navigate = useNavigate();
  const [specialOffer, setSpecialOffer] = useState<
    APISchemas["SpecialOffer"][] | undefined
  >();
  const { activeOrganization, user } = useAuthStore((state) => ({
    activeOrganization: state.activeOrganization,
    user: state.user,
  }));
  const toast = useToast();

  const { t } = useTranslation();

  const { data: Membership, refetch: membershipRefetch } = useQuery(
    "membership",
    async () => {
      if (!activeOrganization) return;

      const { data, error } = await GET(
        "/membership/{org_pk}/get-membership/",
        {
          params: {
            path: {
              org_pk: activeOrganization.id.toString(),
            },
          },
        }
      );
      if (error && error?.detail) {
        toast({
          status: "error",
          description: error?.detail,
        });
      }
      return data;
    }
  );

  const { data: Plans, refetch: planRefetch } = useQuery(
    "membership-plans",
    async () => {
      const { data, error } = await GET("/membership/get-plans/", {});
      setSpecialOffer(data?.special_offers);
      const formattedPlans: APISchemas["Plan"][] = [];

      data?.plan_list.forEach((obj) => {
        obj.plan_kind.forEach((plan) => {
          const newPlanType = { ...obj };
          newPlanType.plan_kind = [plan];

          formattedPlans.push(newPlanType);
        });
      });
      if (error && error?.detail) {
        toast({
          status: "error",
          description: error?.detail,
        });
      }
      return formattedPlans;
    },
    {
      select(formattedPlans) {
        return {
          monthly: formattedPlans?.filter(
            (plan: APISchemas["Plan"]) =>
              plan.plan_kind[0].plan_period.toLowerCase() === "monthly" ||
              plan.name.toLowerCase() === "free"
          ),
          yearly: formattedPlans?.filter(
            (plan: APISchemas["Plan"]) =>
              plan.plan_kind[0].plan_period.toLowerCase() === "yearly" ||
              plan.name.toLowerCase() === "free"
          ),
        };
      },
    }
  );

  const combinedRefetch = useCallback(() => {
    membershipRefetch();
    planRefetch;
  }, [membershipRefetch, planRefetch]);

  const [planType, setPlanType] = useState<string>("monthly");

  const [plan, setPlan] = useState<APISchemas["Plan"]>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const cancelRef = useRef(null);

  const checkPlan = useCallback(
    async (newPlan: APISchemas["Plan"] | undefined) => {
      if (!activeOrganization) return;
      if (!newPlan) return;
      const { response, error, data } = await POST(
        "/membership/{org_pk}/plan-check/",
        {
          params: {
            path: {
              org_pk: activeOrganization?.id.toString(),
            },
          },
          body: {
            plan_id: newPlan.id,
            plan_kind_id: newPlan.plan_kind[0].id,
          },
        }
      );

      if (response.status === 400) {
        return toast({
          status: "error",
          title: error?.detail,
        });
      }

      if (response.status === 206) {
        return toast({
          status: "success",
          title: data?.detail,
          description: `${t("membership-packages.refund-amount")} ${data?.refund_amount} ${data?.currency}`,
        });
      }
      if (
        response.status === 403 ||
        response.status === 422 ||
        response.status === 304
      ) {
        return toast({
          status: "warning",
          title: error?.detail,
        });
      }
      if (response.status === 402) {
        toast({
          status: "warning",
          title: error?.detail,
        });

        console.log({
          state: {
            newPlan,
            specialOffer: specialOffer?.find(
              (offer) => offer.plan === newPlan.id
            ),
          },
        });

        return navigate(`/membership/payment/${newPlan.id}`, {
          state: {
            newPlan,
            specialOffer: specialOffer?.find(
              (offer) => offer.plan === newPlan.id
            ),
          },
        });
      }

      if (response.status === 418) {
        return onOpen();
      }

      setTimeout(() => {
        combinedRefetch();
      }, 1000);

      if (response.ok) {
        return onClose();
      }
    },
    [activeOrganization, combinedRefetch, navigate, onClose, onOpen, toast]
  );

  return (
    <Box
      bg="white"
      mt="20px"
      mx="20px"
      p="20px"
      pb="0"
      h="full"
      borderRadius="6px"
      box-shadow="4px 4px 108px 0px rgba(0, 0, 0, 0.13)"
      mb="50px"
    >
      <Flex w="full" justifyContent="space-between" alignItems="center">
        <Text
          fontSize="24px"
          fontWeight="700"
          lineHeight="20px"
          color="black.active"
        >
          {t("membership-packages.membership-plans")}
        </Text>

        <IconButton
          cursor="pointer"
          onClick={() => {
            navigate(-1);
          }}
          variant="unstyled"
          size="none"
          aria-label="back-circle"
          icon={<CrossCircleIcon />}
        />
      </Flex>

      <Flex w="full" justifyContent="center">
        <Flex
          w="343px"
          h="full"
          bg="gray.soft"
          borderRadius="7px"
          p="2px"
          overflow="hidden"
          box-shadow=" 4px 4px 108px 0px rgba(0, 0, 0, 0.13)"
        >
          <Button
            cursor="pointer"
            onClick={() => setPlanType("monthly")}
            borderRadius="7px"
            variant="unstyled"
            w="50%"
            bg={planType === "monthly" ? "white" : "gray.soft"}
            fontSize="16px"
            fontWeight="600"
            letterSpacing="-0.08px"
            lineHeight="20px"
            color="blue.brand"
          >
            {t("membership-packages.monthly")}
          </Button>
          <Button
            cursor="pointer"
            onClick={() => setPlanType("yearly")}
            borderRadius="7px"
            bg={planType === "yearly" ? "white" : "gray.soft"}
            variant="unstyled"
            w="50%"
            fontSize="16px"
            fontWeight="600"
            letterSpacing="-0.08px"
            lineHeight="20px"
            color="blue.brand"
          >
            {t("membership-packages.yearly")}
          </Button>
        </Flex>
      </Flex>
      <Center>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: {
              autoHide: "scroll",
            },
          }}
          style={{
            padding: "5px 25px",
          }}
        >
          <HStack gap="12" mt="48px" mb="20px">
            {(planType === "monthly" ? Plans?.monthly : Plans?.yearly)
              ?.sort(
                (a, b) =>
                  (a.plan_kind[0].price ?? 0) - (b.plan_kind[0].price ?? 0)
              )
              ?.map((plan) => {
                const isDowngrade =
                  (plan.plan_kind[0].price ?? 0) <
                  (Membership?.plan_kind.price ?? 0);
                const isUpgrade =
                  (plan.plan_kind[0].price ?? 0) >
                  (Membership?.plan_kind.price ?? 0);
                const isCurrentPlan =
                  plan.plan_kind[0].id === Membership?.plan_kind.id;
                const isSamePlan =
                  plan.name.toLowerCase() ===
                    Membership?.plan_kind.plan.name.toLowerCase() &&
                  plan.plan_kind[0].plan_period.toLowerCase() ===
                    Membership?.plan_kind.plan_period.toLowerCase();
                const newOffer = specialOffer?.find((offer) => {
                  return (
                    offer.plan_kind === plan.plan_kind[0].id &&
                    offer.plan === plan.plan_kind[0].plan
                  );
                });
                return (
                  <PlanCard
                    newOffer={newOffer}
                    key={plan.id}
                    data={plan}
                    isDisabled={activeOrganization?.owner !== user?.id}
                    isUpgrade={isUpgrade}
                    isDowngrade={isDowngrade && !isSamePlan}
                    isCurrentPlan={isCurrentPlan && isSamePlan}
                    onClick={() => {
                      setPlan(plan);
                      (Membership?.plan_kind.price ?? 0) >
                      (plan.plan_kind[0].price ?? 0)
                        ? onAlertOpen()
                        : checkPlan(plan);
                    }}
                  />
                );
              })}
          </HStack>
        </OverlayScrollbarsComponent>
      </Center>
      <SelfUpdateModal
        isOpen={isOpen}
        onClose={onClose}
        plan={plan}
        onUpdateEnd={() => {
          onClose();
          checkPlan(plan);
        }}
      />

      {plan && (
        <PlanDowngradeAlert
          membership={Membership}
          isAlertOpen={isAlertOpen}
          cancelRef={cancelRef}
          onAlertClose={onAlertClose}
          plan={plan}
          onClick={() => {
            checkPlan(plan);
            onAlertClose();
          }}
        />
      )}
    </Box>
  );
};

interface SelfUpdateModalProps {
  isOpen: boolean;
  plan?: APISchemas["Plan"];
  onClose: () => void;
  onUpdateEnd?: () => void;
}

const SelfUpdateModal: FC<SelfUpdateModalProps> = ({
  isOpen,
  onClose,
  onUpdateEnd,
  plan,
}) => {
  const toast = useToast();
  const activeOrganization = useAuthStore((state) => state.activeOrganization);
  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 3,
    count: 3,
  });
  const { t } = useTranslation();
  const [organizationTrunk, setOrganizationTrunk] = useState<
    Array<APISchemas["PureOrganization"]>
  >([]);
  const [channelsTrunk, setChannelsTrunk] = useState<
    Array<APISchemas["Channel"]>
  >([]);
  const [userTrunk, setUserTrunk] = useState<
    Array<
      APISchemas["UserDisplayData"] & {
        status: "active" | "inactive" | undefined;
      }
    >
  >([]);

  useEffect(() => {
    setActiveStep(1);
  }, [plan, setActiveStep]);

  const { data: Membership } = useQuery(
    ["membership", activeOrganization?.id],
    async () => {
      if (!activeOrganization) return;

      const { data } = await GET("/membership/{org_pk}/get-membership/", {
        params: {
          path: {
            org_pk: activeOrganization.id.toString(),
          },
        },
      });

      return {
        ...data,
        start_date: data?.start_date ? parseISO(data?.start_date) : undefined,
        end_date: data?.end_date ? parseISO(data?.end_date) : undefined,
      };
    }
  );

  const updateOrganization = useCallback(
    (organization: APISchemas["PureOrganization"], status: boolean) => {
      setOrganizationTrunk((prev) => {
        const orgIndex = prev.findIndex((org) => org.id === organization.id);

        organization.status = status ? "active" : "inactive";

        if (orgIndex === -1) {
          prev.push(organization);
          return [...prev];
        }

        prev[orgIndex] = organization;

        return [...prev];
      });
    },
    []
  );

  const updateChannel = useCallback(
    (channel: APISchemas["Channel"], status: boolean) => {
      setChannelsTrunk((prev) => {
        const channelIndex = prev.findIndex((org) => org.id === channel.id);

        channel.status = status ? "active" : "inactive";

        if (channelIndex === -1) {
          prev.push(channel);
          return [...prev];
        }

        prev[channelIndex] = channel;

        return [...prev];
      });
    },
    []
  );

  const updateTeamMember = useCallback(
    (
      member: APISchemas["UserDisplayData"] & {
        status: "active" | "inactive" | undefined;
      },
      status: boolean
    ) => {
      setUserTrunk((prev) => {
        const memberIndex = prev.findIndex((org) => org.id === member.id);

        member.status = status ? "active" : "inactive";

        if (memberIndex === -1) {
          prev.push(member);
          return [...prev];
        }

        prev[memberIndex] = member;

        return [...prev];
      });
    },
    []
  );

  const bulkUpdate = useCallback(async () => {
    if (!activeOrganization) return;

    POST("/payment/bulk-update-membership/", {
      body: {
        organizations: organizationTrunk.map((o) => ({
          id: o.id,
          status: o.status,
        })),
        channels: channelsTrunk.map((o) => ({
          id: o.id,
          status: o.status,
        })),
        members: userTrunk.map((o) => ({
          id: o.id,
          status: o.status,
        })),
      },
    }).then(({ response }) => {
      if (response.ok) {
        onUpdateEnd?.();
      }
    });
  }, [
    activeOrganization,
    channelsTrunk,
    onUpdateEnd,
    organizationTrunk,
    userTrunk,
  ]);

  useEffect(() => {
    GET("/payment/get-info-by-owner/", {}).then(({ data }) => {
      if (!data) return;

      setOrganizationTrunk(data.organizations);
      setChannelsTrunk(data.channels);
      setUserTrunk(
        data.members.map((m) => ({
          ...m,
          status: "active",
        }))
      );
    });
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent bg="white.bg">
        <ModalHeader>{t("alert.downgrade-modal.change-plan")}/</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {activeStep === 1 && (
            <HStack>
              <Box
                w="full"
                pr="6px"
                borderRight="2px solid"
                borderColor="#F2F2F2"
              >
                <SummaryCard
                  icon={<OrganizationsIcon width="57px" height="57px" />}
                  text="Organizations Selection"
                  firstData={
                    organizationTrunk.filter((o) => o.status === "active")
                      .length ?? Membership?.organizations
                  }
                  secondData={plan?.organization_limit}
                />
                <OverlayScrollbarsComponent
                  options={{
                    scrollbars: {
                      autoHide: "scroll",
                    },
                  }}
                  style={{
                    marginTop: "20px",
                    height: "300px",
                  }}
                >
                  <VStack>
                    {organizationTrunk?.map((org) => (
                      <Flex
                        key={org.id}
                        justifyContent="space-between"
                        alignItems="center"
                        w="full"
                        px="3"
                      >
                        <Flex gap="24px" alignItems="center">
                          <Box
                            bg="blue.shiny"
                            w="43px"
                            h="43px"
                            color="white"
                            textTransform="uppercase"
                            fontSize="32px"
                            rounded="full"
                            textAlign="center"
                            lineHeight="43px"
                          >
                            {org.name.trim()[0]}
                          </Box>
                          <Text
                            color="black.dark"
                            fontSize="20px"
                            fontWeight="500"
                            textTransform="capitalize"
                          >
                            {org.name}
                          </Text>
                        </Flex>
                        <Switch
                          size="lg"
                          sx={{
                            "&>span": {
                              bg:
                                org.status === "active"
                                  ? "blue.shiny"
                                  : undefined,
                            },
                          }}
                          isChecked={org.status === "active"}
                          onChange={(e) =>
                            updateOrganization(org, e.target.checked)
                          }
                        />
                      </Flex>
                    ))}
                  </VStack>
                </OverlayScrollbarsComponent>
              </Box>
              <Flex flexDir="row" alignSelf="stretch">
                <ConnectedIcon width="57px" height="57px" />
                <TeamMembersIcon width="57px" height="57px" />
              </Flex>
            </HStack>
          )}
          {activeStep === 2 && (
            <HStack alignItems="flex-start">
              <OrganizationsIcon width="57px" height="57px" />
              <Box
                w="full"
                px="6px"
                borderRight="2px solid"
                borderLeft="2px solid"
                borderColor="#F2F2F2"
              >
                <Box>
                  <SummaryCard
                    icon={<ConnectedIcon width="57px" height="57px" />}
                    text="Connected Channels"
                    firstData={
                      channelsTrunk.filter((o) => o.status === "active")
                        .length ?? Membership?.connected_channels
                    }
                    secondData={plan?.channel_limit}
                  />
                  <OverlayScrollbarsComponent
                    options={{
                      scrollbars: {
                        autoHide: "scroll",
                      },
                    }}
                    style={{
                      marginTop: "20px",
                      height: "300px",
                    }}
                  >
                    <VStack>
                      {channelsTrunk.map((channel) => (
                        <Flex
                          key={channel.id}
                          justifyContent="space-between"
                          alignItems="center"
                          w="full"
                          px="3"
                        >
                          <Flex gap="24px" alignItems="center">
                            <AvatarWithBadge
                              //opacity={!active ? "0.5" : "1"}
                              status={status}
                              picture={channel.picture ?? undefined}
                              channel={channel.channel_type}
                              channel_id={channel.id.toString()}
                              w="43px"
                              h="43px"
                              key={channel.id}
                              tooltip={false}
                              email={
                                channel.username
                                  ? `@${channel.username}`
                                  : channel.name
                              }
                            />
                            <Box>
                              <Text
                                color="black.dark"
                                fontSize="20px"
                                fontWeight="500"
                              >
                                {channel.name}
                              </Text>
                              <Text
                                color="gray.passive"
                                fontSize="16px"
                                fontWeight="500"
                                textTransform="capitalize"
                              >
                                {channel.channel_type.replace("_", " ")}
                              </Text>
                            </Box>
                          </Flex>
                          <Switch
                            size="lg"
                            sx={{
                              "&>span": {
                                bg:
                                  channel.status === "active"
                                    ? "blue.shiny"
                                    : undefined,
                              },
                            }}
                            isChecked={channel.status === "active"}
                            onChange={(e) =>
                              updateChannel(channel, e.target.checked)
                            }
                          />
                        </Flex>
                      ))}
                    </VStack>
                  </OverlayScrollbarsComponent>
                </Box>
              </Box>
              <TeamMembersIcon width="57px" height="57px" />
            </HStack>
          )}

          {activeStep === 3 && (
            <HStack alignItems="flex-start">
              <VStack>
                <OrganizationsIcon width="57px" height="57px" />
                <TeamMembersIcon width="57px" height="57px" />
              </VStack>
              <Box
                w="full"
                px="6px"
                borderLeft="2px solid"
                borderColor="#F2F2F2"
              >
                <Box>
                  <SummaryCard
                    icon={<TeamMembersIcon width="57px" height="57px" />}
                    text="Team Members"
                    firstData={
                      userTrunk.filter((o) => o.status === "active").length + 1
                    }
                    secondData={plan?.user_limit}
                  />
                  <OverlayScrollbarsComponent
                    options={{
                      scrollbars: {
                        autoHide: "scroll",
                      },
                    }}
                    style={{
                      marginTop: "20px",
                      height: "300px",
                    }}
                  >
                    <VStack>
                      {userTrunk.map((member) => (
                        <>
                          <Flex
                            key={member.id}
                            justifyContent="space-between"
                            alignItems="center"
                            w="full"
                            px="3"
                          >
                            <Flex gap="24px" alignItems="center">
                              <Avatar
                                w="43px"
                                h="43px"
                                src={member.picture ?? undefined}
                              />
                              <Box>
                                <Text
                                  color="black.dark"
                                  fontSize="20px"
                                  fontWeight="500"
                                >
                                  {member.email}
                                </Text>
                                {/* <Text
                                  color="gray.passive"
                                  fontSize="16px"
                                  fontWeight="500"
                                >
                                  
                                  {organizationTrunk.find(
                                    (o) =>
                                      o.id === member.roles?.[0].organization
                                  )?.name ?? ""}
                                </Text> */}
                              </Box>
                            </Flex>
                            <Switch
                              size="lg"
                              sx={{
                                "&>span": {
                                  bg:
                                    member.status === "active"
                                      ? "blue.shiny"
                                      : undefined,
                                },
                              }}
                              isChecked={member.status === "active"}
                              onChange={(e) =>
                                updateTeamMember(member, e.target.checked)
                              }
                            />
                          </Flex>
                          <Divider />
                        </>
                      ))}
                    </VStack>
                  </OverlayScrollbarsComponent>
                </Box>
              </Box>
            </HStack>
          )}
        </ModalBody>

        <ModalFooter w="full">
          <Flex w="full" h="60px" gap="20px" justifyContent="center">
            {activeStep !== 1 && (
              <Button
                w="334px"
                bg="blue.shiny"
                color="white"
                py="16px"
                variant="unstyled"
                h="60px"
                textAlign="center"
                onClick={goToPrevious}
              >
                {t("back")}
              </Button>
            )}
            <Button
              w="334px"
              bg="blue.shiny"
              color="white"
              py="16px"
              variant="unstyled"
              h="60px"
              textAlign="center"
              isDisabled={
                activeStep === 1
                  ? (plan?.organization_limit ?? 0) <
                    organizationTrunk.filter((o) => o.status === "active")
                      .length
                  : activeStep === 2
                    ? (plan?.channel_limit ?? 0) <
                      channelsTrunk.filter((o) => o.status === "active").length
                    : activeStep === 3
                      ? (plan?.user_limit ?? 0) <
                        userTrunk.filter((o) => o.status === "active").length +
                          1
                      : false
              }
              onClick={() => {
                if (organizationTrunk.every((o) => o.status === "inactive")) {
                  toast({
                    description: t(
                      "errors.please-select-at-least-one-organization"
                    ),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                  return;
                }

                if (activeStep !== 3) {
                  return goToNext();
                }

                bulkUpdate();
              }}
            >
              {t("continue")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
