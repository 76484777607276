import { Flex, Text, Icon } from "@chakra-ui/react";
import { FC } from "react";

interface InteractionInfoLineProps {
  text: string;
  icon: JSX.Element;
  data: string;
}

export const InteractionInfoLine: FC<InteractionInfoLineProps> = ({
  text,
  icon,
  data,
}) => {
  return (
    <Flex my="4">
      <Icon justifyContent="center" alignItems="center" mt="1px">
        {icon}
      </Icon>
      <Text
        fontSize="0.9vw"
        fontWeight="400"
        whiteSpace="nowrap"
        lineHeight="14px"
        mx="1"
      >
        {data}
      </Text>
      <Text
        fontSize="0.8vw"
        fontWeight="400"
        whiteSpace="nowrap"
        lineHeight="14px"
      >
        {text}
      </Text>
    </Flex>
  );
};
