import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Input,
  VStack,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { EyeIcon } from "@/assets/icons/eye-icon";
import { EyeIconInvisible } from "@/assets/icons/eye-icon-invisible";
import { SubmitHandler, useForm } from "react-hook-form";
import { setPasswordSchema } from "@/pages/auth/Forgot-Password";
import { zodResolver } from "@hookform/resolvers/zod";
import { POST } from "@/api";
import { useAuthStore } from "@/context/auth-store/auth-store";

export interface Step3Props {
  isOpen: boolean;
  activeStep: number;
  verificationCode: string;

  onClose: () => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}

export const Step3: React.FC<Step3Props> = ({
  isOpen,
  verificationCode,
  onClose,
  goToPreviousStep,
  goToNextStep,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { user } = useAuthStore((a) => ({
    user: a.user,
  }));

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<setPasswordSchema>({
    resolver: zodResolver(setPasswordSchema),
    defaultValues: {
      verification_code: verificationCode,
    },
  });

  const [passwordVisible, setPasswordVisible] = useState<{
    old_pass: boolean;
    new_pass: boolean;
    confirm_pass: boolean;
  }>({
    old_pass: false,
    new_pass: false,
    confirm_pass: false,
  });

  const setPassword: SubmitHandler<setPasswordSchema> = useCallback(
    async ({ password, verification_code }) => {
      const email = user?.email;
      if (!email) {
        toast({
          status: "error",
          description: t("errors.unexpected_error"),
          isClosable: true,
          duration: 3000,
        });

        return;
      }

      const { response, error } = await POST("/auth/set-password/", {
        body: {
          email,
          verification_code,
          password,
        },
      });

      if (response.ok) {
        toast({
          status: "success",
          title: t("alert.success.password-changed-successfully"),
        });
        goToNextStep();
        return;
      }

      if (error) {
        if (error.issues) {
          error.issues.forEach((issue) => {
            if (!issue.path) return;
            setError(issue.path as any, {
              message: issue.message,
            });
          });
          return;
        }
        toast({
          status: "error",
          description: error.detail ?? t("errors.an-error-occurred"),
        });

        return;
      }
    },
    [toast, setError, t]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        onSubmit={handleSubmit(setPassword, console.log)}
        as="form"
        maxW="704px"
        px="16px"
        py="20px"
        border="1px solid"
        borderColor="gray.athens"
      >
        <ModalCloseButton color="blue.shiny" bg="gray.athens" rounded="full" />
        <ModalHeader pb="0">
          {" "}
          {t("page.settings.profile.change-password")}{" "}
        </ModalHeader>
        <ModalBody pb="24px">
          <VStack alignItems="left">
            <Text pb="24px" fontSize="14px" fontWeight="400">
              {t("page.settings.profile.enter-password")}
            </Text>
            <FormControl
              borderRadius="6px"
              width="full"
              pb="16px"
              isInvalid={!!errors.password?.message}
            >
              <FormLabel
                pos="absolute"
                top="-2px"
                fontSize="16px"
                zIndex="9"
                ml="2.5"
                px="4"
                fontWeight="400"
                lineHeight="1"
                pr="8"
                bg="white.normal"
              >
                {t("page.settings.profile.new-password")}
              </FormLabel>
              <InputGroup mb="14px">
                <Input
                  transition="borderColor 0.6s ease"
                  border="1px solid"
                  borderColor="blue.shiny"
                  maxLength={20}
                  w="full"
                  mt="2"
                  p="4"
                  color="gray.passive"
                  variant="unstyled"
                  _placeholder={{
                    color: "gray.passive",
                  }}
                  {...register("password")}
                  type={passwordVisible.new_pass ? "text" : "password"}
                />
                <InputRightElement
                  justifyContent="center"
                  alignItems="center"
                  h="full"
                  mr="3"
                  mt="1"
                >
                  <IconButton
                    cursor="pointer"
                    size="xs"
                    variant="unstyled"
                    aria-label="password"
                    onClick={() =>
                      setPasswordVisible((prevVisibility) => ({
                        ...prevVisibility,
                        new_pass: !prevVisibility.new_pass,
                      }))
                    }
                    icon={
                      !passwordVisible.new_pass ? (
                        <EyeIcon />
                      ) : (
                        <EyeIconInvisible />
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage pos="absolute" top="60px">
                {errors.password?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              borderRadius="6px"
              width="full"
              isInvalid={!!errors.confirmPassword?.message}
            >
              <FormLabel
                pos="absolute"
                top="-2px"
                fontSize="16px"
                zIndex="9"
                ml="2.5"
                px="4"
                fontWeight="400"
                lineHeight="1"
                pr="8"
                bg="white.normal"
              >
                {t("input.confirm-new-password")}
              </FormLabel>
              <InputGroup>
                <Input
                  transition="borderColor 0.6s ease"
                  border="1px solid"
                  borderColor="blue.shiny"
                  maxLength={20}
                  w="full"
                  p="4"
                  color="gray.passive"
                  variant="unstyled"
                  _placeholder={{
                    color: "gray.passive",
                  }}
                  {...register("confirmPassword")}
                  type={passwordVisible.confirm_pass ? "text" : "password"}
                />
                <InputRightElement
                  justifyContent="center"
                  alignItems="center"
                  h="full"
                  mr="3"
                  mt="1"
                >
                  <IconButton
                    cursor="pointer"
                    size="xs"
                    variant="unstyled"
                    aria-label="password"
                    onClick={() =>
                      setPasswordVisible((prevVisibility) => ({
                        ...prevVisibility,
                        confirm_pass: !prevVisibility.confirm_pass,
                      }))
                    }
                    icon={
                      !passwordVisible.confirm_pass ? (
                        <EyeIcon />
                      ) : (
                        <EyeIconInvisible />
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>

              <FormErrorMessage pos="absolute" top="52px">
                {errors.confirmPassword?.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter pt="0">
          <Button
            bg="white.normal"
            color="blue.shiny"
            border="1px solid"
            borderColor="blue.shiny"
            onClick={goToPreviousStep}
            mr="16px"
            px="20px"
            py="14px"
            fontSize="14px"
            w="164px"
          >
            {t("page.settings.profile.back")}
          </Button>
          <Button
            color="white.normal"
            type="submit"
            bg="blue.shiny"
            px="20px"
            py="14px"
            fontSize="14px"
            w="164px"
          >
            {t("page.settings.profile.submit")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
