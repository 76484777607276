import { Box, Flex, Text } from "@chakra-ui/react";

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CreditCard } from "../_payment-select";

import { WirelessBigIcon } from "@/assets/icons/wireless-big-icon";
import { CardChipIcon } from "@/assets/icons/card-chip-icon";

export const AddCardPreview: FC<{ card?: CreditCard }> = ({ card }) => {
  const { t } = useTranslation();
  return (
    <Box
      w="full"
      h="392px"
      border="1.758px solid"
      borderColor="gray.smoke"
      boxShadow="14.647px 17.576px 29.294px 0px rgba(0, 0, 0, 0.05)"
      backdropFilter="blur(10.252809524536133px)"
      bg="linear-gradient(113deg, rgba(255, 255, 255, 0.51) 3.51%, rgba(255, 255, 255, 0.00) 111.71%)"
      rounded="3xl"
      color="black.dark"
      fontWeight="600"
    >
      <Flex
        justifyContent="space-between"
        mt="50px"
        ml="50px"
        alignItems="center"
      >
        <CardChipIcon />
        <WirelessBigIcon />
      </Flex>
      <Box ml="50px" mt="27px" h="130px">
        <Text
          fontSize="16px"
          letterSpacing="wide"
          opacity="0.9"
          mb="28px"
          lineHeight="8px"
        >
          {t("page.membership.Card_Number")}
        </Text>
        <Text
          fontSize="26px"
          letterSpacing="wide"
          opacity="0.9"
          lineHeight="14px"
        >
          {card?.cardNumber
            ?.replace(/([0-9*]{4})/g, (a: string) => a + " ")
            .trim()}
        </Text>
      </Box>
      <Flex ml="50px" alignItems="center" justifyContent="space-between">
        <Text
          fontSize="26px"
          letterSpacing="wide"
          opacity="0.9"
          w="20ch"
          whiteSpace="nowrap"
          textTransform="uppercase"
        >
          {card?.name}
        </Text>
        <Box>
          <Text
            fontSize="16px"
            opacity="0.9"
            lineHeight="24px"
            mb="8px"
            mr="50px"
          >
            {t("page.membership.valid_thru")}
          </Text>
          <Text
            fontSize="20px"
            letterSpacing="wide"
            opacity="0.9"
            lineHeight="14px"
          >
            {card?.expiryMonth || "**"}/{card?.expiryYear || "**"}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
