import {
  Flex,
  Text,
  Divider,
  Card,
  Stack,
  CardBody,
  Tooltip,
  Button,
  Avatar,
  CardFooter,
  Box,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { AvatarWithBadge } from "../avatar/avatar-with-badge";
import { APISchemas } from "@/api";
import { parseISO } from "date-fns";
import { SmartImage } from "../smart-image";
import { useTranslation } from "react-i18next";
import { i18nformat } from "@/utils/misc";
import { useChannel } from "@/hooks/use-channels";

interface Props {
  compose: APISchemas["Compose"];
}

export const PostItem: React.FC<Props> = ({ compose }) => {
  const formatDatetime = useCallback((time: string): string => {
    const parsedDate = parseISO(time);
    const formattedDate = i18nformat(parsedDate, "MMM d, yyyy h:mm a");
    return formattedDate;
  }, []);
  const { t } = useTranslation();

  const { data: Channels } = useChannel();

  const mediaType = compose.media.at(0)?.type;

  const imageUrl = compose?.media[0]?.url;
  const plannedDate =
    compose.scheduled_at && formatDatetime(compose.scheduled_at);
  const description = compose.body;

  const [postList, draft] = useMemo(() => {
    const facebook = compose.facebook_posts?.map((item) => item.channel);
    const linkedin = compose.linkedin_posts?.map((item) => item.channel);
    const twitter = compose.twitter_posts?.map((item) => item.channel);
    const instagram = compose.instagram_posts?.map((item) => item.channel);
    const telegram = compose.telegram_posts?.map((item) => item.channel);
    const youtube = compose.youtube_posts?.map((item) => item.channel);
    const tiktok = compose.tiktok_posts?.map((item) => item.channel);

    const draft =
      compose.facebook_posts.every((p) => p.status === "draft") &&
      compose.instagram_posts.every((p) => p.status === "draft") &&
      compose.linkedin_posts.every((p) => p.status === "draft") &&
      compose.tiktok_posts.every((p) => p.status === "draft") &&
      compose.youtube_posts.every((p) => p.status === "draft") &&
      compose.telegram_posts.every((p) => p.status === "draft") &&
      compose.twitter_posts.every((p) => p.status === "draft");

    return [
      facebook
        .concat(linkedin)
        .concat(twitter)
        .concat(instagram)
        .concat(telegram)
        .concat(tiktok)
        .concat(youtube),
      draft,
    ];
  }, [compose]);

  const avatars = Channels?.filter((channel) => postList.includes(channel.id));
  const maxAvatars = 5;
  const avatarsToDisplay = avatars?.slice(0, maxAvatars);
  const hiddenCount = Math.max((avatars?.length ?? 0) - maxAvatars, 0);
  const hiddenAvatars = avatars?.slice(maxAvatars, avatars.length);
  return (
    <Card
      rounded="lg"
      bg="white"
      h="380px"
      bgColor="green"
      overflow="hidden"
      border={draft ? "1px solid #FBC531" : undefined}
    >
      <CardBody py="2" px="2" rounded="lg" h="280px" position="relative">
        {/* {draft && (
          <Flex
            pos="absolute"
            top="2"
            right="2"
            zIndex="99"
            width="60px"
            height="90px"
          >
            <DraftIcon />
          </Flex>
        )} */}
        <Box overflow="hidden" rounded="lg">
          {mediaType == "image" && imageUrl && (
            <SmartImage imageUrl={imageUrl} h="155px" />
          )}
          {mediaType == "video" && imageUrl && (
            <SmartImage
              fallbackUrl={compose?.media[0]?.download_url.replace(
                "original",
                "thumbnail.jpg"
              )}
              imageUrl={compose?.media[0]?.download_url.replace(
                "original",
                "preview.webp"
              )}
              h="155px"
            />
          )}
        </Box>

        <Stack mt={imageUrl ? "3" : "0"} h="full">
          {!imageUrl && description && (
            <Box
              pb="10"
              px="3"
              pt="3"
              bg="#F7F8FA"
              borderRadius="8px"
              borderTopLeftRadius="0px"
              color="black.active"
            >
              <Text
                fontSize="15px"
                fontWeight="400"
                noOfLines={6}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {description}
              </Text>
            </Box>
          )}

          {imageUrl && (
            <Text
              fontSize="15px"
              fontWeight="400"
              color="black.active"
              noOfLines={4}
            >
              {description}
            </Text>
          )}
        </Stack>
        <Divider
          color="rgba(52, 90, 121, 0.13)"
          w="full"
          position="absolute"
          bottom="2px"
        />
      </CardBody>
      <CardFooter h="130px" py="2px" w="full" mb="4">
        <Stack>
          <Text color="rgba(53, 59, 72, 0.33)" fontSize="12px" fontWeight="400">
            {t("platforms")}
          </Text>

          <Flex>
            {avatarsToDisplay?.map((avatar) => (
              <Button variant="unstyled" cursor="pointer" key={avatar.id}>
                <AvatarWithBadge
                  badgeSize="20px"
                  picture={avatar.picture ?? ""}
                  channel={avatar.channel_type}
                  channel_id={avatar.id.toString()}
                  w="34px"
                  h="34px"
                  key={avatar.id}
                  tooltip={true}
                  email={avatar.username ? `@${avatar.username}` : avatar.name}
                />
              </Button>
            ))}
            {hiddenCount > 0 && (
              <Tooltip
                shadow="0 0 10px rgba(0, 0, 0, 0.2)"
                borderRadius="4px"
                bg="white"
                color="black"
                p="4px"
                label={
                  <ul>
                    {hiddenAvatars?.map((avatar) => (
                      <React.Fragment key={avatar.id}>
                        <Text fontSize="12px">
                          {avatar.username
                            ? `@${avatar.username}`
                            : avatar.name}
                        </Text>
                      </React.Fragment>
                    ))}
                  </ul>
                }
              >
                <Button cursor="pointer" variant="unstyled">
                  <Avatar
                    w="35px"
                    h="35px"
                    fontWeight="600"
                    color="#487EB0"
                    shadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    bg="white.bg"
                    border="1px solid #487EB0"
                    name={"+ " + hiddenCount}
                  />
                </Button>
              </Tooltip>
            )}
          </Flex>

          <Text color="rgba(53, 59, 72, 0.33)" fontSize="12px" fontWeight="400">
            {t("planned-date")}
          </Text>
          <Text color="black.active" fontSize="14px" fontWeight="600">
            {plannedDate}
          </Text>
        </Stack>
      </CardFooter>
    </Card>
  );
};
