import { ChatbotIcon } from "@/assets/icons/chatbot-icon";
import { ClearChatIcon } from "@/assets/icons/clear-chat-icon";
import { SendMessageIcon } from "@/assets/icons/send-message-icon";
import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface Message {
  text: string;
  sender: "user" | "bot";
}

type FAQ = {
  title: string;
  question: string;
  answer: string;
};

interface AsistanProps {
  faqList: FAQ[];
}

export const Asistan: React.FC<AsistanProps> = ({ faqList }) => {
  const { t } = useTranslation();
  const user = useAuthStore((state) => state.user);

  const [inputValue, setInputValue] = useState<string>("");

  const [messages, setMessages] = useState<Message[]>([
    {
      text: t("page.help-center.chatbot"),
      sender: "bot",
    },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = useCallback(
    async (message: string) => {
      if (!message) return;

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: message, sender: "user" },
      ]);

      setIsLoading(true);
      try {
        const response = await fetch(
          "https://assistant.monolog.workers.dev/query",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token gdh-special-token",
            },
            body: JSON.stringify({
              query: message,
            }),
          }
        );

        const responseData = await response.text();

        const botMessage: Message = { text: responseData, sender: "bot" };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } catch (error) {
        console.error("Error fetching data:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: t("page.help-center.error-message"), sender: "bot" },
        ]);
      } finally {
        setIsLoading(false);
      }
    },
    [t]
  );

  const clearChat = useCallback(() => {
    setMessages([
      {
        text: t("page.help-center.chatbot"),
        sender: "bot",
      },
    ]);
  }, [t]);

  return (
    <Flex w="full" columnGap="12px" h="80vh" bg="#FFF">
      <Box
        w="full"
        borderRadius="3px"
        px="24px"
        display="flex"
        flexDir="column"
      >
        <Flex
          w="full"
          h="90px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <ChatbotIcon />
            <Text fontSize="16px" ml="16px" fontWeight="600">
              Posteffect Chatbot
            </Text>
          </Flex>

          <Flex alignItems="center">
            <IconButton
              aria-label="Clear Chat"
              icon={<ClearChatIcon />}
              variant="unstyled"
              onClick={clearChat}
            />
          </Flex>
        </Flex>
        <Divider borderColor="gray.athens" mx="auto" />
        <Box w="full" h="full" overflowY="auto" flex="1">
          {(isLoading
            ? [
                ...messages,
                {
                  text: "...",
                  sender: "bot",
                },
              ]
            : messages
          ).map((message, index) => (
            <Flex
              m="10px"
              key={index}
              justifyContent={
                message.sender === "bot" ? "flex-start" : "flex-end"
              }
            >
              {message.sender === "bot" && <ChatbotIcon />}
              <Box
                maxW="50%"
                bg="gray.athens"
                borderRadius={
                  message.sender === "bot"
                    ? "0 10px 10px 10px"
                    : "10px 0 10px 10px"
                }
                my="18px"
                mx="12px"
                pos="relative"
              >
                <Text
                  fontSize="14px"
                  p="22px"
                  whiteSpace="pre-wrap"
                  fontWeight="500"
                >
                  {message.text}
                </Text>
              </Box>
              {/* {message.sender === "bot" && (
                <Flex ml="11px" alignItems="center">
                  <FaqLikeIcon />
                  <Box ml="11px">
                    <FaqDislikeIcon />
                  </Box>
                </Flex>
              )} */}

              {message.sender === "user" && (
                <Avatar
                  w="10"
                  h="10"
                  src={user?.picture ?? undefined}
                  name={`${user?.first_name ?? ""} ${user?.last_name ?? ""}`}
                />
              )}
            </Flex>
          ))}

          <Box ref={messagesEndRef} />
        </Box>

        <Flex
          w="100%"
          h="58px"
          my="30px"
          justifyContent="center"
          overflowX="scroll"
        >
          {faqList.slice(0, 5).map((faq, index) => (
            <Box mx="10px">
              <Button
                w="full"
                px="16px"
                key={index}
                h="full"
                bg="gray.bg"
                borderRadius="8px"
                fontSize="14px"
                fontWeight="400"
                onClick={() => sendMessage(faq.question)}
              >
                {faq.question}
              </Button>
            </Box>
          ))}
        </Flex>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage(inputValue);
            setInputValue("");
          }}
        >
          <InputGroup
            mb="24px"
            w="70%"
            mx="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Input
              h="56px"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              bg="gray.bg"
              placeholder={t("page.help-center.type-message")}
              _placeholder={{ fontSize: "14px", color: "black.dark" }}
              borderRadius="16px"
              fontSize="14px"
              border="none"
              color="black.dark"
            />
            <InputRightElement
              h="full"
              children={
                <IconButton
                  aria-label="send message"
                  icon={<SendMessageIcon />}
                  variant="unstyled"
                  onClick={() => {
                    sendMessage(inputValue);
                    setInputValue("");
                  }}
                />
              }
            />
          </InputGroup>
        </form>
      </Box>
    </Flex>
  );
};
