import {
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { PlusStyledIcon } from "@/assets/icons/plus-styled-icon";

interface TagInputProps {
  tagInput: string;
  setTagInput: (value: string) => void;
  setAddedAccounts: (tagInput: string) => void;
}

export const TagInputGroup: FC<TagInputProps> = ({
  tagInput,
  setTagInput,
  setAddedAccounts,
}) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <InputGroup alignItems="center">
        <Input
          value={tagInput}
          onChange={(e) => setTagInput(e.target.value)}
          p="2"
          fontSize="14px"
          borderRadius="3px"
          _placeholder={{
            color: "gray.smoke",
            fontSize: "12px",
            fontWeight: "500",
          }}
          placeholder={t("tag-user")}
          bgColor="white"
          color="black.active"
          variant="unstyled"
          h="7"
          fontWeight="500"
        />
        <InputRightElement h="7" alignItems="center" justifyContent="center">
          <IconButton
            aria-label="add"
            icon={<PlusStyledIcon width="18px" height="18px" />}
            variant="unstyled"
            _hover={{ opacity: 0.8 }}
            size="none"
            onClick={() => {
              if (tagInput.length === 0) return;
              setTagInput("");
              setAddedAccounts(tagInput);
            }}
          />
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};
