import { POST } from "@/api";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { Center, Spinner, useToast } from "@chakra-ui/react";
import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

export const LoginGoogle: FC = () => {
  const [searchParams] = useSearchParams();

  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const postRef = useRef<boolean>(false);

  const code = searchParams.get("code");
  if (!code) {
    toast({
      status: "error",
      title: t("errors.unexpected_error"),
      duration: 3000,
      isClosable: true,
    });

    return <Navigate to="/auth/login" replace />;
  }

  if (!postRef.current && toast && code && navigate && t) {
    postRef.current = true;
    POST("/auth/google/callback/", {
      body: {
        code,
      },
    }).then(async ({ data, error }) => {
      if (error?.detail) {
        toast({
          status: "error",
          title: error.detail,
          duration: 3000,
          isClosable: true,
        });

        navigate("/auth/login");

        return;
      }

      if (error?.issues) {
        error.issues.forEach((issue) => {
          if (!issue.path) return;
          toast({
            status: "error",
            title: issue.message,
            duration: 3000,
            isClosable: true,
          });
        });

        navigate("/auth/login");

        return;
      }

      if (!data) {
        toast({
          status: "error",
          title: t("errors.unexpected_error"),
          duration: 3000,
          isClosable: true,
        });

        navigate("/auth/login");

        return;
      }

      await useAuthStore.getState().setToken(data.token);

      navigate("/");
    });
  }

  return (
    <Center w="full" h="full">
      <Spinner />
    </Center>
  );
};
