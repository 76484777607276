import { APISchemas } from "@/api";

import { useAuthStore } from "@/context/auth-store/auth-store";
import { i18n, z } from "@/i18n";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  ListItem,
  List,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Text,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { PhoneNumberUtil } from "google-libphonenumber";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import { useConfigStore } from "@/context/config-store/config-store";
import {
  GetCountryNames,
  GetCitiesByCountryName,
} from "@/utils/countries-cities";
import { EditSquareIconBg } from "@/assets/icons/edit-square-icon-bg";
import { CancelSquareIcon } from "@/assets/icons/cancel-square-icon";
import { CheckSquareIcon } from "@/assets/icons/check-square-icon";
import { useSearchParams } from "react-router-dom";

interface EditBillingInfoProps {
  billingInfo: APISchemas["Address"] | undefined;
  handleEdit: (data: validationSchema, individual: boolean) => void;
}
type validationSchema = z.infer<typeof validationSchema>;

const validationSchema = z.object({
  name: z.string().min(1),
  street: z.string().min(1),
  county: z.string().min(1),
  city: z.string().min(1),
  postal_code: z.string().refine(
    (value) => {
      return /^\d+$/.test(value);
    },
    {
      message: i18n.t("errors.postal-code-should-contain-only-digits"),
    }
  ),
  country: z.string().min(1),
  detail: z.string().min(1),
  phone: z.string().min(1),
  contact_name: z.string().min(1),
  tin: z.string().optional(),
  tax_office: z.string().optional(),
});
type EditErrorItem = {
  path: string;
  message: Record<string, string>;
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const EditBillingInfo: React.FC<EditBillingInfoProps> = ({
  billingInfo,
  handleEdit,
}) => {
  const [searchParams] = useSearchParams();

  const ref = useRef<{
    consumed: boolean;
    target: string;
  }>({
    consumed: false,
    target: searchParams.get("id") ?? "",
  });

  const billingInfoRef = useRef<{
    data: typeof billingInfo;
    used: boolean;
  }>();
  const { setUser } = useAuthStore((state) => ({
    setUser: state.setUser,
  }));

  const [language] = useConfigStore((c) => [c.language]);
  const [individual, setIndividual] = useState<boolean>(
    billingInfo?.address_type === "B" ? false : true
  );
  const [phone, setPhone] = useState<string>(billingInfo?.phone ?? "");
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
  const { t } = useTranslation();

  const [countriesData, setCountriesData] = useState<string[]>([]);
  const [citiesData, setCitiesData] = useState<Array<string>>([]);

  const {
    isOpen: isOpenCountry,
    onOpen: onOpenCountry,
    onClose: onCloseCountry,
  } = useDisclosure();
  const {
    isOpen: isOpenCity,
    onOpen: onOpenCity,
    onClose: onCloseCity,
  } = useDisclosure();

  const focusRef = useRef<HTMLInputElement | null>(null);
  const focusCityRef = useRef<HTMLInputElement | null>(null);
  const outsideCountryRef = useRef<HTMLDivElement | null>(null);
  const outsideCityRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: outsideCountryRef,
    handler: onCloseCountry,
  });
  useOutsideClick({
    ref: outsideCityRef,
    handler: onCloseCity,
  });

  const [city, setCity] = useState<string | undefined>();
  const [country, setCountry] = useState<string | undefined>();

  const {
    handleSubmit,
    control,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm<validationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      tax_office: billingInfo?.tax_office ?? undefined,
      tin: billingInfo?.tin ?? undefined,
      name: billingInfo?.id.toString() ?? undefined,
      county: billingInfo?.county ?? undefined,
      city: billingInfo?.city ?? undefined,
      country: billingInfo?.country ?? undefined,
      phone: billingInfo?.phone ?? undefined,
      detail: billingInfo?.detail ?? undefined,
      street: billingInfo?.street ?? undefined,
      postal_code: billingInfo?.postal_code ?? undefined,
      contact_name: billingInfo?.name ?? undefined,
    },
  });

  useEffect(() => {
    const countriesData = GetCountryNames(language);
    setCountriesData(countriesData);
  }, [language]);

  useEffect(() => {
    if (!country) {
      setCitiesData([]);
      return;
    }

    if (!language) return;

    const cities = GetCitiesByCountryName(country, language);

    setCitiesData(cities.map((c) => c.name));

    return;
  }, [country, language]);

  const handleCountryInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCountry(event.target.value);
    },
    []
  );

  const countrySuggestions = useMemo(() => {
    if (!country) return [];
    return countriesData
      .filter((c) => c.toUpperCase().startsWith(country.toUpperCase()))
      .slice(0, 5);
  }, [countriesData, country]);

  const handleCityInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCity(event.target.value);
    },
    []
  );

  const citySuggestions = useMemo(() => {
    if (!city) return [];

    return citiesData
      .filter((c) =>
        c.toLocaleLowerCase("tr-TR").startsWith(city.toLocaleLowerCase("tr-TR"))
      )
      .slice(0, 5);
  }, [city, citiesData]);

  const validatePhone = useCallback((phone: string) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  }, []);

  useEffect(() => {
    setIsPhoneValid(validatePhone(phone));
  }, [phone, validatePhone]);

  if (billingInfo && !billingInfoRef.current?.used) {
    billingInfoRef.current = {
      data: billingInfo,
      used: true,
    };
    setIndividual(billingInfo?.address_type === "B" ? false : true);
  }

  const [editBillingInfo, setEditBillingInfo] = useState<boolean>(false);
  const [editError, setEditError] = useState<any>();

  const onSubmit: SubmitHandler<validationSchema> = useCallback(
    async (data) => {
      if (!isPhoneValid) return;
      const errors = await handleEdit(data, individual);
      setUser();
      setEditError(errors);
      setEditBillingInfo(false);
    },
    [handleEdit, individual, setUser, isPhoneValid]
  );
  useEffect(() => {
    if (editError?.some((error: EditErrorItem) => error !== null)) {
      const timeoutId = setTimeout(() => {
        setEditError(Array(setEditError.length).fill(null));
      }, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [setEditError, editError]);

  const resetForm = useCallback(() => {
    setIndividual(billingInfo?.address_type === "B" ? false : true);
    reset({
      tax_office: billingInfo?.tax_office ?? undefined,
      tin: billingInfo?.tin ?? undefined,
      name: billingInfo?.name ?? undefined,
      county: billingInfo?.county ?? undefined,
      city: billingInfo?.city ?? undefined,
      country: billingInfo?.country ?? undefined,
      phone: billingInfo?.phone ?? undefined,
      detail: billingInfo?.detail ?? undefined,
      street: billingInfo?.street ?? undefined,
      contact_name: billingInfo?.contact_name ?? undefined,
      postal_code: billingInfo?.postal_code ?? undefined,
    });
  }, [billingInfo, reset]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    if (!editBillingInfo) {
      resetForm();
    }
  }, [editBillingInfo, resetForm]);

  useEffect(() => {
    if (!ref.current.consumed && ref.current.target) {
      ref.current.consumed = true;

      const target = document.getElementById(ref.current.target);

      if (target) {
        setTimeout(() => {
          target.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });

          if (ref.current.target === "billing") {
            setEditBillingInfo(true);
          }
        }, 1000);
      }
    }
  }, []);

  return (
    <Flex>
      <Flex
        my="3"
        alignItems="top"
        justifyContent="space-between"
        w="full"
        flex="2"
      >
        <Box whiteSpace="nowrap">
          <Text
            fontWeight="600"
            lineHeight="20px"
            color="#353B48"
            fontSize="24px"
          >
            {t("billing_address")}
          </Text>

          <Text color="#B7BABF" fontSize="13px" lineHeight="20px" mt="1.5">
            {t("page.settings.profile.billing-info")}
          </Text>
        </Box>
      </Flex>
      <Box flex="2.4">
        <Flex w="full" mb="9">
          <Flex alignItems="center" justifyContent="center" mr="10">
            <Box
              width="20px"
              height="20px"
              borderRadius="full"
              border="1.5px solid"
              borderColor={editBillingInfo ? "blue.shiny" : "gray.light"}
              alignItems="center"
              display="flex"
              justifyContent="center"
              onClick={() => {
                if (editBillingInfo) setIndividual(true);
              }}
              cursor={editBillingInfo ? "pointer" : "default"}
            >
              {individual && (
                <Box
                  width="14px"
                  height="14px"
                  borderRadius="full"
                  bg={editBillingInfo ? "blue.shiny" : "gray.light"}
                />
              )}
            </Box>
            <Text ml="2" fontSize="16px" lineHeight="16px">
              {t("individual")}
            </Text>
          </Flex>
          <Flex alignItems="center" justifyContent="center" mr="10">
            <Box
              width="20px"
              height="20px"
              borderRadius="full"
              border="1.5px solid"
              borderColor={editBillingInfo ? "blue.shiny" : "gray.light"}
              alignItems="center"
              display="flex"
              justifyContent="center"
              onClick={() => {
                if (editBillingInfo) setIndividual(false);
              }}
              cursor={editBillingInfo ? "pointer" : "default"}
            >
              {!individual && (
                <Box
                  width="14px"
                  height="14px"
                  borderRadius="full"
                  bg={editBillingInfo ? "blue.shiny" : "gray.light"}
                />
              )}
            </Box>
            <Text ml="2" fontSize="16px" lineHeight="16px">
              {t("company")}
            </Text>
          </Flex>
        </Flex>
        <Box mb="3">
          <form
            style={{
              border: "none",
              width: "full",
              display: "flex",

              position: "relative",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid
              w="full"
              templateRows={individual ? "repeat(5, 1fr)" : "repeat(6, 1fr)"}
              templateColumns="repeat(2, 1fr)"
              columnGap="40px"
            >
              {/* <Text
                position="absolute"
                top="-10px"
                fontSize="16px"
                color="gray.smoke"
              >
                {t("contact_info")}
              </Text> */}
              <GridItem colSpan={1}>
                <FormControl
                  isInvalid={!!errors.contact_name}
                  borderRadius="4px"
                  h="100px"
                  w="full"
                >
                  <FormLabel
                    pos="absolute"
                    top="-10px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {individual ? t("contact_name") : t("company_name")}
                  </FormLabel>
                  <Input
                    border={editBillingInfo ? "1px solid" : "1px solid"}
                    borderColor={
                      editBillingInfo ? "blue.shiny" : "gray.passive"
                    }
                    transition="borderColor 0.6s ease"
                    maxLength={200}
                    variant="unstyled"
                    w="full"
                    p="4"
                    disabled={!editBillingInfo}
                    _disabled={{
                      color: "gray.passive",
                    }}
                    color="gray.passive"
                    type="text"
                    _placeholder={{
                      color: "gray.passive",
                      position: "relative",
                      top: "6px",
                    }}
                    {...register("contact_name")}
                  />

                  {editError?.find(
                    (item: EditErrorItem) => item.path === "contact_name"
                  ) && (
                    <Text
                      mt="5px"
                      fontSize="50px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        editError?.find(
                          (item: EditErrorItem) => item.path === "contact_name"
                        )?.message?.contact_name
                      }
                    </Text>
                  )}
                  {editBillingInfo && (
                    <FormErrorMessage>
                      <Text>{errors.contact_name?.message}</Text>
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl
                  isInvalid={!!errors.phone}
                  borderRadius="4px"
                  transition="borderColor 0.6s ease"
                  w="full"
                  h="100px"
                >
                  <FormLabel
                    pos="absolute"
                    top="-10px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("phone_number")}
                  </FormLabel>
                  <Box
                    border={editBillingInfo ? "1px solid" : "1px solid"}
                    borderColor={
                      editBillingInfo ? "blue.shiny" : "gray.passive"
                    }
                    borderRadius="4px"
                    pl="4"
                    py="2.5"
                    sx={{
                      "& .react-international-phone-input": {
                        background: "white.normal",
                        border: "none",
                        width: "full",
                        color: "gray.passive",
                      },
                      "& .react-international-phone-input--disabled": {
                        background: "white.normal",
                        border: "none",
                        width: "full",
                        color: "gray.passive",
                      },
                      "& .react-international-phone-country-selector-button": {
                        background: "white.normal",
                        width: "full",
                        color: "gray.passive",
                        border: "none",
                      },
                    }}
                  >
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          hideDropdown={true}
                          disabled={!editBillingInfo}
                          defaultCountry="tr"
                          onChange={(value) => {
                            field.onChange(value);
                            setPhone(value);
                          }}
                          value={field.value}
                        />
                      )}
                    />
                  </Box>
                  <Flex gap="2">
                    {editError?.find(
                      (item: EditErrorItem) => item.path === "phone"
                    ) && (
                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        color="red.main"
                        ml="1"
                      >
                        {
                          editError?.find(
                            (item: EditErrorItem) => item.path === "phone"
                          )?.message?.phone
                        }
                      </Text>
                    )}
                    {!isPhoneValid && editBillingInfo && phone.length > 3 && (
                      <Text
                        whiteSpace="nowrap"
                        color="red.main"
                        fontSize="14px"
                        position="relative"
                      >
                        {t("page.settings.profile.phone-validation")}
                      </Text>
                    )}
                    {editBillingInfo && (
                      <Text
                        whiteSpace="nowrap"
                        color="red.main"
                        fontSize="14px"
                        position="relative"
                      >
                        {errors.phone?.message}
                      </Text>
                    )}
                  </Flex>
                </FormControl>
              </GridItem>

              {!individual ? (
                <GridItem colSpan={1}>
                  <FormControl
                    isInvalid={!!errors.tin}
                    width="full"
                    borderRadius="4px"
                    h="100px"
                  >
                    <FormLabel
                      pos="absolute"
                      top="-10px"
                      fontSize="16px"
                      zIndex="9"
                      ml="2.5"
                      px="4"
                      fontWeight="400"
                      lineHeight="1"
                      pr="8"
                      bg="white.normal"
                    >
                      {t("tin")}
                    </FormLabel>

                    <Input
                      border={editBillingInfo ? "1px solid" : "1px solid"}
                      borderColor={
                        editBillingInfo ? "blue.shiny" : "gray.passive"
                      }
                      variant="unstyled"
                      maxLength={20}
                      w="full"
                      p="4"
                      disabled={!editBillingInfo}
                      _disabled={{
                        color: "gray.passive",
                      }}
                      color="gray.passive"
                      type="text"
                      _placeholder={{
                        color: "gray.passive",
                        position: "relative",
                        top: "6px",
                      }}
                      {...register("tin")}
                    />
                    {editError?.find(
                      (item: EditErrorItem) => item.path === "tin"
                    ) && (
                      <Text
                        mt="5px"
                        fontSize="14px"
                        fontWeight="500"
                        color="red.main"
                        ml="1"
                      >
                        {
                          editError?.find(
                            (item: EditErrorItem) => item.path === "tin"
                          )?.message?.tin
                        }
                      </Text>
                    )}

                    {editBillingInfo && (
                      <FormErrorMessage>
                        <Text>{errors.tin?.message}</Text>
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              ) : (
                <GridItem display="none"> </GridItem>
              )}

              {!individual && (
                <GridItem colSpan={1}>
                  <FormControl
                    isInvalid={!!errors.tax_office}
                    borderRadius="4px"
                    h="100px"
                  >
                    <FormLabel
                      pos="absolute"
                      top="-10px"
                      fontSize="16px"
                      zIndex="9"
                      ml="2.5"
                      px="4"
                      fontWeight="400"
                      lineHeight="1"
                      pr="8"
                      bg="white.normal"
                    >
                      {t("tax-office")}
                    </FormLabel>

                    <Input
                      border={editBillingInfo ? "1px solid" : "1px solid"}
                      borderColor={
                        editBillingInfo ? "blue.shiny" : "gray.passive"
                      }
                      variant="unstyled"
                      maxLength={20}
                      w="full"
                      p="4"
                      disabled={!editBillingInfo}
                      _disabled={{
                        color: "gray.passive",
                      }}
                      color="gray.passive"
                      type="text"
                      _placeholder={{
                        color: "gray.passive",
                        position: "relative",
                        top: "6px",
                      }}
                      {...register("tax_office")}
                    />
                    {editError?.find(
                      (item: EditErrorItem) => item.path === "tax_office"
                    ) && (
                      <Text
                        mt="5px"
                        fontSize="14px"
                        fontWeight="500"
                        color="red.main"
                        ml="1"
                      >
                        {
                          editError?.find(
                            (item: EditErrorItem) => item.path === "tax_office"
                          )?.message?.tax_office
                        }
                      </Text>
                    )}
                    {editBillingInfo && (
                      <FormErrorMessage>
                        <Text>{errors.tax_office?.message}</Text>
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              )}

              <GridItem colSpan={1}>
                {/* <Text
                  position="absolute"
                  top="-50px"
                  fontSize="16px"
                  color="gray.smoke"
                >
                  {t("address_info")}
                </Text> */}
                <FormControl
                  width="full"
                  borderRadius="4px"
                  isInvalid={!!errors.name}
                  h="100px"
                >
                  <FormLabel
                    pos="absolute"
                    top="-10px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("address-name")}
                  </FormLabel>
                  <Input
                    border={editBillingInfo ? "1px solid" : "1px solid"}
                    borderColor={
                      editBillingInfo ? "blue.shiny" : "gray.passive"
                    }
                    transition="borderColor 0.6s ease"
                    variant="unstyled"
                    maxLength={20}
                    w="full"
                    p="4"
                    disabled={!editBillingInfo}
                    _disabled={{
                      color: "gray.passive",
                    }}
                    color="gray.passive"
                    type="text"
                    _placeholder={{ color: "gray.passive" }}
                    {...register("name")}
                  />
                  {editError?.find(
                    (item: EditErrorItem) => item.path === "name"
                  ) && (
                    <Text
                      mt="5px"
                      fontSize="14px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        editError?.find(
                          (item: EditErrorItem) => item.path === "name"
                        )?.message?.name
                      }
                    </Text>
                  )}
                  {editBillingInfo && (
                    <FormErrorMessage>
                      <Text>{errors.name?.message}</Text>
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={1} ref={outsideCountryRef}>
                <FormControl
                  w="full"
                  isInvalid={!!errors.country}
                  borderRadius="4px"
                  h="100px"
                >
                  <FormLabel
                    pos="absolute"
                    top="-10px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("country")}
                  </FormLabel>
                  <Popover
                    isOpen={isOpenCountry}
                    onClose={onCloseCountry}
                    initialFocusRef={focusRef}
                    closeOnBlur
                    placement="bottom-end"
                    matchWidth
                  >
                    <PopoverAnchor>
                      <Box>
                        <Controller
                          render={({ field }) => (
                            <Input
                              ref={focusRef}
                              onFocus={onOpenCountry}
                              border={
                                editBillingInfo
                                  ? "1px solid #57AAEB"
                                  : "1px solid #8A858E"
                              }
                              transition="borderColor 0.6s ease"
                              w="full"
                              maxLength={20}
                              variant="unstyled"
                              p="4"
                              disabled={!editBillingInfo}
                              _disabled={{
                                color: "gray.passive",
                              }}
                              color="gray.passive"
                              type="text"
                              autoComplete="off"
                              _placeholder={{ color: "gray.passive" }}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e);
                                handleCountryInputChange(e);
                              }}
                            />
                          )}
                          name={"country"}
                          control={control}
                        />
                      </Box>
                    </PopoverAnchor>
                    {countrySuggestions.length > 0 && (
                      <PopoverContent w="full" zIndex="99">
                        <PopoverBody p="0" w="full">
                          <List overflowY="auto" w="full">
                            {countrySuggestions.map((suggestion) => (
                              <ListItem w="full">
                                <Button
                                  px="4"
                                  textAlign="start"
                                  borderRadius="0px"
                                  variant="unstyled"
                                  bgColor="gray.soft"
                                  justifyContent="start"
                                  w={focusRef.current?.offsetWidth ?? "full"}
                                  cursor="pointer"
                                  key={suggestion}
                                  type="button"
                                  _hover={{ bg: "gray.smooth" }}
                                  onClick={() => {
                                    setValue("country", suggestion);
                                    onCloseCountry();
                                    setCountry(suggestion);
                                  }}
                                >
                                  <Text
                                    overflow="hidden"
                                    fontSize="16px"
                                    fontWeight="450"
                                    color="black.active"
                                    textOverflow="ellipsis"
                                  >
                                    {suggestion}
                                  </Text>
                                </Button>
                              </ListItem>
                            ))}
                          </List>
                        </PopoverBody>
                      </PopoverContent>
                    )}
                  </Popover>

                  {editError?.find(
                    (item: EditErrorItem) => item.path === "country"
                  ) && (
                    <Text
                      mt="5px"
                      fontSize="14px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        editError?.find(
                          (item: EditErrorItem) => item.path === "country"
                        )?.message?.country
                      }
                    </Text>
                  )}
                  {editBillingInfo && (
                    <FormErrorMessage>
                      <Text>{errors.country?.message}</Text>
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>

              <GridItem colSpan={1} ref={outsideCityRef}>
                <FormControl
                  isInvalid={!!errors.city}
                  borderRadius="4px"
                  w="full"
                  h="100px"
                >
                  <FormLabel
                    pos="absolute"
                    top="-10px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="16px"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("city")}
                  </FormLabel>
                  <Popover
                    isOpen={isOpenCity}
                    onClose={onCloseCity}
                    initialFocusRef={focusCityRef}
                    closeOnBlur
                    placement="bottom-end"
                    eventListeners={{ resize: true }}
                    matchWidth
                  >
                    <PopoverAnchor>
                      <Box>
                        <Controller
                          render={({ field }) => (
                            <Input
                              ref={focusCityRef}
                              onFocus={onOpenCity}
                              border={
                                editBillingInfo
                                  ? "1px solid #57AAEB"
                                  : "1px solid #8A858E"
                              }
                              transition="borderColor 0.6s ease"
                              variant="unstyled"
                              maxLength={20}
                              w="full"
                              p="4"
                              disabled={!editBillingInfo}
                              _disabled={{
                                color: "gray.passive",
                              }}
                              type="text"
                              autoComplete="off"
                              _placeholder={{ color: "gray.passive" }}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e);
                                handleCityInputChange(e);
                              }}
                            />
                          )}
                          name={"city"}
                          control={control}
                        />
                      </Box>
                    </PopoverAnchor>

                    {citySuggestions.length > 0 && (
                      <PopoverContent zIndex="99" w="full">
                        <PopoverBody p="0" w="full">
                          <List overflowY="auto" w="full">
                            {citySuggestions.map((suggestion) => (
                              <ListItem w="full">
                                <Button
                                  px="4"
                                  textAlign="start"
                                  borderRadius="0px"
                                  variant="unstyled"
                                  bgColor="gray.soft"
                                  justifyContent="start"
                                  w={
                                    focusCityRef.current?.offsetWidth ?? "full"
                                  }
                                  cursor="pointer"
                                  key={suggestion}
                                  type="button"
                                  overflow="hidden"
                                  _hover={{ bg: "gray.smooth" }}
                                  onClick={() => {
                                    setValue("city", suggestion);
                                    onCloseCity();
                                    setCity(suggestion);
                                  }}
                                >
                                  <Text
                                    overflow="hidden"
                                    fontSize="16px"
                                    fontWeight="450"
                                    color="black.active"
                                    textOverflow="ellipsis"
                                  >
                                    {suggestion}
                                  </Text>
                                </Button>
                              </ListItem>
                            ))}
                          </List>
                        </PopoverBody>
                      </PopoverContent>
                    )}
                  </Popover>

                  {editError?.find(
                    (item: EditErrorItem) => item.path === "city"
                  ) && (
                    <Text
                      mt="5px"
                      fontSize="14px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        editError?.find(
                          (item: EditErrorItem) => item.path === "city"
                        )?.message?.city
                      }
                    </Text>
                  )}
                  {editBillingInfo && (
                    <FormErrorMessage>
                      <Text>{errors.city?.message}</Text>
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl
                  isInvalid={!!errors.county}
                  width="full"
                  borderRadius="4px"
                  h="100px"
                >
                  <FormLabel
                    pos="absolute"
                    top="-10px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("county")}
                  </FormLabel>

                  <Input
                    border={editBillingInfo ? "1px solid" : "1px solid"}
                    borderColor={
                      editBillingInfo ? "blue.shiny" : "gray.passive"
                    }
                    variant="unstyled"
                    maxLength={20}
                    w="full"
                    p="4"
                    disabled={!editBillingInfo}
                    _disabled={{
                      color: "gray.passive",
                    }}
                    color="gray.passive"
                    type="text"
                    _placeholder={{
                      color: "gray.passive",
                      position: "relative",
                      top: "6px",
                    }}
                    {...register("county")}
                  />
                  {editError?.find(
                    (item: EditErrorItem) => item.path === "county"
                  ) && (
                    <Text
                      mt="5px"
                      fontSize="14px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        editError?.find(
                          (item: EditErrorItem) => item.path === "county"
                        )?.message?.county
                      }
                    </Text>
                  )}
                  {editBillingInfo && (
                    <FormErrorMessage>
                      <Text>{errors.county?.message}</Text>
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl
                  isInvalid={!!errors.street}
                  borderRadius="4px"
                  h="100px"
                  w="full"
                >
                  <FormLabel
                    pos="absolute"
                    top="-10px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("street")}
                  </FormLabel>
                  <Input
                    border={editBillingInfo ? "1px solid" : "1px solid"}
                    borderColor={
                      editBillingInfo ? "blue.shiny" : "gray.passive"
                    }
                    transition="borderColor 0.6s ease"
                    maxLength={20}
                    variant="unstyled"
                    w="full"
                    p="4"
                    disabled={!editBillingInfo}
                    _disabled={{
                      color: "gray.passive",
                    }}
                    color="gray.passive"
                    type="text"
                    _placeholder={{
                      color: "gray.passive",
                      position: "relative",
                      top: "6px",
                    }}
                    {...register("street")}
                  />
                  {editError?.find(
                    (item: EditErrorItem) => item.path === "street"
                  ) && (
                    <Text
                      mt="5px"
                      fontSize="14px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        editError?.find(
                          (item: EditErrorItem) => item.path === "street"
                        )?.message?.street
                      }
                    </Text>
                  )}
                  {editBillingInfo && (
                    <FormErrorMessage>
                      <Text>{errors.street?.message}</Text>
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl
                  width="full"
                  borderRadius="4px"
                  isInvalid={!!errors.postal_code}
                  h="100px"
                >
                  <FormLabel
                    pos="absolute"
                    top="-10px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("postal_code")}
                  </FormLabel>
                  <Input
                    border={editBillingInfo ? "1px solid" : "1px solid"}
                    borderColor={
                      editBillingInfo ? "blue.shiny" : "gray.passive"
                    }
                    transition="borderColor 0.6s ease"
                    variant="unstyled"
                    maxLength={20}
                    w="full"
                    p="4"
                    disabled={!editBillingInfo}
                    _disabled={{
                      color: "gray.passive",
                    }}
                    color="black.active"
                    type="text"
                    _placeholder={{ color: "gray.passive" }}
                    {...register("postal_code")}
                  />
                  {editError?.find(
                    (item: EditErrorItem) => item.path === "postal_code"
                  ) && (
                    <Text
                      mt="5px"
                      fontSize="14px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        editError?.find(
                          (item: EditErrorItem) => item.path === "postal_code"
                        )?.message?.postal_code
                      }
                    </Text>
                  )}
                  {editBillingInfo && (
                    <FormErrorMessage>
                      <Text>{errors.postal_code?.message}</Text>
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>

              <GridItem colSpan={2}>
                <FormControl
                  isInvalid={!!errors.detail}
                  borderRadius="4px"
                  h="100px"
                  pos="relative"
                >
                  <FormLabel
                    pos="absolute"
                    top="4px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white.normal"
                  >
                    {t("billing_address")}
                  </FormLabel>
                  <Input
                    border={editBillingInfo ? "1px solid" : "1px solid"}
                    borderColor={
                      editBillingInfo ? "blue.shiny" : "gray.passive"
                    }
                    maxLength={200}
                    h="100px"
                    w="100%"
                    mt="3"
                    p="4"
                    variant="unstyled"
                    disabled={!editBillingInfo}
                    _disabled={{
                      color: "gray.passive",
                    }}
                    transition="borderColor 0.6s ease"
                    color="gray.passive"
                    type="text"
                    _placeholder={{ color: "gray.passive" }}
                    {...register("detail")}
                  />
                  {editError?.find(
                    (item: EditErrorItem) => item.path === "detail"
                  ) && (
                    <Text
                      mt="5px"
                      fontSize="14px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        editError?.find(
                          (item: EditErrorItem) => item.path === "detail"
                        )?.message?.detail
                      }
                    </Text>
                  )}
                  {editBillingInfo && (
                    <FormErrorMessage>
                      <Text>{errors.detail?.message}</Text>
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
          </form>
        </Box>
      </Box>
      <Flex flex="0.6" justifyContent="right" id="billing">
        {" "}
        {!editBillingInfo ? (
          <Button
            cursor="pointer"
            variant="unstyled"
            type="submit"
            onClick={() => {
              setEditBillingInfo(!editBillingInfo);
            }}
          >
            <Flex alignItems="center" justifyContent="center">
              <EditSquareIconBg width="40px" height="40px" />
            </Flex>
          </Button>
        ) : (
          <Flex>
            <Button
              w="40px"
              h="40px"
              border-radius="3px"
              color="white.normal"
              onClick={() => setEditBillingInfo(false)}
              _hover={{
                opacity: "0.6",
              }}
            >
              <Flex alignItems="center" justifyContent="center">
                <CancelSquareIcon width="40px" height="40px" />
              </Flex>
            </Button>
            <Button
              w="40px"
              h="40px"
              border-radius="3px"
              color="white.normal"
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              _hover={{
                opacity: "0.6",
              }}
              ml="2"
              type="submit"
            >
              <Flex alignItems="center" justifyContent="center">
                <CheckSquareIcon width="40px" height="40px" />
              </Flex>
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
