import React from "react";

export const ChangePasswordIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.4316 2.70716C15.0878 2.67581 14.7838 2.92909 14.7525 3.27284L14.6697 4.18125C13.2647 1.97481 10.8258 0.5 7.99991 0.5C4.95988 0.5 2.28078 2.23506 0.950846 4.83919C0.793846 5.14659 0.915784 5.52306 1.22319 5.68006C1.53066 5.83706 1.90706 5.71516 2.06406 5.40772C3.1896 3.20384 5.44981 1.75 7.99991 1.75C10.3242 1.75 12.4217 2.96034 13.6234 4.8565L12.7323 4.22003C12.4514 4.01944 12.061 4.0845 11.8604 4.36537C11.6598 4.64628 11.7249 5.03659 12.0058 5.23722L14.7081 7.16725C15.1042 7.44959 15.6505 7.19112 15.6938 6.7154L15.9972 3.38634C16.0286 3.04256 15.7754 2.7385 15.4316 2.70716Z"
        fill="#50CD89"
      />
      <path
        d="M14.7767 11.32C14.4692 11.163 14.0928 11.2849 13.9358 11.5923C12.8103 13.7962 10.5501 15.25 7.99996 15.25C5.67568 15.25 3.57812 14.0397 2.37643 12.1435L3.26759 12.78C3.5485 12.9806 3.93881 12.9155 4.13943 12.6347C4.34003 12.3538 4.27496 11.9634 3.99409 11.7628L1.29175 9.83279C0.900528 9.55329 0.349841 9.80401 0.306091 10.2846L0.00262211 13.6137C-0.0287216 13.9574 0.22456 14.2615 0.568278 14.2929C0.912466 14.3241 1.21612 14.0706 1.24743 13.7272L1.33025 12.8188C2.73518 15.0252 5.17406 16.5 7.99996 16.5C11.04 16.5 13.7191 14.765 15.049 12.1609C15.206 11.8534 15.0841 11.477 14.7767 11.32Z"
        fill="#50CD89"
      />
    </svg>
  );
};
