import React, {
  useState,
  useRef,
  useEffect,
  FormEventHandler,
  useCallback,
} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Input,
  Box,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { colors } from "@/theme/colors";
import { addMinutes } from "date-fns";
import { i18n } from "@/i18n";
import { setPasswordSchema } from "@/pages/auth/Forgot-Password";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export interface Step2Props {
  isOpen: boolean;
  onClose: () => void;
  onVerify: (code: string) => void;

  duration: { minutes: number; seconds: number };
  goToNextStep: () => void;
  goToPreviousStep: () => void;

  setDuration: React.Dispatch<
    React.SetStateAction<{ minutes: number; seconds: number; endDate: Date }>
  >;
  onSubmitSendEmail: FormEventHandler<HTMLElement>;

  handleVerificationCode: (verification_code: string) => Promise<boolean>;
}

export const Step2: React.FC<Step2Props> = ({
  isOpen,
  onClose,
  duration,
  setDuration,
  goToNextStep,
  goToPreviousStep,
  onSubmitSendEmail,
  handleVerificationCode,
}) => {
  const { t } = useTranslation();
  const [pin, setPin] = useState<string[]>(new Array(6).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>(
    new Array(6).fill(null)
  );
  const toast = useToast();

  const setPasswordMethods = useForm<setPasswordSchema>({
    resolver: zodResolver(setPasswordSchema),
  });

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, [isOpen]);

  const handleChange = (index: number, value: string) => {
    const newPin = [...pin];
    newPin[index] = value.replace(/[^0-9]/g, "");

    setPin(newPin);

    if (value !== "" && index < pin.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const { setValue } = setPasswordMethods;

  const handleSendButtonClick = useCallback(
    async (pin: string[]) => {
      if (!pin.every((value) => value != "")) {
        toast({
          description: i18n.t("errors.please-write-6-digits"),
          status: "error",
          isClosable: true,
        });
        return;
      }
      const success = await handleVerificationCode(
        pin.toString().replace(/,/g, "")
      );
      if (!success) return;
      setValue("verification_code", pin.toString().replace(/,/g, ""));
      goToNextStep();
    },
    [goToNextStep, setValue, toast, handleVerificationCode]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="704"
        px="16px"
        py="28px"
        border="1px solid"
        borderColor="gray.athens"
      >
        <ModalCloseButton />
        <ModalHeader pb="0">
          {" "}
          {t("page.settings.profile.confirm-code")}
        </ModalHeader>
        <ModalBody py="0">
          <Box justifyContent="center">
            <Text pb="24px" pt="8px" fontSize="14px" fontWeight="400">
              {t("page.settings.profile.enter-code")}{" "}
            </Text>{" "}
            <Text color="red.main" fontSize="16px">
              {t("page.settings.profile.time-left")}
              {(duration.minutes !== 0 || duration.seconds !== 0) && (
                <Text as="span" color="blue.shiny" fontWeight="600" ml="6px">
                  {("0" + duration.minutes.toString()).slice(-2)}:
                  {("0" + duration.seconds.toString()).slice(-2)}
                </Text>
              )}
            </Text>
            <Flex gap="4">
              {pin.map((value, index) => (
                <Input
                  key={index}
                  type="text"
                  maxLength={1}
                  value={value}
                  onChange={(e) => handleChange(index, e.target.value)}
                  size="lg"
                  textAlign="center"
                  ref={(el) => (inputRefs.current[index] = el)}
                  h="94px"
                  fontSize="lg"
                  borderRadius="3px"
                  my="26px"
                  bg="white.alabaster"
                  borderColor="blue.brand-light"
                />
              ))}
            </Flex>
            <Text
              as="span"
              style={{
                fontWeight: 400,
                marginRight: 8,
                color: colors.blue.shiny,
              }}
            >
              {t("page.settings.profile.receive-code")}
            </Text>
            <Button
              variant="link"
              onClick={(event) => {
                if (duration.minutes !== 0 && duration.seconds !== 0) {
                  toast({
                    description: t("errors.please-wait-the-timer-to-be-over"),
                    status: "error",
                    isClosable: true,
                  });
                  return;
                }
                if (event) {
                  onSubmitSendEmail(event);
                }
                setDuration(() => ({
                  minutes: 3,
                  seconds: 0,
                  endDate: addMinutes(new Date(), 3),
                }));
              }}
              color="blue.shiny"
            >
              {t("page.settings.profile.send-again")}
            </Button>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="white.normal"
            color="blue.shiny"
            border="1px solid"
            borderColor="blue.shiny"
            onClick={goToPreviousStep}
            mr="16px"
            px="20px"
            py="14px"
            fontSize="14px"
            w="164px"
          >
            {t("page.settings.profile.back")}
          </Button>
          <Button
            color="white.normal"
            onClick={() => handleSendButtonClick(pin)}
            bg="blue.shiny"
            px="20px"
            py="14px"
            fontSize="14px"
            w="164px"
          >
            {t("page.settings.profile.continue")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
