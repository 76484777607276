import { FC } from "react";

export const WirelessIcon: FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="29"
      viewBox="0 0 25 29"
      fill="none"
      {...props}
    >
      <path
        d="M13.1682 4.44284C12.4469 3.85239 11.2729 3.85239 10.5445 4.44284C9.82321 5.03328 9.82321 5.99421 10.5445 6.59045C15.5372 10.6773 15.5372 17.3285 10.5445 21.4153C10.1839 21.7106 10 22.0984 10 22.4863C10 22.8741 10.1839 23.2619 10.5445 23.5572C11.2659 24.1476 12.4398 24.1476 13.1682 23.5572C19.6106 18.2894 19.6106 9.71056 13.1682 4.44284Z"
        fill="#353B48"
      />
      <path
        d="M8.20812 8.45044C7.4777 7.84985 6.28897 7.84985 5.55139 8.45044C4.82097 9.05103 4.82097 10.0285 5.55139 10.6349C7.81426 12.4897 7.81426 15.5162 5.55139 17.371C5.18618 17.6712 5 18.0658 5 18.4603C5 18.8548 5.18618 19.2493 5.55139 19.5496C6.28181 20.1501 7.47053 20.1501 8.20095 19.5496C11.9318 16.4936 11.9318 11.5123 8.20812 8.45044Z"
        fill="#353B48"
      />
      <path
        d="M3.74644e-07 14C3.18204e-07 15.1046 0.895432 16 2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12C0.895432 12 4.31084e-07 12.8954 3.74644e-07 14Z"
        fill="#353B48"
      />
      <path
        d="M18.1384 28.5466C27.2872 20.8002 27.2872 8.19978 18.1384 0.453403C17.4238 -0.151135 16.2609 -0.151135 15.5394 0.453402C14.8249 1.05794 14.8249 2.04179 15.5394 2.65226C23.2662 9.18363 23.2662 19.8164 15.5394 26.3537C15.1821 26.6559 15 27.053 15 27.4501C15 27.8472 15.1821 28.2443 15.5394 28.5466C16.2539 29.1511 17.4168 29.1511 18.1384 28.5466Z"
        fill="#353B48"
      />
    </svg>
  );
};
