import { FC } from "react";

export const GoogleIcon: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="23" cy="23" r="23" fill="#DCDDE1" fillOpacity="0.33" />
      <g clipPath="url(#clip0_16369_3240)">
        <path
          d="M35.0182 20.7398L24.4131 20.7393C23.9448 20.7393 23.5652 21.1188 23.5652 21.5871V24.975C23.5652 25.4432 23.9448 25.8228 24.413 25.8228H30.3852C29.7312 27.52 28.5107 28.9413 26.9534 29.8444L29.4999 34.2527C33.5849 31.8902 35.9999 27.7449 35.9999 23.1046C35.9999 22.4439 35.9512 21.9716 35.8538 21.4398C35.7798 21.0357 35.429 20.7398 35.0182 20.7398Z"
          fill="#167EE6"
        />
        <path
          d="M23 30.913C20.0773 30.913 17.5259 29.3161 16.1556 26.9531L11.7474 29.4939C13.9907 33.3818 18.1931 36 23 36C25.3581 36 27.5832 35.365 29.5 34.2586V34.2525L26.9535 29.8442C25.7887 30.5198 24.4407 30.913 23 30.913Z"
          fill="#12B347"
        />
        <path
          d="M29.5 34.2586V34.2526L26.9535 29.8442C25.7887 30.5198 24.4408 30.913 23 30.913V36C25.3581 36 27.5833 35.3651 29.5 34.2586Z"
          fill="#0F993E"
        />
        <path
          d="M15.087 23.0001C15.087 21.5594 15.4801 20.2117 16.1556 19.0469L11.7474 16.5061C10.6349 18.4168 10 20.6359 10 23.0001C10 25.3642 10.6349 27.5833 11.7474 29.494L16.1556 26.9532C15.4801 25.7885 15.087 24.4407 15.087 23.0001Z"
          fill="#FFD500"
        />
        <path
          d="M23 15.087C24.9059 15.087 26.6565 15.7642 28.0239 16.8907C28.3612 17.1685 28.8515 17.1485 29.1605 16.8395L31.561 14.439C31.9116 14.0884 31.8866 13.5146 31.5121 13.1897C29.2211 11.2021 26.2402 10 23 10C18.193 10 13.9907 12.6181 11.7474 16.506L16.1556 19.0468C17.5259 16.6838 20.0773 15.087 23 15.087Z"
          fill="#FF4B26"
        />
        <path
          d="M28.0239 16.8907C28.3612 17.1685 28.8516 17.1485 29.1605 16.8395L31.561 14.439C31.9115 14.0884 31.8865 13.5146 31.5121 13.1897C29.2211 11.2021 26.2402 10 23 10V15.087C24.9058 15.087 26.6565 15.7642 28.0239 16.8907Z"
          fill="#D93F21"
        />
      </g>
      <defs>
        <clipPath id="clip0_16369_3240">
          <rect
            width="26"
            height="26"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
