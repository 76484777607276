export const SettingFilterIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.91919 9.62437C6.20202 10.0337 6.11747 9.69261 6.11747 17.8508C6.11747 18.5809 6.9504 18.9987 7.53744 18.562C10.0459 16.6707 10.5471 16.507 10.5471 15.6223C10.5471 9.67724 10.4778 10.0116 10.7454 9.62437L14.8189 4.07959H1.8457L5.91919 9.62437Z"
        fill="#57AAEC"
      />
      <path
        d="M16.3575 1.19186C16.212 0.913067 15.9265 0.739746 15.6122 0.739746H1.05206C0.373364 0.739746 -0.0259768 1.50534 0.363344 2.06162C0.366543 2.06697 0.319152 2.0021 1.07069 3.0249H15.5936C16.234 2.15327 16.6621 1.77668 16.3575 1.19186Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
