import React, { FC, useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  DotProps,
} from "recharts";

import { CustomTooltip } from "./custom-tooltip";

interface CustomRectangleProps extends DotProps {
  payload: {
    name: string;
    data: number;
  };
  avg: number;
}
const CustomRectangle: React.FC<CustomRectangleProps> = ({
  cx,
  cy,
  payload,
  avg,
}) => {
  const fillColor = payload?.data > avg ? "#50CD89" : "#F13232";
  const rectHeight = 100;

  const y = (cy ?? 0) - rectHeight / 20;

  return (
    <rect
      x={(cx ?? 0) - 5}
      y={y}
      width={10}
      height={rectHeight}
      fill={fillColor}
      strokeWidth={1}
      rx={6}
      ry={6}
    />
  );
};

interface CandleStickGraphProps {
  data: {
    name: string;
    data: number;
    lastData?: number;
  }[];
}

export const CandleStickGraph: FC<CandleStickGraphProps> = ({ data }) => {
  const avg = useMemo(() => {
    return data.reduce((sum, point) => sum + point.data, 0) / data.length;
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="2 2" horizontal={false} />
        <ReferenceLine y={avg} stroke="#57AAEC" strokeDasharray="3 3" />
        <XAxis
          axisLine={{ stroke: "#B7BABF", strokeWidth: 1 }}
          dataKey="name"
          scale="point"
          padding={{ left: 60, right: 60 }}
          tick={{ fontSize: 12, fontWeight: "400", fill: "#B7BABF" }}
          tickLine={{ stroke: "gray.smoke" }}
        />
        <YAxis
          axisLine={{ stroke: "#B7BABF", strokeWidth: 1 }}
          tick={{ fontSize: 12, fontWeight: "400", fill: "#B7BABF" }}
          tickLine={{ stroke: "gray.smoke" }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="data"
          stroke="none"
          dot={(props) => <CustomRectangle {...props} avg={avg} />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
