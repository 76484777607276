import { Box, Flex, Text } from "@chakra-ui/react";

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChipBigIcon } from "@/assets/icons/chip-big-icon";
import { WirelessBigIcon } from "@/assets/icons/wireless-big-icon";

export interface CardInfo {
  cardHolderName: string | null;
  maskedCardNumber: string;
  cardToken: string;
  finishPaymentRequired: boolean;
  cardNamebyUser: string;
  bankCode: string | null;
  isDebitCard: boolean | null;
  cardProgramName: string | null;
  isBusinessCard: boolean | null;
  cardType: string;
  bin: string | null;
}

export const CardPreview: FC<{ card?: CardInfo }> = ({ card }) => {
  const { t } = useTranslation();

  return (
    <Box
      maxW="694px"
      h="410px"
      border="1.758px solid #B7BABF"
      boxShadow="14.647px 17.576px 29.294px 0px rgba(0, 0, 0, 0.05)"
      backdropFilter="blur(10.252809524536133px)"
      bg="linear-gradient(113deg, rgba(255, 255, 255, 0.51) 3.51%, rgba(255, 255, 255, 0.00) 111.71%)"
      rounded="3xl"
      color="black.dark"
      fontWeight="600"
    >
      <Flex
        justifyContent="space-between"
        mt="50px"
        ml="20px"
        alignItems="center"
      >
        <ChipBigIcon />
        <WirelessBigIcon />
      </Flex>
      <Box ml="50px" mt="27px" mb="120px">
        <Text
          fontSize="16px"
          letterSpacing="wide"
          opacity="0.9"
          mb="28px"
          lineHeight="8px"
        >
          {t("page.membership.Card_Number")}
        </Text>
        <Text
          fontSize="26px"
          letterSpacing="wide"
          opacity="0.9"
          lineHeight="14px"
        >
          {card?.maskedCardNumber
            ?.replace(/([0-9*]{4})/g, (a: string) => a + " ")
            .trim()}
        </Text>
      </Box>
      <Flex ml="50px" alignItems="center">
        <Text
          fontSize="26px"
          letterSpacing="wide"
          opacity="0.9"
          w="20ch"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textTransform="uppercase"
        >
          {card?.cardHolderName || "****"}
        </Text>
        <Box ml="100px">
          <Text
            fontSize="16px"
            whiteSpace="nowrap"
            opacity="0.9"
            lineHeight="24px"
            mb="8px"
            mr="40px"
          >
            {t("page.membership.valid_thru")}
          </Text>
          <Text
            fontSize="16px"
            letterSpacing="wide"
            opacity="0.9"
            lineHeight="14px"
          >
            {/* {card?.expiryMonth || "**"}/{card?.expiryYear || "**"} */} **/**
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
