import React from "react";

export const UploadProfileIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.7175 6.10761H7.52291V11.4633C7.52291 12.0067 7.96348 12.4468 8.50631 12.4468H11.1906C11.7334 12.4468 12.174 12.0068 12.174 11.4633V6.10761H13.9788C14.2778 6.10761 14.5465 5.92759 14.6615 5.65129C14.7753 5.37611 14.712 5.05799 14.5007 4.84674L10.3703 0.715802C10.0818 0.427903 9.61446 0.427903 9.32536 0.715802L5.19562 4.84674C4.98433 5.05799 4.92103 5.37557 5.03542 5.65129C5.15031 5.92704 5.41851 6.10761 5.7175 6.10761Z"
        fill="#50CD89"
      />
      <path
        d="M17.881 8.11499C17.3916 8.11499 16.9945 8.51208 16.9945 9.002V12.7109C16.9945 13.822 16.0901 14.7258 14.979 14.7258H4.71731C3.60621 14.7258 2.70237 13.822 2.70237 12.7109V9.002C2.70237 8.51208 2.30473 8.11499 1.81536 8.11499C1.3254 8.11499 0.928345 8.51208 0.928345 9.00204V12.7109C0.928345 14.8001 2.62804 16.4999 4.71731 16.4999H14.979C17.0682 16.4999 18.768 14.8002 18.768 12.7109V9.00204C18.768 8.51208 18.3709 8.11499 17.881 8.11499Z"
        fill="#50CD89"
      />
    </svg>
  );
};
