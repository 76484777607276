import React from "react";

export const EditSquareIconBg = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="32"
        height="32"
        rx="5.5"
        fill="#57AAEC"
        fillOpacity="0.13"
        stroke="#57AAEC"
      />
      <path
        d="M18.4741 11.1282L21.7706 14.4247L13.4263 22.7689L10.1317 19.4725L18.4741 11.1282ZM24.2719 10.3332L22.8018 8.86312C22.2337 8.29498 21.3112 8.29498 20.7411 8.86312L19.3329 10.2713L22.6293 13.5678L24.2719 11.9252C24.7126 11.4845 24.7126 10.7739 24.2719 10.3332ZM8.40663 24.1059C8.34664 24.3759 8.59041 24.6178 8.86043 24.5521L12.5338 23.6615L9.23919 20.3651L8.40663 24.1059Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
