import { Icon } from "@chakra-ui/react";

interface AddPersonIconProps extends React.SVGAttributes<SVGElement> {
  fill: string;
}

export const AddPersonIcon = (props: AddPersonIconProps) => {
  return (
    <Icon w={props.width || "21px"} h={props.height || "21px"}>
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M11.4866 8.02841C11.1791 7.88225 10.8654 7.75634 10.5461 7.65088C11.58 6.87488 12.25 5.63922 12.25 4.25C12.25 1.90656 10.3435 0 7.99999 0C5.65652 0 3.74999 1.90656 3.74999 4.25C3.74999 5.64075 4.42149 6.87762 5.45727 7.65347C4.50827 7.96591 3.6178 8.45206 2.83843 9.09313C1.4093 10.2687 0.415644 11.9083 0.0405505 13.7101C-0.077012 14.2747 0.0638317 14.8545 0.426894 15.301C0.788175 15.7452 1.32389 16 1.89664 16H9.59374C9.93892 16 10.2187 15.7202 10.2187 15.375C10.2187 15.0298 9.93892 14.75 9.59374 14.75H1.89664C1.62983 14.75 1.46886 14.6011 1.39668 14.5123C1.27202 14.359 1.22377 14.1595 1.2643 13.9648C1.91289 10.8494 4.66089 8.57356 7.8343 8.49681C7.88927 8.49894 7.94449 8.5 7.99999 8.5C8.05602 8.5 8.1118 8.49891 8.1673 8.49675C9.1398 8.51956 10.0751 8.7415 10.9499 9.15738C11.2617 9.3055 11.6345 9.17297 11.7827 8.86119C11.9309 8.54944 11.7983 8.17659 11.4866 8.02841ZM8.15242 7.24616C8.10171 7.24525 8.05089 7.24478 7.99999 7.24478C7.94955 7.24478 7.89911 7.24528 7.84874 7.24622C6.26452 7.16719 4.99999 5.8535 4.99999 4.25C4.99999 2.59578 6.34577 1.25 7.99999 1.25C9.65421 1.25 11 2.59578 11 4.25C11 5.85309 9.73608 7.16653 8.15242 7.24616Z"
          fill={props.fill || "#33373D"}
        />
        <path
          d="M15.375 12.4062H13.6562V10.6875C13.6562 10.3423 13.3764 10.0625 13.0312 10.0625C12.6861 10.0625 12.4062 10.3423 12.4062 10.6875V12.4062H10.6875C10.3423 12.4062 10.0625 12.6861 10.0625 13.0312C10.0625 13.3764 10.3423 13.6562 10.6875 13.6562H12.4062V15.375C12.4062 15.7202 12.6861 16 13.0312 16C13.3764 16 13.6562 15.7202 13.6562 15.375V13.6562H15.375C15.7202 13.6562 16 13.3764 16 13.0312C16 12.6861 15.7202 12.4062 15.375 12.4062Z"
          fill={props.fill || "#33373D"}
        />
      </svg>
    </Icon>
  );
};
