import { AttachIcon } from "@/assets/icons/attach-icon";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { TicketPopover } from "../popover/ticket-popover";
import { useForm } from "react-hook-form";
import { useChannel } from "@/hooks/use-channels";
import { Trans, useTranslation } from "react-i18next";
import { PLATFORMS } from "@/constant";
import { platformIcons } from "@/utils/platform-icons";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";

import { DeleteDocIcon } from "@/assets/icons/delete-doc-icon";
import { ClipIcon } from "@/assets/icons/clip-icon";

export const CreateTicket: FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}> = ({ onClose, isOpen, onSubmit }) => {
  const [images, setImages] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const uploadedImages = acceptedFiles.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setImages((prevImages) => [...prevImages, ...uploadedImages]);
  }, []);
  const { getRootProps: fileRoot, getInputProps: fileInput } = useDropzone({
    onDrop: onDrop,
    accept: {
      "image/jpg": [".png", ".jpg", ".jpeg"],
      "video/mp4": [".mp4"],
    },
  });

  type SupportSchema = {
    title: string;
    message: string;
    channel?: string;
    feature?: string;
    platform?: string;
    file: File[];
  };

  console.log("images", images);

  const { register, handleSubmit, setValue, watch } = useForm<SupportSchema>();

  const feature = watch("feature");
  const platform = watch("platform");
  const channel = watch("channel");
  const file = watch("file");
  console.log("file", file);

  const { data: Channels } = useChannel();
  const { t } = useTranslation();

  type FEATURES = string[];

  const features: FEATURES = useMemo(
    () => [
      t("page.faqList.Planning.title"),
      t("page.faqList.Analytics.title"),
      t("page.faqList.Sharing.title"),
      t("page.faqList.Collaboration.title"),
      t("page.faqList.Channels.title"),
      t("page.faqList.URLShortening.title"),
    ],
    [t]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0px" maxH="780px" maxW="780px">
        <ModalBody p="24px">
          <Grid
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            templateRows="repeat(6, 1fr)"
            templateColumns="repeat(3, 1fr)"
            rowGap="12px"
          >
            <GridItem colSpan={3}>
              <FormControl>
                <FormLabel> {t("page.help-center.subject")}</FormLabel>
                <Input
                  h="56px"
                  px="12px"
                  placeholder={t("page.help-center.info")}
                  _placeholder={{ fontSize: "14px", color: "gray.smoke" }}
                  borderRadius="4px"
                  {...register("title")}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={3} rowSpan={2}>
              <FormControl>
                <FormLabel>{t("page.help-center.message")}</FormLabel>
                <Textarea
                  placeholder={t("page.help-center.info2")}
                  _placeholder={{ fontSize: "14px", color: "gray.smoke" }}
                  borderRadius="4px"
                  resize="none"
                  px="12px"
                  h="150px"
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl>
                <FormLabel>{t("page.help-center.relevant-feature")}</FormLabel>
                <Box w="full" pr="20px">
                  <TicketPopover
                    w="220px"
                    selected={feature}
                    onChange={(data) => setValue("feature", data)}
                    data={features.map((item) => ({
                      key: item,
                      value: item,
                    }))}
                  />
                </Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl h="full">
                <FormLabel>{t("page.help-center.relevant-platform")}</FormLabel>
                <Box w="full" pr="20px">
                  <TicketPopover
                    selected={platform}
                    w="220px"
                    onChange={(data) => setValue("platform", data)}
                    data={PLATFORMS.map((item) => ({
                      key: item,
                      value: item,
                      Icon: platformIcons(item),
                    }))}
                  />
                </Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl h="full">
                <FormLabel>{t("page.help-center.relevant-channel")}</FormLabel>

                <TicketPopover
                  selected={channel}
                  w="245px"
                  onChange={(data) => setValue("channel", data)}
                  data={
                    Channels && Channels.length > 0
                      ? Channels.map((item) => ({
                          key: item.name.toString(),
                          value: item.name,
                          Icon: (
                            <AvatarWithBadge
                              w="28px"
                              h="28px"
                              key={item.id}
                              picture={item.picture ?? undefined}
                              channel={item.channel_type}
                              channel_id={item.id.toString()}
                            />
                          ),
                        }))
                      : [
                          {
                            key: "no_channel",
                            value: t("page.help-center.no-channel"),
                          },
                        ]
                  }
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={3} rowSpan={2}>
              <FormControl h="full">
                <FormLabel>{t("page.help-center.attach-document")}</FormLabel>
                <Box
                  w="full"
                  h="155px"
                  borderRadius="4px"
                  border="0.05px solid rgba(212, 212, 212, 0.53)"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  {...fileRoot()}
                  cursor="pointer"
                  display="flex"
                >
                  <Box
                    flex="1"
                    overflowY="scroll"
                    h="full"
                    py="10px"
                    display={images.length > 0 ? "inline" : "none"}
                  >
                    {images.map((file, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        mb="10px"
                        mx="10px"
                        justifyContent="space-between"
                      >
                        <Text
                          maxW="200px"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          fontSize="14px"
                          color="black.dark"
                          mr="10px"
                          bg="gray.athens"
                          px="20px"
                          py="3px"
                          borderRadius="6px"
                        >
                          <IconButton
                            aria-label="delete"
                            variant="unstyled"
                            size="none"
                            mr="10px"
                            icon={<ClipIcon width="14px" />}
                          />

                          {file.name}
                        </Text>
                        <IconButton
                          aria-label="delete"
                          variant="unstyled"
                          size="none"
                          icon={<DeleteDocIcon />}
                          onClick={() => {
                            setImages((prevImages) =>
                              prevImages.filter((_, i) => i !== index)
                            );
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                  <Divider
                    display={images.length > 0 ? "block" : "none"}
                    orientation="vertical"
                    bg="gray.athens"
                    h="90%"
                    w="2px"
                  />

                  <Box
                    flex="2"
                    justifyContent="center"
                    alignItems="center"
                    flexDir="column"
                    display="flex"
                  >
                    <input {...fileInput()} />
                    <AttachIcon />{" "}
                    <Text fontSize="14px" mt="18px">
                      <Trans i18nKey="page.help-center.uploadInstruction">
                        <Link
                          color="blue.shiny"
                          href="#"
                          mr="5px"
                          fontWeight="bold"
                          {...fileRoot()}
                        />
                        {{ drag: t("page.help-center.drag") }}{" "}
                        {{ local: t("page.help-center.local") }}
                      </Trans>
                    </Text>
                    <Text fontSize="14px" color="gray.smoke" mt="16px">
                      {t("page.help-center.upload")}
                    </Text>
                  </Box>
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter pt="0">
          <Button
            bg="gray.smoke"
            w="95px"
            h="36px"
            color="white.normal"
            px="32px"
            onClick={() => {
              onClose();
              setImages([]);
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="ghost"
            h="36px"
            bg="blue.shiny"
            color="white.normal"
            px="32px"
            ml="20px"
            type="submit"
            onClick={() => {
              handleSubmit(onSubmit)();
              console.log("submit");
            }}
          >
            {t("create")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
