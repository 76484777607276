import {
  Box,
  Button,
  Flex,
  IconButton,
  RadioGroup,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FC, useCallback, useRef, useState } from "react";

import { LoadingIllustration } from "@/assets/icons/loading-illustration";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { GET, POST } from "@/api";
import { CardItem } from "./card-item";
import { InvoiceTable } from "../../Membership/components/invoice-table";
import { ConnectPlusIcon } from "@/assets/icons/connect-plus-icon";
import { Pagination } from "@/components/pagination";
import {
  CardInfo,
  CardPreview,
} from "../../Membership/components/card-preview";
import Slider from "react-slick";
import { colors } from "@/theme/colors";

export interface PaymentMethodProps {
  onButtonClick: () => void;
}

export const PaymentMethod: FC<PaymentMethodProps> = ({ onButtonClick }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const sliderRef = useRef<Slider>(null);

  const { data: CardList, refetch } = useQuery(["channels"], async () => {
    const { data } = await GET("/payment/card-list/", {});
    return data?.cardList ?? [];
  });

  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0);

  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalPages = Math.ceil((CardList?.length ?? 0) / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = CardList?.slice(startIndex, endIndex) ?? [];

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handleDeleteCard = useCallback(
    async (token: string) => {
      const { response, error } = await POST("/payment/delete-card/", {
        body: {
          cardToken: token,
        },
      });
      refetch();
      if (response.ok) {
        toast({
          status: "success",
          title: t("card-info-deleted-successfully"),
        });

        return;
      }

      if (error) {
        toast({
          status: "error",
          title: error.detail ?? t("errors.card-info-not-deleted"),
        });

        return;
      }
    },
    [refetch, t, toast]
  );

  const handleSelectCard = useCallback(
    (card: CardInfo) => {
      const index = CardList?.findIndex(
        (item) => item.cardToken === card.cardToken
      );

      if (index !== undefined && index >= 0) {
        setSelectedCardIndex(index);
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(index);
        }
      }
    },
    [CardList]
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: window.innerHeight > 900 ? "15%" : "0",
    initialSlide: selectedCardIndex,
    afterChange: (current: number) => {
      setSelectedCardIndex(current);
      const newPage = Math.floor(current / itemsPerPage) + 1;
      setCurrentPage(newPage);
    },
  };

  return (
    <Flex
      h="full"
      flexDir="column"
      mt="42px"
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.08)"
      borderRadius="6px"
      p="36px"
      bg="white.normal"
    >
      <Box>
        {" "}
        <Flex bg="white.normal" alignItems="center" w="full" borderRadius="6px">
          <Flex w="full" justifyContent="space-between" mb="37px">
            <Box ml="0">
              <Text
                color="black.active"
                fontSize="24px"
                fontWeight="600"
                lineHeight="20px"
                mb="30px"
              >
                {t("payment_method")}
              </Text>
              <Text
                color="gray.light"
                fontSize="16px"
                lineHeight="17px"
                mt="1.5"
                w="fulll"
              >
                {t("payment-info")}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Flex w="full" gap="0">
        <Box w="full" display="flex" flexDir="column" flex={1} mb="40px">
          <Flex
            w="full"
            flexDir="column"
            gap="4"
            overflowY="auto"
            alignItems="center"
            borderRadius="6px"
          >
            <RadioGroup
              w="full"
              minH="430px"
              mt="10px"
              value={String(selectedCardIndex)}
              onChange={(value) => {
                const index = Number(value);
                setSelectedCardIndex(index);
                if (sliderRef.current) {
                  sliderRef.current.slickGoTo(index);
                }
              }}
            >
              {currentPageData ? (
                currentPageData?.map((item, index) => (
                  <CardItem
                    card={item}
                    key={item.cardToken}
                    onDelete={() => {
                      handleDeleteCard(item.cardToken);
                    }}
                    onSelect={() => handleSelectCard(item)}
                    index={index + startIndex}
                  />
                ))
              ) : (
                <>
                  <Text
                    color="green.label"
                    fontWeight="700"
                    fontSize="40px"
                    pos="relative"
                    top="40px"
                  >
                    {t("dev.under-development")} ...
                  </Text>
                  <LoadingIllustration />
                </>
              )}
            </RadioGroup>

            <Box h="full" display="flex">
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </Box>
          </Flex>
        </Box>

        <Box
          flex={2}
          h="full"
          w="full"
          mt="12px"
          ml="24px"
          overflowX="scroll"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Slider
            ref={sliderRef}
            {...settings}
            initialSlide={selectedCardIndex}
          >
            {CardList?.map((creditCard) => (
              <Box key={creditCard.cardToken} px="30px" h="full" w="full">
                <CardPreview card={creditCard} />
              </Box>
            ))}
          </Slider>
        </Box>

        <Box
          w="0px"
          h="100px"
          my="167px"
          mx="12px"
          display="flex"
          flexDir="column"
          flex={0.1}
          alignItems="center"
          transform="rotate(-90deg)"
        >
          <Button
            fontSize="15px"
            bg="green.light"
            textColor="green.label"
            border="1px solid"
            borderColor="green.label"
            borderTopRadius="23px"
            boxShadow="10px -20px 50px rgba(0, 0, 0, 0.08)"
            borderBottomRadius="0px"
            onClick={onButtonClick}
            _hover={{
              bg: "green.light",
              textColor: "green.label",
            }}
            h="101px"
            w="416px"
          >
            <IconButton
              aria-label="connect-plus-icon"
              icon={<ConnectPlusIcon fill={colors.green.label.default} />}
              variant="unstyled"
            />
            <Text fontSize="16px" fontWeight="600">
              {t("add-new-card")}
            </Text>
          </Button>
        </Box>
      </Flex>

      <Flex w="full">
        <InvoiceTable />
      </Flex>
    </Flex>
  );
};
