import { Icon, Box } from "@chakra-ui/react";
import {
  TwitterIcon,
  InstagramColorfulIcon,
  LinkedinIcon,
  FacebookIcon,
  TelegramIcon,
  YoutubeIcon,
  TiktokCircleIcon,
} from "@/assets/brands";
import { forwardRef } from "react";

export interface ChannelIconProps {
  channel?: string;
  w?: string;
  h?: string;
}

export type Ref = HTMLButtonElement;

export const ChannelIcon = forwardRef<Ref, ChannelIconProps>(
  ({ channel, w, h }) => {
    return (
      <Box color="black" borderRadius="4px" w={w} h={h}>
        {channel && (
          <Icon rounded="full" boxSize="none">
            {channel.startsWith("twitter") ? (
              <TwitterIcon width={w} height={h} />
            ) : channel.startsWith("facebook") ? (
              <FacebookIcon width={w} height={h} />
            ) : channel.startsWith("instagram") ? (
              <InstagramColorfulIcon width={w} height={h} />
            ) : channel.startsWith("tiktok") ? (
              <TiktokCircleIcon width={w} height={h} />
            ) : channel.startsWith("linkedin") ? (
              <LinkedinIcon width={w} height={h} />
            ) : channel.startsWith("telegram") ? (
              <TelegramIcon width={w} height={h} inverse={false} />
            ) : channel.startsWith("youtube") ? (
              <YoutubeIcon width={w} height={h} />
            ) : null}
          </Icon>
        )}
      </Box>
    );
  }
);
