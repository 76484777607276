import "./styles/font.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-international-phone/style.css";
import "overlayscrollbars/overlayscrollbars.css";
import "./styles/app.css";
import App from "./App";
import "react-day-picker/dist/style.css";

import { ChakraProvider, Flex } from "@chakra-ui/react";
import { QueryClientProvider, QueryClient, QueryCache } from "react-query";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import "./i18n";
import { createRoot } from "react-dom/client";

import { theme } from "./theme";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import * as Sentry from "@sentry/react";
import React from "react";
import { colors } from "./theme/colors";
import Hotjar from "@hotjar/browser";

const env = import.meta.env;

Sentry.init({
  dsn: "https://237b8b801b92f424b6b3aa68069e34f7@o4506960406315008.ingest.us.sentry.io/4507141957746688",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  environment: env.MODE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const queryCache = new QueryCache({
  onError(error) {
    console.error(error, typeof error);
  },
});

const queryClient = new QueryClient({
  queryCache,
});

if (env.MODE === "production") {
  const siteId = 5097321;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
}

const element = document.getElementById("root");
if (element) {
  createRoot(element).render(
    <QueryClientProvider client={queryClient}>
      <ChakraProvider
        resetCSS={true}
        toastOptions={{
          defaultOptions: {
            position: "top-right",
            containerStyle: {
              textColor: "white",
            },
          },
        }}
        theme={theme}
      >
        <BrowserRouter>
          <OverlayScrollbarsComponent
            style={{
              maxHeight: "100vh",
              backgroundColor: colors.gray.bg.default,
            }}
            options={{
              scrollbars: {
                autoHide: "scroll",
                autoHideDelay: 400,
              },
            }}
          >
            <Flex id="fist-flex" h="min-content" minW="0">
              <App />
            </Flex>
          </OverlayScrollbarsComponent>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  );
}
