import React from "react";

export const DeleteAccountIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.0299 12.6273L17.9942 11.5908L15.3575 14.2293L12.7209 11.5908L11.6851 12.6273L14.3217 15.2658L11.6851 17.9043L12.7209 18.9407L15.3575 16.3022L17.9942 18.9407L19.0299 17.9043L16.3933 15.2658L19.0299 12.6273Z"
        fill="#F13232"
      />
      <path
        d="M9.64433 0.204346C6.65585 0.204346 4.22452 2.63735 4.22452 5.62789C4.22452 7.48026 5.15757 9.11842 6.5778 10.0972C5.25178 10.5551 4.03641 11.3116 3.01534 12.3333C1.24469 14.1052 0.269531 16.4611 0.269531 18.9669H1.73435C1.73435 14.6023 5.28274 11.0514 9.64433 11.0514C12.6328 11.0514 15.0641 8.61848 15.0641 5.62789C15.0641 2.63735 12.6328 0.204346 9.64433 0.204346ZM9.64433 9.58561C7.46355 9.58561 5.68934 7.81021 5.68934 5.62789C5.68934 3.44558 7.46355 1.67018 9.64433 1.67018C11.8251 1.67018 13.5993 3.44558 13.5993 5.62789C13.5993 7.81021 11.8251 9.58561 9.64433 9.58561Z"
        fill="#F13232"
      />
    </svg>
  );
};
