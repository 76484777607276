import { APISchemas, GET, POST } from "@/api";
import {
  Button,
  Divider,
  Heading,
  Text,
  SimpleGrid,
  Box,
  Flex,
  Checkbox,
  useToast,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { CreditCard, PaymentSelect } from "./_payment-select";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useQuery } from "react-query";
import { getCurrencySymbol } from "@/constant";
import { i18nformat } from "@/utils/misc";
import { useTranslation, Trans } from "react-i18next";
import { useOutsideStore } from "@/context/outside-store/outside-store";
import { addDays } from "date-fns";
import { PaymentSuccessIcon } from "@/assets/graphics/payment-success-icon";
import { TermsModal } from "../Privacy/components/terms-modal";
import { CreditCardPreview } from "./components/credit-card-preview";

export const MembershipReview: FC = () => {
  const startPayment = useOutsideStore((c) => c.startPayment);
  const [selected, setSelected] = useState<CreditCard | undefined>();
  const [creditCard, setCreditCard] = useState<CreditCard>();
  const [loading, setLoading] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [successModal, openSuccessModal] = useState<boolean>(false);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<
    {
      path?: string | undefined;
      message: string;
    }[]
  >([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();

  const location = useLocation();
  const { newPlan, specialOffer } = location.state;

  const { data: Address } = useQuery(
    ["address", "review"],
    async () => {
      const { data, response } = await GET("/auth/address/", {});
      if (!response.ok) {
        toast({
          title: t("errors.please-enter-a-valid-address"),
          status: "error",
          isClosable: true,
        });

        navigate("/settings?id=billing");
        return;
      }
      return data;
    },
    {
      cacheTime: 0,
      select(data) {
        if (data && data.length === 0) {
          toast({
            status: "error",
            title: t("errors.please-enter-a-valid-address"),
            isClosable: true,
          });
          navigate("/settings?id=billing");
        }
        return data?.at(0);
      },
    }
  );

  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();

  if (!id) {
    navigate("/");
  }
  const [searchParams] = useSearchParams();
  const ok = searchParams.get("ok");

  const saveCard = useCallback(
    async (creditCard: CreditCard) => {
      if (!creditCard) {
        toast({
          status: "error",
          title: t("errors.please-enter-credit-card"),
          isClosable: true,
        });
        return;
      }

      const { error, data } = await POST("/payment/save-card/", {
        body: {
          cardHolderName: creditCard.name,
          cardNumber: creditCard.cardNumber,
          cardMonth: creditCard.expiryMonth,
          cardYear: "20" + creditCard.expiryYear,
        },
      });

      if (error?.issues) {
        setFormErrors(error.issues);
        error.issues.forEach((err) => {
          toast({
            description: err.message,
            status: "error",
            isClosable: true,
          });
        });

        return;
      }
      if (data?.result.startsWith("Invalid")) {
        toast({
          description: data.resultMessage,
          status: "error",
          isClosable: true,
        });

        return data;
      }

      return data;
    },
    [toast, t]
  );

  const finishPayment = useCallback(
    async (urlAddress: string) => {
      if (!creditCard?.CVV) return;
      const url = new URL(urlAddress);
      const searchParams = url.searchParams;
      const orderId = searchParams.get("OrderId");
      const systemTransId = searchParams.get("SystemTransId");
      const result = searchParams.get("Result");
      const totalAmount = searchParams.get("TotalAmount");
      const installmentCount = searchParams.get("InstallmentCount");
      const hash = searchParams.get("Hash");
      const mdStatus = searchParams.get("MDStatus") || "1";

      if (
        orderId &&
        systemTransId &&
        result &&
        totalAmount &&
        installmentCount &&
        hash &&
        mdStatus
      ) {
        const { response, error } = await POST("/payment/finish-payment/", {
          body: {
            orderId: orderId,
            systemTransId: systemTransId,
            result: result,
            totalAmount: Number(totalAmount),
            installmentCount: installmentCount,
            hash: hash,
            mdStatus: mdStatus,
            cvv: creditCard.CVV,
          },
        });
        if (response.ok) {
          openSuccessModal(true);
          return;
        }
        if (error?.detail) {
          toast({
            description: error.detail,
            status: "error",
            isClosable: true,
          });
          return;
        }
      } else {
        toast({
          status: "error",
          title: t("errors.unexpected-error-contact-customer-service"),
          isClosable: true,
        });
      }
    },
    [creditCard, toast, t]
  );

  const payAndConfirm = useCallback(async () => {
    if (!id) {
      toast({
        status: "error",
        title: t("errors.unexpected_error"),
        isClosable: true,
      });
      return;
    }

    if (!Address) {
      toast({
        status: "error",
        title: t("errors.please-enter-a-valid-address"),
        isClosable: true,
      });
      navigate("/settings?id=billing");
      return;
    }

    if (!creditCard) {
      toast({
        status: "error",
        title: t("errors.please-select-a-credit-card"),
        isClosable: true,
      });
      return;
    }

    let cardToken: string | undefined;
    if (selected) {
      cardToken = selected.ctoken;
    } else {
      const saveCardData = await saveCard(creditCard);
      if (saveCardData?.cardToken) cardToken = saveCardData.cardToken;

      const { data, error } = await GET("/payment/card-list/", {});

      if (!data || error) {
        toast({
          description: t("errors.unexpected_error"),
          status: "error",
          isClosable: true,
        });
        return;
      }

      const savedCard = data.cardList?.find((x) => x.cardToken === cardToken);

      if (!savedCard) {
        toast({
          description: t("errors.unexpected_error"),
          status: "error",
          isClosable: true,
        });
        return;
      }

      creditCard.bankCode = savedCard.bankCode;
    }

    if (!cardToken) {
      toast({
        description: t("errors.unexpected_error"),
        status: "error",
        isClosable: true,
      });
      return;
    }
    const { data, error } = await POST("/payment/pay/", {
      body: {
        product_kind_id: Number(newPlan.plan_kind[0].id),
        product_id: Number(id),
        card_token: cardToken,
        cvv: creditCard.CVV,
        bank_code: creditCard.bankCode,
        address: Address.id.toString(),
      },
    });

    if (error?.issues) {
      setFormErrors(error.issues);
      error.issues.map((err) => {
        toast({
          description: err.message,
          status: "error",
          isClosable: true,
        });
      });

      return;
    }

    const newWindow = window.open();

    if (newWindow && data?.redirectForm) {
      if (newWindow && data?.redirectForm) {
        startPayment();
        newWindow.document.write(data?.redirectForm);
      }
    }
  }, [
    id,
    Address,
    creditCard,
    selected,
    toast,
    navigate,
    saveCard,
    t,
    startPayment,
  ]);

  useEffect(() => {
    if (ok === "true") {
      return;
    }

    setReady(true);
    setLoading(true);

    setLoading(false);
    const unSub = useOutsideStore.subscribe((c) => {
      if (!c.state.url) return;
      finishPayment(c.state.url);
      c.clearState();
    });
    return () => {
      unSub();
    };
  }, [ok, finishPayment]);

  return (
    <Box p="7" bg="white">
      <SimpleGrid columns={2} w="full">
        <Box
          boxShadow="0px 0px 94px 13px rgba(191, 191, 191, 0.25)"
          h="full"
          py="6"
          px="5"
          bg="white"
        >
          <Heading size="lg" pb="5">
            {t("page.membership.review.payment-checkout")}
          </Heading>
          <Box px="6vw">
            <Text fontSize="24px" fontWeight="600">
              {t("page.membership.review.payment")}
            </Text>
            <Divider borderColor="gray.passive" my="8px" mb="27px" />
            <Box mb="5">
              <Text fontSize="xl" fontWeight="medium" mb="4">
                {t("page.membership.Choose-a-payment-method")}
              </Text>
              <PaymentSelect
                formError={formErrors}
                selected={selected}
                hardValidation={termsChecked}
                setSelected={(item) => setSelected(item)}
                onChange={setCreditCard}
                onValid={(valid) => setReady(valid)}
              />
              <Divider borderColor="gray.passive" my="8px" mb="27px" />
              <Box>
                <Text fontSize="xl" fontWeight="medium" mb="4" display="inline">
                  {t("billing_address")}
                </Text>{" "}
                :
                <Text
                  ml="3"
                  as="a"
                  href="/settings"
                  color="gray.passive"
                  textDecor="underline"
                >
                  {Address?.address_type === "B"
                    ? t("company")
                    : t("individual")}{" "}
                  - {Address?.name} - {Address?.city}
                </Text>
              </Box>
            </Box>
            <Box px="35px">
              <Checkbox
                color="black.dark"
                fontSize="16px"
                mb="48px"
                onChange={() => {
                  setTermsChecked(!termsChecked);
                }}
                isChecked={termsChecked}
              >
                {t("page.membership.I-read-and-accepted")}
                <Button
                  variant="link"
                  textDecor="underline"
                  fontWeight="700"
                  color="blue.shiny"
                  display="inline-block"
                  _hover={{
                    borderBottom: "none",
                  }}
                  ml="1"
                  mr="1"
                  onClick={onOpen}
                >
                  {t("page.membership.payment-terms")} &
                  {t("page.membership.policy-conditions")}
                </Button>
              </Checkbox>
              <Text color="gray.passive" fontSize="14px" mt="11px">
                {t("page.membership.review.personal-data-message")}
              </Text>

              <Button
                mt="23px"
                bg="#50CD89"
                color="white"
                w="full"
                rounded="md"
                py="18px"
                size="none"
                boxShadow="xl"
                _hover={{
                  opacity: "0.8",
                }}
                type="submit"
                isDisabled={loading || !ready || !termsChecked}
                onClick={() => payAndConfirm()}
              >
                {t("page.membership.pay-and-confirm")}
              </Button>
            </Box>
          </Box>
        </Box>

        <Flex
          flexDir="column"
          pt="3.5"
          px="6"
          pb="14"
          color="black"
          overflow="hidden"
          bg="white.snow-white"
          alignItems="center"
        >
          <Box w="476px">
            <Text fontSize="24px" fontWeight="600">
              {t("page.membership.review.order-summary")}
            </Text>
            <Divider borderColor="gray.passive" my="8px" mb="27px" />
            <CreditCardPreview card={creditCard} />
            <Box w="full" mt="7" lineHeight="1" pl="2" pr="8">
              <Flex justifyContent="space-between">
                <Text
                  fontSize="xl"
                  fontWeight="semibold"
                  mb="5"
                  textTransform="capitalize"
                >
                  {newPlan?.name &&
                    t(`membership-packages.${newPlan.name.toLowerCase()}`)}
                </Text>
                <Text fontSize="20px" fontWeight="500">
                  {newPlan.plan_kind[0].price} {newPlan.plan_kind[0].currency}
                </Text>
              </Flex>
              <Text mb="5" fontSize="16px">
                {newPlan?.description &&
                  t(`membership-packages.${newPlan.description.toLowerCase()}`)}
              </Text>

              <Flex justifyContent="space-between" color="gray.passive">
                <Text fontSize="16px" fontWeight="500">
                  {t("page.membership.review.valid-until")}
                </Text>
                <Text fontSize="16px" fontWeight="500">
                  {i18nformat(
                    addDays(
                      Date.now(),
                      newPlan.plan_kind[0].plan_period === "monthly"
                        ? 30
                        : 365 ?? 0
                    ),
                    "dd MMMM yyyy"
                  )}
                </Text>
              </Flex>
              <Divider borderColor="gray.passive" my="9" />
              <Flex
                fontSize="16px"
                justifyContent="space-between"
                fontWeight="600"
                mb="4"
              >
                <Text>{t("page.membership.review.sub-total")}</Text>
                <Text>
                  {getCurrencySymbol(newPlan.plan_kind[0].currency ?? "usd")}
                  {newPlan.plan_kind[0].price} {newPlan.plan_kind[0].currency}
                </Text>
              </Flex>
              {/* <Flex
                fontSize="16px"
                justifyContent="space-between"
                fontWeight="600"
              >
                <Text>{t("page.membership.Taxes")}</Text>
                <Text>
                  {getCurrencySymbol(Plan?.currency ?? "usd")}0.00{" "}
                  {Plan?.currency}
                </Text>
              </Flex> */}
              <Divider mb="5" mt="8" borderColor="gray.passive" />
              <Flex justifyContent="space-between" fontWeight="medium" mb="8">
                <Text fontSize="20px" fontWeight="500">
                  {t("page.membership.Total")}:
                </Text>
                <Text fontSize="36px">
                  {getCurrencySymbol(newPlan.plan_kind[0].currency ?? "usd")}
                  {specialOffer?.amount || newPlan.plan_kind[0].price}{" "}
                  {specialOffer?.currency || newPlan.plan_kind[0].currency}{" "}
                </Text>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </SimpleGrid>
      {newPlan && <SuccessModal isOpen={successModal} plan={newPlan} />}
      <TermsModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

const SuccessModal: FC<{ isOpen: boolean; plan: APISchemas["Plan"] }> = ({
  isOpen,
  plan,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClose = useCallback(() => null, []);

  const date = i18nformat(
    addDays(
      Date.now(),
      plan.plan_kind[0].plan_period === "monthly" ? 30 : 365 ?? 0
    ),
    "dd MMMM yyyy"
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent py="8">
        <ModalBody
          textAlign="center"
          bg="white"
          rounded="6px"
          py="55px"
          px="18px"
        >
          <SimpleGrid columns={2}>
            <PaymentSuccessIcon />

            <Box>
              <Heading
                fontSize="36px"
                fontWeight="700"
                color="black.dark"
                lineHeight="1"
              >
                {t("page.membership.success-modal.well-done")}
              </Heading>

              <Text
                fontSize="20px"
                color="black.dark"
                mt="24px"
                mb="56px"
                textAlign="left"
                lineHeight="41px"
              >
                {t("page.membership.success-modal.message")}{" "}
                <Text
                  as="span"
                  color="#57AAEC"
                  fontWeight="600"
                  textTransform="capitalize"
                >
                  {t(`membership-packages.${plan.name.toLowerCase()}`)}
                </Text>{" "}
                <Trans
                  i18nKey="page.membership.success-modal.until"
                  values={{ date }}
                >
                  <span style={{ color: "#57AAEC", fontWeight: 600 }}>
                    {date}
                  </span>
                </Trans>
              </Text>

              <Button
                bg="#50CD89"
                color="white"
                fontSize="24px"
                p="24px"
                w="full"
                onClick={() => navigate("/")}
              >
                {t("page.membership.success-modal.back-to-dashboard")}
              </Button>
            </Box>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
