export const AlertIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6841_15001)">
        <path
          d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17Z"
          fill="#026FA8"
        />
        <path
          d="M8.50098 10.2C8.19316 10.2 7.89729 10.0809 7.67534 9.86758C7.45339 9.65429 7.32258 9.36339 7.31031 9.05582L7.15326 5.09004C7.14696 4.92974 7.17309 4.76981 7.23009 4.61985C7.28708 4.46989 7.37377 4.33299 7.48495 4.21734C7.59614 4.10169 7.72953 4.00968 7.87713 3.94683C8.02473 3.88398 8.1835 3.85157 8.34393 3.85156H8.65803C8.81846 3.85157 8.97723 3.88398 9.12483 3.94683C9.27243 4.00968 9.40582 4.10169 9.517 4.21734C9.62819 4.33299 9.71487 4.46989 9.77187 4.61985C9.82887 4.76981 9.855 4.92974 9.84869 5.09004L9.69164 9.05582C9.67938 9.36339 9.54857 9.65429 9.32662 9.86758C9.10467 10.0809 8.8088 10.2 8.50098 10.2Z"
          fill="white"
        />
        <path
          d="M9.69184 11.9592C9.69184 11.3022 9.1592 10.7695 8.50217 10.7695C7.84513 10.7695 7.3125 11.3022 7.3125 11.9592C7.3125 12.6162 7.84513 13.1489 8.50217 13.1489C9.1592 13.1489 9.69184 12.6162 9.69184 11.9592Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6841_15001">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
