export const colors = {
  blue: {
    label: "#1090CB",
    main: "#345A79",
    light: "#E8F4FA",
    facebook: "#3A559F",
    linkedIn: "#0077B5",
    middle: "#497EB0",
    hashtag: "#1C8EEB",
    primary: "#487EB0;",
    brand: { default: "#3B5998", _dark: "#FFF" },
    shiny: "#57AAEB",
    "brand-light": "#B4DAF8",
    zodiac: "#0F2552",
    night: "#313349",
    baby: { default: "#B4DAF8", _dark: "#FFF" },
    sky: "#F2F7FF",
    water: "#E4F6FF",
  },
  black: {
    default: "#000",
    light: "#515151",
    dark: { default: "#353B48", _dark: "#FFF" },
    active: { default: "#33373D", _dark: "#FFF" },
    smooky: "#536174",
    medium: "#4D4D4D",
    night: "#161722",
  },
  gray: {
    light: "#D2D2D2",
    athens: { default: "#EBEDF2", _dark: "#FFF" },
    antrasit: { default: "#33373D", _dark: "#FFF" },
    passive: "#666b8f",
    thin: "#F6F7F9",
    line: "#345A7954",
    primary: "#C9C9C9",
    whiteSmoke: "#F4F6F8",
    smooth: "#E1E3EA",
    soft: { default: "#F2F2F2", _dark: "#FFF" },
    bg: { default: "#F7F8FA", _dark: "#15171C" },
    smoke: { default: "#B7BABF", _dark: "#B7BABF" },
    border: "#E4E5E7",
    silver: "#BCBEC3",
  },
  green: {
    label: { default: "#50CD89", _dark: "#FFF" },
    medium: "#056F4F",
    light: "#E8F9F0",
  },
  red: {
    light: "#FFEEEE",
    normal: "#A40000",
    label: "#E52625",
    main: { default: "#F13232", _dark: "#FFF" },
    youtube: "#F00000",
  },
  yellow: {
    label: { default: "#FBC531", _dark: "#FFF" },
  },
  pink: {
    error: "#FF6476",
  },
  purple: {
    normal: "#9631FB",
    light: "#EADEFA",
  },
  white: {
    normal: "#FFF",
    bg: { default: "#F7F8FA", _dark: "#FFF" },
    alabaster: { default: "#FAFAFA", _dark: "#15171C" },
    "snow-white": "#F9FAFA",
    line: "#CDD6DA",
  },
};
