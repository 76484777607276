import { Box, VStack, Grid, GridItem } from "@chakra-ui/react";
import { FC } from "react";
import { AnalyticsHeader } from "./components/analytics-header";
import { ChannelStatics } from "./components/channel-statics";
import { ChannelActivity } from "./components/channel-activity/channel-activity";
import { AnalyticsChartSection } from "./components/analytics-chart-section";
import { useChannel } from "@/hooks/use-channels";
import { DataStats } from "./components/channel-stats";
import { NotableDates } from "./components/notable-dates";
import { LikeBoxIcon } from "@/assets/icons/like-box-icon";
import { CommentBoxIcon } from "@/assets/icons/comment-box-icon";
import { ReachBoxIcon } from "@/assets/icons/reach-box-icon";
import { RetweetBoxIcon } from "@/assets/icons/retweet-icon";
import { useTranslation } from "react-i18next";
import { PostReports } from "./components/post-reports/post-reports";
import { ContentPerformance } from "./components/content-performance";

export const Desktop: FC = () => {
  const { t } = useTranslation();
  const { data: Channels } = useChannel();
  return (
    <VStack
      overflow="hidden"
      pos="relative"
      maxWidth="100vw"
      bg="gray.bg"
      h="full"
      p="20px"
      gap="0px"
    >
      <AnalyticsHeader />
      <Box w="full" height="full" h="400px" my="5">
        <Grid templateColumns="repeat(7, 1fr)" w="full" h="400px" gap="20px">
          <GridItem colSpan={2} w="100%">
            <Grid templateRows="repeat(5,1fr)" w="full" h="full" gap="20px">
              <GridItem rowSpan={3}>
                <ChannelStatics
                  title="Facebook"
                  postCount={1350}
                  followers={1356}
                  following={1367}
                  Channels={Channels}
                />
              </GridItem>
              <GridItem rowSpan={2}>
                <ChannelActivity title="facebook" />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={3} w="full" h="full">
            <Grid templateColumns="repeat(2, 1fr)" w="full" h="full" gap="20px">
              <GridItem w="full" h="full">
                <DataStats
                  goal={"1.000.000"}
                  increase={false}
                  icon={<LikeBoxIcon width="40px" height="40px" />}
                  title={t("page.analytics.like-c")}
                  data={336.625}
                  account="twitter"
                  variation={20.13}
                />
              </GridItem>
              <GridItem w="full" h="full">
                <DataStats
                  goal={"1.000.000"}
                  increase={true}
                  icon={<CommentBoxIcon width="40px" height="40px" />}
                  title={t("page.analytics.comment-c")}
                  data={548.625}
                  account="twitter"
                  variation={10.13}
                />
              </GridItem>
              <GridItem w="full" h="full">
                <DataStats
                  goal={"1.000.000"}
                  increase={false}
                  icon={<ReachBoxIcon width="40px" height="40px" />}
                  title={t("page.analytics.reach-c")}
                  data={334.202}
                  variation={45.34}
                  account="twitter"
                />
              </GridItem>
              <GridItem w="full" h="full">
                <DataStats
                  goal={"1.000.000"}
                  increase={false}
                  icon={<RetweetBoxIcon width="40px" height="40px" />}
                  title={t("page.analytics.retweet")}
                  data={13.202}
                  variation={5.34}
                  account="twitter"
                />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={2}>
            <NotableDates
              clickData={clickData}
              clickCount={4253}
              increased={false}
              variation={35}
              lastUpdate={"2 " + t("page.analytics.hours-ago")}
            />
          </GridItem>
        </Grid>
      </Box>

      <Grid templateColumns="repeat(7, 1fr)" w="full" h="400px" gap="20px">
        <GridItem colSpan={5} w="100%" h="400px">
          <AnalyticsChartSection />
        </GridItem>
        <GridItem colSpan={2} w="full" h="full">
          <ContentPerformance />
        </GridItem>
      </Grid>
      <Box w="full" h="full" mt="4">
        <PostReports />
      </Box>
    </VStack>
  );
};

const clickData = [
  {
    date: "19 Feb",
    data: 4000,
  },
  {
    date: "20 Feb",
    data: 3000,
  },
  {
    date: "21 Feb",
    data: 2000,
  },
  {
    date: "22 Feb",
    data: 2780,
  },
  {
    date: "23 Feb",
    data: 1890,
  },
  {
    date: "24 Feb",
    data: 2390,
  },
  {
    date: "25 Feb",
    data: 3490,
  },
  {
    date: "26 Feb",
    data: 2390,
  },
];
