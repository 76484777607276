import {
  FacebookBlueIcon,
  InstagramColorfulIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@/assets/brands";
import { TelegramCircleIcon } from "@/assets/brands/circle/telegram-circle";
import { TiktokCircleIcon } from "@/assets/brands/circle/tiktok-circle";
import {
  Flex,
  Text,
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  PopoverBody,
} from "@chakra-ui/react";
import React, { FC, useState, useEffect, useMemo } from "react";
import { useChannel } from "@/hooks/use-channels";
import { useTranslation } from "react-i18next";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { CalendarIcon } from "@/assets/icons/calendar-icon";
import { DayPicker, DateRange } from "react-day-picker";
import { format } from "date-fns";

export const AnalyticsHeader: FC = () => {
  const [active, setActive] = useState<string>("Twitter");
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [activeChannel, setActiveChannel] = useState<string | undefined>();
  const today: Date = useMemo(() => new Date(), []);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [range, setRange] = useState<DateRange>({ from: today, to: today });
  const { data: Channels } = useChannel();
  const { t } = useTranslation();

  const renderDateRange = () => {
    if (range?.from && range?.to) {
      return `${format(range.from, "dd MMM, yyyy")} - ${format(range.to, "dd MMM, yyyy")}`;
    } else if (range?.from) {
      return `${format(range.from, "dd MMM, yyyy")}`;
    } else if (range?.to) {
      return ` ${format(range.to, "dd MMM, yyyy")}`;
    }
    return null;
  };

  useEffect(() => {
    if (!range?.from && !range?.to) {
      setRange({ from: today, to: today });
    }
  }, [range, today]);

  return (
    <Flex w="full" height="60px" bgColor="white" p="16px">
      <Flex
        bgColor="gray.whiteSmoke"
        h="30px"
        px="4"
        alignItems="center"
        width="max-content"
        gap="4"
        pr="0px"
        borderRadius="6px"
      >
        <IconButton
          opacity={active === "Twitter" ? "1" : "0.40"}
          aria-label="account"
          icon={<TwitterIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Twitter")}
        />
        <IconButton
          opacity={active === "Linkedin" ? "1" : "0.40"}
          aria-label="account"
          icon={<LinkedinIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Linkedin")}
        />
        <IconButton
          opacity={active === "Facebook" ? "1" : "0.40"}
          aria-label="account"
          icon={<FacebookBlueIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Facebook")}
        />
        <IconButton
          opacity={active === "Instagram" ? "1" : "0.40"}
          aria-label="account"
          icon={<InstagramColorfulIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Instagram")}
        />
        <IconButton
          opacity={active === "Youtube" ? "1" : "0.40"}
          aria-label="account"
          icon={<YoutubeIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Youtube")}
        />
        <IconButton
          opacity={active === "Telegram" ? "1" : "0.40"}
          aria-label="account"
          icon={<TelegramCircleIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Telegram")}
        />
        <IconButton
          opacity={active === "Tiktok" ? "1" : "0.40"}
          aria-label="account"
          icon={<TiktokCircleIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Tiktok")}
        />
      </Flex>

      <Flex ml="20px">
        <Popover isOpen={popoverOpen} onClose={() => setPopoverOpen(false)}>
          <PopoverTrigger>
            <Button
              bgColor="gray.whiteSmoke"
              width="170px"
              height="30px"
              onClick={() => setPopoverOpen(!popoverOpen)}
              px="2"
            >
              <Flex justifyContent="space-between" alignItems="center" w="full">
                <Text
                  as="span"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize="13px"
                  lineHeight="14px"
                  color="black.active"
                  fontWeight="450"
                >
                  {activeChannel
                    ? activeChannel
                    : t("page.dashboard.all-accounts")}
                </Text>
                {popoverOpen ? (
                  <Box>
                    <ChevronUpIcon color="black.active" />
                  </Box>
                ) : (
                  <Box>
                    <ChevronDownIcon color="black.active" />
                  </Box>
                )}
              </Flex>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            bgColor="gray.whiteSmoke"
            width="170px"
            maxHeight="150px"
          >
            <OverlayScrollbarsComponent
              options={{
                scrollbars: {
                  autoHide: "scroll",
                },
              }}
            >
              <PopoverBody p="0px">
                {Channels?.map((channel) => (
                  <React.Fragment key={channel.id}>
                    <Button
                      h="30px"
                      _hover={{ bgColor: "gray.border" }}
                      variant="unstyled"
                      textOverflow="ellipsis"
                      fontSize="13px"
                      w="full"
                      fontWeight="450"
                      color="black.active"
                      px="2"
                      onClick={() => {
                        setActiveChannel(channel.name);
                        setPopoverOpen(false);
                      }}
                    >
                      <Text
                        textAlign="start"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        fontSize="13px"
                        lineHeight="14px"
                        color="black.active"
                        fontWeight="450"
                      >
                        {channel.name}
                      </Text>
                    </Button>
                  </React.Fragment>
                ))}
              </PopoverBody>
            </OverlayScrollbarsComponent>
          </PopoverContent>
        </Popover>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end" w="full" h="full">
        <Box position="relative" mr="2">
          <IconButton
            size="none"
            aria-label="calendar"
            icon={<CalendarIcon />}
            onClick={() => setCalendarOpen(!calendarOpen)}
          />
          {calendarOpen && (
            <Box
              zIndex="99"
              position="absolute"
              top="10"
              right="0"
              rounded="lg"
              bgColor="white.normal"
              border="1px solid #E4E5E7"
              boxShadow="sm"
              w="min-content"
              sx={{
                ".rdp-day_selected": {
                  backgroundColor: "#B4DAF8",
                  color: "white",
                },
              }}
            >
              <DayPicker
                selected={range}
                onSelect={(range) => {
                  setRange(range as DateRange);
                }}
                mode="range"
                showOutsideDays
              />
            </Box>
          )}
        </Box>
        <Flex
          px="4"
          h="full"
          border="1px solid #57AAEC"
          alignItems="center"
          justifyContent="center"
          borderRadius="3px"
          w="200px"
        >
          <Text fontSize="13px" lineHeight="14px">
            {renderDateRange()}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
