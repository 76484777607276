import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CustomTooltip } from "./custom-tooltip";

interface BarGraphProps {
  data: {
    name: string;
    data: number;
    lastData?: number;
  }[];
}
// @typescript-eslint/no-explicit-any
const Custom3DBar = (props: any) => {
  const { x, y, width, height, fill, smallRect } = props;
  const topHeight = 13;
  const restHeight = height - topHeight;
  return (
    <g>
      <rect
        x={x}
        y={y + 0.3}
        width={width}
        height={topHeight}
        fill={smallRect ? smallRect : fill}
        rx="2"
        ry="2"
      />
      <rect
        x={x}
        y={y + topHeight}
        width={width}
        opacity={0.5}
        height={restHeight}
        fill={fill}
        rx={0}
        ry={0}
      />
    </g>
  );
};

export const BarGraph: React.FC<BarGraphProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          left: 0,
          bottom: 0,
        }}
        barCategoryGap="0%"
        barGap={0}
        barSize={20}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tick={{ fontSize: "12px", fill: "#B7BABF" }}
          tickLine={false}
          axisLine={{ stroke: "#B7BABF" }}
        />
        <YAxis
          tick={{ fontSize: "12px", fill: "#B7BABF" }}
          axisLine={{ stroke: "#B7BABF" }}
          tickLine={false}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey="data"
          shape={<Custom3DBar fill="#F13232" smallRect="#FFBCBC" />}
        />
        <Bar
          dataKey="lastData"
          fill="#57AAEC"
          shape={<Custom3DBar fill="#57AAEC" smallRect="#75C2FF" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
