import React, { FormEventHandler, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Radio,
  RadioGroup,
  ModalCloseButton,
  Box,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export interface Step1Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmitSendEmail: FormEventHandler<HTMLElement>;
}

export const Step1: React.FC<Step1Props> = ({
  isOpen,
  onClose,
  onSubmitSendEmail,
}) => {
  const { t } = useTranslation();

  const [verificationMethod, setVerificationMethod] = useState("mail");

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <Box h="full" w="full" opacity="0.4">
        <ModalOverlay />
      </Box>
      <ModalContent
        as="form"
        onSubmit={onSubmitSendEmail}
        maxW="704"
        px="16px"
        py="20px"
        border="1px solid"
        borderColor="gray.athens"
      >
        <ModalCloseButton color="blue.shiny" bg="gray.athens" rounded="full" />
        <ModalHeader fontSize="20px" pb="0">
          {t("page.settings.profile.sure-password")}
        </ModalHeader>
        <ModalBody>
          <FormControl as="fieldset">
            <FormLabel as="legend" fontSize="14px" pb="12px" fontWeight="400">
              {t("page.settings.profile.choose-method")}
            </FormLabel>
            <RadioGroup
              onChange={setVerificationMethod}
              value={verificationMethod}
            >
              <Radio value="mail">
                <Text as="span">
                  {t("page.settings.profile.email-verification")}
                </Text>
              </Radio>
              <Radio value="sms" isDisabled ml="20px">
                <Text as="span">
                  {t("page.settings.profile.sms-verification")}
                </Text>
              </Radio>
            </RadioGroup>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="right" width="full">
            <Button
              bg="white.normal"
              color="blue.shiny"
              border="1px solid"
              borderColor="blue.shiny"
              onClick={onClose}
              mr="16px"
              px="20px"
              py="14px"
              fontSize="14px"
              w="164px"
            >
              {t("page.settings.profile.no-cancel")}
            </Button>
            <Button
              bg="blue.shiny"
              type="submit"
              // onClick={onSubmitSendEmail}
              color="white.normal"
              px="20px"
              py="14px"
              fontSize="14px"
              w="164px"
            >
              {t("page.settings.profile.yes-confirm")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
