import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountSettings } from "./account-settings";
import Members from "./organizations/members";
import { Profile } from "./profile";
import { useLocation } from "react-router-dom";
import { PaymentMethod } from "./payment-method";
import { AddNewCard } from "./payment-method/add-new-card";

export const SettingsPage: FC = () => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(
    location.state && location.state.tab ? location.state.tab : 0
  );

  const { t } = useTranslation();

  return (
    <Box>
      <Tabs
        orientation="horizontal"
        variant="unstyled"
        index={tabIndex}
        onChange={setTabIndex}
        p="24px"
      >
        <Box
          w="full"
          h="full"
          p="24px"
          borderRadius="6px"
          bg="white.normal"
          boxShadow={"0px 0px 20px rgba(0, 0, 0, 0.08)"}
        >
          <TabList
            w="min-content"
            shadow="sm"
            bg="gray.whiteSmoke"
            borderRadius="6px"
            whiteSpace="nowrap"
          >
            <Tab
              bg={tabIndex === 0 ? "white.normal" : "transparent"}
              w="full"
              m="5px"
              mx="20px"
              borderRadius="6px"
              py="9px"
            >
              <Flex
                fontSize="16px"
                lineHeight="20px"
                opacity={tabIndex === 0 ? "1" : "0.63"}
                color="black.active"
                flexDir="column"
                alignItems="end"
                fontWeight={tabIndex === 0 ? "500" : undefined}
              >
                {t("profile")}
              </Flex>
            </Tab>
            <Tab
              w="full"
              bg={tabIndex === 1 ? "white.normal" : "transparent"}
              m="5px"
              mx="20px"
              borderRadius="6px"
              py="9px"
            >
              <Flex
                fontSize="16px"
                lineHeight="20px"
                opacity={tabIndex === 1 ? "1" : "0.63"}
                color="black.active"
                flexDir="column"
                alignItems="end"
              >
                {t("payment_method")}
              </Flex>
            </Tab>
            {/* <Tab justifyContent="left">
          <Flex
            fontSize="14px"
            lineHeight="20px"
            opacity={tabIndex === 2 ? "1" : "0.33"}
            color={"black.active"}
            flexDir="column"
            alignItems="end"
            fontWeight={tabIndex === 2 ? "bold" : undefined}
          >
            {t("organizations")}
          </Flex>
        </Tab> */}
            <Tab
              w="full"
              bg={tabIndex === 2 ? "white.normal" : "transparent"}
              m="5px"
              mx="20px"
              borderRadius="6px"
              py="9px"
            >
              <Flex
                fontSize="16px"
                lineHeight="20px"
                opacity={tabIndex === 2 ? "1" : "0.63"}
                color="black.active"
                flexDir="column"
                alignItems="end"
              >
                {t("members")}
              </Flex>
            </Tab>
            {/* <Tab justifyContent="left">
          <Flex
            fontSize="14px"
            lineHeight="20px"
            opacity={tabIndex === 4 ? "1" : "0.33"}
            color={"black.active"}
            flexDir="column"
            alignItems="end"
            fontWeight={tabIndex === 4 ? "bold" : undefined}
          >
            {t("roles")}
          </Flex>
        </Tab> */}

            <Tab
              w="full"
              bg={tabIndex === 3 ? "white.normal" : "transparent"}
              m="5px"
              mx="20px"
              borderRadius="6px"
              py="9px"
            >
              <Flex
                fontSize="16px"
                lineHeight="20px"
                opacity={tabIndex === 3 ? "1" : "0.63"}
                color="black.active"
                flexDir="column"
                alignItems="end"
                whiteSpace="nowrap"
              >
                {t("account_settings")}
              </Flex>
            </Tab>

            <Tab
              w="full"
              bg={tabIndex === 3 ? "white.normal" : "transparent"}
              m="5px"
              borderRadius="6px"
              display="none"
              py="9px"
            >
              <Flex
                fontSize="16px"
                lineHeight="20px"
                opacity={tabIndex === 3 ? "1" : "0.63"}
                color="black.active"
                flexDir="column"
                alignItems="end"
                whiteSpace="nowrap"
              ></Flex>
            </Tab>
          </TabList>
        </Box>
        <TabPanels>
          <TabPanel h="full" p="0">
            <Profile />
          </TabPanel>
          <TabPanel h="full" p="0">
            <PaymentMethod onButtonClick={() => setTabIndex(4)} />
          </TabPanel>
          {/* <TabPanel h="full">
          <Organizations />
        </TabPanel> */}
          <TabPanel h="full" p="0">
            <Members />
          </TabPanel>
          {/* <TabPanel h="full">
          <Roles userData={userData} />
        </TabPanel> */}
          <TabPanel h="full" p="0">
            <AccountSettings />
          </TabPanel>
          {/* <TabPanel h="full">
          <PrivacyPolicy />
        </TabPanel> */}

          <TabPanel h="full" p="0">
            <AddNewCard onButtonClick={() => setTabIndex(1)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
