import { FC } from "react";
import { Text, Flex, Button } from "@chakra-ui/react";
import { TrashIconRed } from "@/assets/icons/trash-icon-red";

interface TagUserItemProps {
  text: string;
  onRemove: () => void;
  border: boolean;
}

export const TagUserItem: FC<TagUserItemProps> = ({
  text,
  border,
  onRemove,
}) => {
  return (
    <Flex
      p="1"
      px="2"
      w="full"
      bg="white"
      fontSize="12px"
      fontWeight="500"
      _hover={{ bgColor: "gray.bg" }}
      justifyContent="space-between"
      alignItems="center"
      borderRadius={border ? "3px" : "0"}
    >
      <Text
        fontWeight="500"
        textAlign="center"
        fontSize="12px"
        color="black.active"
      >
        {text}
      </Text>
      <Button
        size="none"
        cursor="pointer"
        variant="unstyled"
        onClick={() => onRemove()}
      >
        <TrashIconRed />
      </Button>
    </Flex>
  );
};
