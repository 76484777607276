import React from "react";

export const CancelSquareIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.897461"
        y="1.10278"
        width="33"
        height="33"
        rx="5.5"
        fill="#F13232"
        fillOpacity="0.13"
      />
      <rect
        x="0.897461"
        y="1.10278"
        width="33"
        height="33"
        rx="5.5"
        stroke="#F13232"
      />
      <path
        d="M11.3414 14.2903L12.1717 24.3133C12.2312 25.0361 12.8469 25.6028 13.5724 25.6028H21.2226C21.9481 25.6028 22.5638 25.0361 22.6233 24.3133L23.4536 14.2903H11.3414ZM15.0533 23.7278C14.8079 23.7278 14.6015 23.5369 14.5859 23.2883L14.1172 15.7258C14.1012 15.4672 14.2975 15.2447 14.5557 15.2287C14.8235 15.2099 15.0364 15.4086 15.0528 15.6672L15.5216 23.2297C15.5382 23.4975 15.3263 23.7278 15.0533 23.7278ZM17.8662 23.259C17.8662 23.5181 17.6566 23.7278 17.3975 23.7278C17.1384 23.7278 16.9287 23.5181 16.9287 23.259V15.6965C16.9287 15.4374 17.1384 15.2278 17.3975 15.2278C17.6566 15.2278 17.8662 15.4374 17.8662 15.6965V23.259ZM20.6778 15.7258L20.2091 23.2883C20.1937 23.5344 19.9886 23.7395 19.7119 23.7269C19.4537 23.7108 19.2574 23.4884 19.2734 23.2298L19.7421 15.6673C19.7582 15.4086 19.9848 15.2205 20.2393 15.2287C20.4975 15.2448 20.6938 15.4672 20.6778 15.7258Z"
        fill="#F13232"
      />
      <path
        d="M23.4912 11.4778H20.6787V11.009C20.6787 10.2336 20.0479 9.60278 19.2725 9.60278H15.5225C14.747 9.60278 14.1162 10.2336 14.1162 11.009V11.4778H11.3037C10.7859 11.4778 10.3662 11.8975 10.3662 12.4153C10.3662 12.933 10.7859 13.3528 11.3037 13.3528C15.6151 13.3528 19.1799 13.3528 23.4912 13.3528C24.009 13.3528 24.4287 12.933 24.4287 12.4153C24.4287 11.8975 24.009 11.4778 23.4912 11.4778ZM19.7412 11.4778H15.0537V11.009C15.0537 10.7504 15.2638 10.5403 15.5225 10.5403H19.2725C19.5311 10.5403 19.7412 10.7504 19.7412 11.009V11.4778Z"
        fill="#F13232"
      />
    </svg>
  );
};
